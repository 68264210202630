import React from "react";
import copyIcon from "/app/src/components/generic/title/copyIcon.svg";
import { Modal } from "antd";
import { reportService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQueryClient } from "@tanstack/react-query";
import { isEnterKeyOrClick } from "/app/src/helpers/onClickOnKeyPress";

const { confirm } = Modal;

/**
 * Returns a button that duplicates a report
 * @param param0 Id of report to duplicate
 */
export default function DuplicateReport({ reportId }: { reportId: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleEnterPressOrClick = (event) => {
    if (isEnterKeyOrClick(event)) {
      event.preventDefault();
      ShowConfirm(
        t("translation:copy"),
        t("translation:do_not_copy"),
        t("translation:confirm_copy_report"),
        duplicateReport,
      );
    }
  };

  /**
   * Duplicate a report, invalidate the cache and navigate to the new report
   */
  const duplicateReport = () => {
    reportService
      .duplicateReport({ reportId }, buildParams({ copy: "True" }))
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
        navigate(`/reports/${response.report.id}`);
      });
  };
  return (
    <button
      className="buttonless"
      onClick={handleEnterPressOrClick}
      onKeyDown={handleEnterPressOrClick}
    >
      <img
        tabIndex={0}
        style={{ marginRight: "10px" }}
        src={copyIcon}
        alt="Copy"
      />
    </button>
  );
}
function ShowConfirm(
  okText: string,
  cancelText: string,
  title: string,
  duplicateReport: () => void,
) {
  confirm({
    okText: okText,
    cancelText: cancelText,
    title: title,
    onOk() {
      //refresh the list of reports if channel successfully removed
      duplicateReport();
    },
    onCancel() {},
  });
}
