import React from "react";

import { Formik, FormikProps } from "formik";
import { Form, Input, Select } from "formik-antd";
import { Row, Col } from "antd";
import { resetPasswordSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
interface FormValues {
  property: string;
  bin: string;
  maxStock: string;
}

export default function MaterialForm({
  formRef,
}: {
  formRef: React.RefObject<FormikProps<FormValues>>;
}) {
  const { Option } = Select;
  const { t } = useTranslation();

  const materialFieldsForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    errors,
    touched,
    isValid,
    dirty,
  }) => (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
        >
          <Form.Item name="property" label={t("translation:material_property")}>
            <Select name="property" size="large">
              <Option value="BASIC">{t("translation:basic_")}1</Option>
              <Option value="BASIC2">{t("translation:basic_")}2</Option>
              <Option value="BASIC3">{t("translation:basic_")}3</Option>
              <Option value="BASIC4">{t("translation:basic_")}4</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
        >
          <Form.Item name="bin" label={t("translation:bin")}>
            <Select name="bin" size="large">
              <Option value="BIN">{t("translation:sample_bin_")}1</Option>
              <Option value="BIN2">{t("translation:sample_bin_")}2</Option>
              <Option value="BIN3">{t("translation:sample_bin_")}3</Option>
              <Option value="BIN4">{t("translation:sample_bin_")}4</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item name="maxStock" label={t("translation:max_stock_per_bin")}>
            <Input suffix name="maxStock" size="large" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Row>
      <Col span={24}>
        <div>
          <Formik
            onSubmit={(e) => {
              console.log(e);
            }}
            innerRef={formRef}
            component={materialFieldsForm}
            validationSchema={resetPasswordSchema}
            initialValues={{
              property: "",
              bin: "",
              maxStock: "",
            }}
          />
        </div>
      </Col>
    </Row>
  );
}
