import React from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";

import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string;
}

export default function NewIntegration({
  app,
  setToggled,
}: {
  app: App;
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={10}>
          <Form.Item name="name" hasFeedback={false}>
            <Input
              name="name"
              placeholder={t("translation:enter_name")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
          >
            {t("translation:create")} {t("translation:integration")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="box">
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={{
            name: "",
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={(values, actions) => {
            integrationService
              .createSingle(formatForm(values, app))
              .then((response) => {
                actions.setSubmitting(false);
                navigate(
                  `/apps/${app.id}/integrations/${response.integration.id}`,
                );
                setToggled();
              });
          }}
        />
      </div>
    </div>
  );
}

function formatForm(values: FormValues, app: App): Integration {
  return {
    name: values.name,
    appId: app.id,
  };
}
