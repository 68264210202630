import React from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col, DatePicker } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { newOrderSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { Order } from "/app/src/models";

interface FormValues {
  name: string;
  type: string;
  priority: "High" | "Medium" | "Low" | "Hot";
  deadline: string;
}

export default function OrderHeader({
  order,
  submit,
  setOrder,
}: {
  order: Order;
  submit: (
    order: Order,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => Promise<any>;
  setOrder: (order: Order) => void;
}) {
  const { t } = useTranslation();

  const orderHeaderForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    setFieldValue,
  }) => (
    <Form layout="vertical">
      <h3>{t("translation:order_details")}</h3>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Form.Item name="name" label={t("translation:name")}>
            <Input
              suffix
              name="name"
              className="name"
              placeholder={t("translation:order_name_number")}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="type" label={t("translation:type")}>
            <Select name="type" size="large">
              <Select.Option key="1" value="Put">
                {t("translation:put")}
              </Select.Option>
              <Select.Option key="2" value="Pick">
                {t("translation:pick")}
              </Select.Option>
              <Select.Option key="3" value="Count">
                {t("translation:count")}
              </Select.Option>
              <Select.Option key="4" value="Transfer">
                {t("translation:transfer")}
              </Select.Option>
              <Select.Option key="5" value="Return">
                {t("translation:return")}
              </Select.Option>
              <Select.Option key="6" value="Purge">
                {t("translation:purge")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="priority" label={t("translation:priority")}>
            <Select name="priority" size="large">
              <Select.Option key="1" value="Low">
                {t("translation:low")}
              </Select.Option>
              <Select.Option key="2" value="Medium">
                {t("translation:medium")}
              </Select.Option>
              <Select.Option key="3" value="High">
                {t("translation:high")}
              </Select.Option>
              <Select.Option key="4" value="Hot">
                {t("translation:hot")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="deadline" label={t("translation:deadline")}>
            <DatePicker
              size="large"
              name="deadline"
              showTime={true}
              onChange={(e) => setFieldValue("deadline", e)}
            />
          </Form.Item>
        </Col>
        <Col span={12} />
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!(dirty && isValid)}
          >
            {t("translation:create")} {t("translation:order")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="newOrder box">
      <Formik
        component={orderHeaderForm}
        initialValues={{
          name: "",
          type: "Pick",
          priority: "Medium",
          deadline: "",
        }}
        validationSchema={newOrderSchema}
        onSubmit={(values, actions) => {
          submit(values, actions.setFieldError).then(() => {
            actions.setSubmitting(false);
          });
        }}
      />
    </div>
  );
}
