import React, { useCallback } from "react";
import { sortPermissions } from "../helpers";
import { rolePermissionService } from "/app/src/services";
import { message } from "antd";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import PermissionSwitch from "../permissionSwitch";
import { Permission } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { useAuthState } from "/app/src/contexts/authentication";

/**
 * Displays the permission level for only viewing dashboard
 */
export default function DashboardViewOnly({
  roleId,
  allPermissions,
  active,
}: {
  roleId: number;
  allPermissions: { permissions: Permission[] };
  active: boolean;
}) {
  const code = "DASH";
  const queryClient = useQueryClient();
  const { user } = useAuthState();
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ["permissions", roleId, code],
    queryFn: () =>
      rolePermissionService
        .getRolePermissions(
          roleId,
          buildParams({
            search: code,
            roleId,
          }),
        )
        .then((response) => {
          return sortPermissions(response.permissions, code);
        }),
    enabled: roleId !== undefined,
    initialData: {},
  });

  const handleSwitchChange = useCallback(
    (checked: boolean, level) => {
      if (roleId === user.roleId) {
        message.error(t("translation:cannot_edit_own_role"));
        return;
      }
      const fullCode = `${code}-${level}`;
      const permission = allPermissions.permissions.find(
        (permission) => permission.code === fullCode,
      );

      if (!checked) {
        rolePermissionService
          .removeRolePermission(roleId, permission.id)
          .then(() => {
            queryClient.invalidateQueries(["permissions", roleId]);
          });
        queryClient.setQueryData(["permissions", roleId, code], (oldData) => {
          return {
            [code]: oldData[code].filter((permission) => permission !== level),
          };
        });
      } else {
        rolePermissionService
          .addRolePermission(roleId, permission.id)
          .then(() => {
            queryClient.invalidateQueries(["permissions", roleId]);
          });
        queryClient.setQueryData(
          ["permissions", roleId, code],
          (oldData: { [key: string]: string[] }) => {
            if (!(code in oldData)) {
              return {
                [code]: [level],
              };
            }
            return {
              [code]: [...oldData[code], level],
            };
          },
        );
      }
    },
    [allPermissions.permissions, queryClient, roleId, t, user.roleId],
  );

  const handleViewOnlySwitchChange = useCallback(
    (checked: boolean) => {
      handleSwitchChange(checked, "006");
    },
    [handleSwitchChange],
  );
  if (!active) {
    return null;
  }
  return (
    <>
      <div>
        <h3>{t("translation:only_dashboard_access")}</h3>
      </div>
      <div>
        <PermissionSwitch
          isChecked={data?.[code]?.includes("006")}
          handleSwitchChange={handleViewOnlySwitchChange}
        />
      </div>
      <div />
      <div />
      <div />
    </>
  );
}
