import React from "react";
import { Routes, Route } from "react-router-dom";
import SingleProcessor from "./singleProcesser";
import IntegrationList from "./integrationList";
import { App } from "/app/src/models";

export default function OrderProcessor({ app }: { app: App }) {
  return (
    <Routes>
      <Route
        path="integrations/:integrationId/*"
        element={<SingleProcessor app={app} />}
      />
      <Route path="/" element={<IntegrationList appId={app.id as number} />} />
    </Routes>
  );
}
