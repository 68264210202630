import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";
export default function Controls({
  app,
  toggleNew,
  activeNew,
}: {
  app: App;
  toggleNew: () => void;
  activeNew: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="controls">
      <div className="icon"> </div>
      <div className="title">{app && <h1>{app.name}</h1>}</div>

      <div className="controlButtons">
        {activeNew ? (
          <Button onClick={() => toggleNew()}>Cancel</Button>
        ) : (
          <Button type="primary" onClick={() => toggleNew()}>
            {t("translation:new_display")}
          </Button>
        )}
      </div>
    </div>
  );
}
