import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { OrderLine } from "../models";

/**
 * Get all order lines
 * @param params URLSearchParams
 * @returns Promise<{ order_lines: OrderLine[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ order_lines: OrderLine[] }> {
  return genericGetAll("order_lines", params);
}

/**
 * Get a single order line
 * @param id string
 * @returns Promise<{ order_line: OrderLine }>
 */
function getSingle(id: string): Promise<{ order_line: OrderLine }> {
  return genericGetSingle("order_lines", id);
}

/**
 * Gets the count of order lines
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("order_lines", params);
}

export const orderLineService = {
  getAll,
  getSingle,
  getCount,
};
