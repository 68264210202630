import { useEffect, useRef, useCallback } from "react";

// returns true if the component is mounted
export function useMountedState() {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
}
