import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { userService, roleService } from "/app/src/services";
import Controls from "./controls";
import Roles from "../roles";
import { Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import Filter from "./filter";
import Table from "/app/src/components/generic/tables/table";
import Status from "./status";
import { useTranslation } from "react-i18next";
import { User, Role as RoleType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery } from "@tanstack/react-query";

import { useSort } from "/app/src/hooks";
import { buildParams } from "/app/src/helpers";
import { Column } from "react-table";
import { useAuthState } from "/app/src/contexts/authentication";
type query = {
  limit: number;
  page: number;
  status?: string;
  roleId?: number | string;
  countOnly?: boolean;
  orderby?: string;
};

export default function UserList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authType } = useAuthState();
  const columns: Column<User>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:username"),
        accessor: "username",
      },
      {
        Header: t("translation:email"),
        accessor: "email",
      },
      {
        Header: t("translation:status"),
        accessor: "status",
        Cell: ({ cell: { value } }) => <p>{Status(value)}</p>,
      },
      {
        Header: t("translation:role"),
        accessor: "roleName",
        Cell: ({ cell: { value } }) => <Tag className="userGroup">{value}</Tag>,
      },
    ];
  }, [t]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sort, setSort] = useSort();
  const [selectedRole, setSelectedRole] = useState<string | number>("all");
  const [selectedStatus, setSelectedStatus] = useState("all");

  const goToUser = (userId: number) => {
    navigate(`/users/${userId}`);
  };

  //Get roles
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return roleService.getAll();
    },
    initialData: { roles: [] },
    select: (data: { roles: RoleType[] }) => {
      return data.roles;
    },
  });
  //Get number of users
  const { data: usersCount } = useQuery({
    queryKey: ["usersCount"],
    queryFn: () => {
      return userService.getAll(buildParams({ countOnly: true }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //Get users
  const { data: users, isFetching } = useQuery({
    queryKey: ["users", page, pageSize, selectedRole, selectedStatus, sort],
    queryFn: () => {
      const query: query = {
        limit: pageSize,
        page: page,
        orderby: getOrderByQuery(sort),
      };
      if (selectedRole !== "all") {
        query["roleId"] = selectedRole;
      }
      if (selectedStatus !== "all") {
        query["status"] = selectedStatus;
      }
      return userService.getAll(buildParams(query));
    },
    initialData: { users: [] },
    select: (data: { users: User[] }) => {
      return data.users;
    },
  });

  return (
    <div className="users">
      <Helmet>
        <title>{t("translation:users")} - ItemPath</title>
      </Helmet>
      {authType === "basic" && <Controls />}
      <Row gutter={20}>
        <Col span={6}>
          <Filter
            roles={roles}
            setRole={setSelectedRole}
            setStatus={setSelectedStatus}
          />
          <Roles roles={roles} />
        </Col>
        <Col span={18}>
          <Table
            loading={isFetching}
            rows={users}
            tableColumns={columns}
            length={usersCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize: pageSize,
              setPage: setPage,
              setPageSize: setPageSize,
            }}
            rowClicked={goToUser}
            emptyText="No Users Found"
          />
        </Col>
      </Row>
    </div>
  );
}
