import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function DataButton() {
  const { t } = useTranslation();
  const { reportId } = useNumberId();
  return (
    <NavLink end to={`/reports/${reportId}`}>
      {t("translation:data")}
    </NavLink>
  );
}
