import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { orderLineService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";

import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";

import Table from "/app/src/components/generic/tables/table";
import { useSort } from "/app/src/hooks";
import { useTranslation } from "react-i18next";
import { OrderLine as OrderLineType } from "/app/src/models";
import { Column } from "react-table";
import { useQuery } from "@tanstack/react-query";
export default function OrderLines() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns: Column<OrderLineType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:number"),
        accessor: "number",
      },
      {
        Header: t("translation:info1"),
        accessor: "Info1",
      },
    ];
  }, [t]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");

  const goToOrderLine = (orderLineId: number) => {
    navigate(`/explorer/order_lines/${orderLineId}`);
  };

  const { data: orderLines, isFetching } = useQuery({
    queryKey: ["orderLines", page, pageSize, searchString, sort],
    queryFn: () => {
      return orderLineService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          state: "queued",
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { order_lines: [] },
    select: (data: { order_lines: OrderLineType[] }) => {
      return data.order_lines;
    },
  });

  const { data: orderLinesCount } = useQuery({
    queryKey: ["orderLinesCount", searchString],
    queryFn: () => {
      return orderLineService.getCount(
        buildParams({
          search: searchString,
          state: "queued",
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:order_lines")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={orderLines}
        tableColumns={columns}
        length={orderLinesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        rowClicked={goToOrderLine}
        emptyText={t("translation:no_order_lines")}
      />
    </div>
  );
}
