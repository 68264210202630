import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Bin } from "../models";

/**
 * Get all bins
 * @param params URLSearchParams
 * @returns Promise<{ bins: Bin[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ bins: Bin[] }> {
  return genericGetAll("bins", params);
}

/**
 * Get a single bin
 * @param id string
 * @returns Promise<{ bin: Bin }>
 */
function getSingle(id: string): Promise<{ bin: Bin }> {
  return genericGetSingle("bins", id);
}

/**
 * Gets the count of bins
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("bins", params);
}

export const binService = {
  getAll,
  getSingle,
  getCount,
};
