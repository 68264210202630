import { useContext } from "react";
import PermissionContext from "../contexts/PermissionContext";
import { checkForPermissionLevel } from "../helpers/permissions";
import { PermissionCode } from "../types";

/**
 * Hook to get permission levels from the PermissionContext given a permission code
 * @param code Permission code to check
 * @returns canEdit, canDelete, canCreate, canView
 */
export function useContextForPermission(code: PermissionCode) {
  const permissions = useContext(PermissionContext);
  const canEdit = checkForPermissionLevel(permissions, code, "EDIT");
  const canDelete = checkForPermissionLevel(permissions, code, "DELETE");
  const canCreate = checkForPermissionLevel(permissions, code, "CREATE");
  const canView = checkForPermissionLevel(permissions, code, "VIEW");
  return {
    canEdit,
    canView,
    canCreate,
    canDelete,
    isLoading: permissions.isLoading,
  };
}
