import { object, string } from "yup";
import isIpAddress from "/app/src/helpers/validators";
import i18next from "/app/src/locales/config";

export const webServicesSchema = object().shape({
  username: string().required(i18next.t("translation:username_is_required")),
  password: string().required(i18next.t("translation:password_is_required")),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test(
      "translation:test-name",
      i18next.t("translation:valid_address"),
      (value) => {
        return isIpAddress(value);
      },
    ),
});

export const webServicesSchemaNoPwd = object().shape({
  username: string().required(i18next.t("translation:username_is_required")),
  password: string(),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test(
      "translation:test-name",
      i18next.t("translation:valid_address"),
      (value) => {
        return isIpAddress(value);
      },
    ),
});
