import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import WorkflowHistories from "./workflowHistories";
import ActionLogs from "./actionLogs";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Workflow } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "/app/src/hooks/useNumberId";
export default function Logs() {
  const { t } = useTranslation();
  const { workflowId } = useNumberId();
  const [workflowHistoryId, setWorkflowHistoryId] = useState<number>();

  const { data: workflow } = useQuery({
    queryKey: ["workflow", workflowId],
    queryFn: () => {
      return workflowService.getSingle(workflowId);
    },
    initialData: { workflow: {} },
    select: (data: { workflow: Workflow }) => {
      return data.workflow;
    },
  });

  return (
    <div className="workflow">
      <Helmet>
        <title>{t("translation:logs")}</title>
      </Helmet>
      <div className="title">
        <Link to={`/workflows/${workflowId}`}>{workflow.name}</Link>
        <span className="separator">{">"}</span>
        <h1>
          {workflow.name} {t("translation:logs")}
        </h1>
      </div>
      <Row gutter={20}>
        <Col span={6}>
          <WorkflowHistories
            workflowId={workflow.id}
            setWorkflowHistoryId={setWorkflowHistoryId}
          />
        </Col>
        <Col span={18}>
          <ActionLogs workflowHistoryId={workflowHistoryId} />
        </Col>
      </Row>
    </div>
  );
}
