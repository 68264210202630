import Email from "./email";
import Epicor from "./epicor";
import Slack from "./slack";
import SMS from "./sms";
import Hue from "./hue";
import DataPush from "./dataPush";
import CycleCounts from "./cycleCounts";
import { integrationService } from "/app/src/services";
import { WorkflowAction, App, Report } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";

export default function AppMapper({
  action = {},
  app,
  isNew,
  addWorkflowAction,
  updateAction,
  removeWorkflowAction,
  reports,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  isNew: boolean;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  updateAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  reports: Report[];
  resetSelectedApp: () => void;
}) {
  const { data: integrations } = useQuery({
    queryKey: ["integrationsByApp", app.id],
    queryFn: () => {
      return integrationService.getAll(buildParams({ appId: app?.id }));
    },
    enabled: Boolean(app?.id),
    initialData: { integrations: [] },
    select: (data: { integrations: [] }) => {
      return data.integrations;
    },
  });

  switch (app?.name) {
    case "Email":
      return (
        <Email
          isNew={isNew}
          action={action}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          updateAction={updateAction}
          reports={reports}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "Epicor":
      return (
        <Epicor
          integrations={integrations}
          isNew={isNew}
          action={action}
          updateAction={updateAction}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "Slack":
      return (
        <Slack
          isNew={isNew}
          removeWorkflowAction={removeWorkflowAction}
          addWorkflowAction={addWorkflowAction}
          updateAction={updateAction}
          action={action}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "SMS":
      return (
        <SMS
          isNew={isNew}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          action={action}
          updateAction={updateAction}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "Philips Hue":
      return (
        <Hue
          isNew={isNew}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          action={action}
          updateAction={updateAction}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "Cycle Counts":
      return (
        <CycleCounts
          isNew={isNew}
          integrations={integrations}
          updateAction={updateAction}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          action={action}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    case "Data Push":
      return (
        <DataPush
          isNew={isNew}
          updateAction={updateAction}
          addWorkflowAction={addWorkflowAction}
          removeWorkflowAction={removeWorkflowAction}
          integrations={integrations}
          action={action}
          resetSelectedApp={resetSelectedApp}
          app={app}
        />
      );
    default:
      return null;
  }
}
