import React from "react";
import { Pagination } from "antd";

export default function Paginate({
  length,
  pageSize,
  setPageSize,
  currentPage,
  setPage,
}: {
  length: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  currentPage: number;
  setPage: (page: number) => void;
}) {
  return (
    <div className="pagination">
      {length >= 0 && (
        <Pagination
          total={length}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={pageSize}
          current={currentPage + 1}
          onChange={(newPage, pageSize) => {
            setPage(newPage - 1);
          }}
          onShowSizeChange={(current, value) => {
            setPageSize(Number(value));
          }}
        />
      )}
    </div>
  );
}
