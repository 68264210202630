import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Connection as ConnectionType,
  Setting as SettingType,
} from "/app/src/models";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string | undefined;
  value: string | undefined;
  number: boolean | undefined;
}

export default function Headers({
  connection,
}: {
  connection: ConnectionType;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  function formatForm(values: FormValues, connectionId: number | undefined) {
    return {
      name: values.name,
      connectionId: connectionId,
      type: "header",
      value: values.value,
      number: values.number ? 2 : 1,
    };
  }

  const { mutateAsync: addHeading } = useMutation({
    mutationFn: (setting: SettingType) => {
      return settingService.createSingle(setting).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", { connectionId: connection.id, type: "header" }],
        (oldData: { settings: SettingType[] }) => {
          return { settings: [...oldData.settings, response.setting] };
        },
      );
    },
  });

  const addHeadingHandler = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const formmattedForm = formatForm(values, connection.id);
    await addHeading(formmattedForm).finally(() => {
      actions.resetForm();
    });
  };

  const { mutateAsync: removeHeader } = useMutation({
    mutationFn: (setting: SettingType) => {
      return settingService.deleteSingle(setting.id).then(() => {
        const settingId = setting.id;
        return { settingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", { connectionId: connection.id, type: "header" }],
        (oldData: { settings: SettingType[] }) => {
          return {
            settings: oldData.settings.filter(
              (setting: SettingType) => setting.id !== response.settingId,
            ),
          };
        },
      );
    },
  });

  const newSettingForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 7, order: 1 }}
          xl={{ span: 7, order: 1 }}
        >
          <Form.Item
            name="name"
            label={t("translation:key")}
            hasFeedback={false}
          >
            <Input
              name="name"
              placeholder={t("translation:enter_key")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 7, order: 2 }}
          xl={{ span: 7, order: 2 }}
        >
          <Form.Item
            name="value"
            label={t("translation:value")}
            hasFeedback={false}
          >
            <Input
              name="value"
              placeholder={t("translation:enter_value")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 8, order: 3 }}
          lg={{ span: 4, order: 3 }}
          xl={{ span: 4, order: 3 }}
        >
          <Form.Item
            name="number"
            label={t("translation:private")}
            tooltip={{
              title: t("translation:private_description"),
              icon: <QuestionCircleOutlined />,
            }}
          >
            <Checkbox name="number" />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24, order: 4 }}
          sm={{ span: 24, order: 4 }}
          md={{ span: 16, order: 4 }}
          lg={{ span: 6, order: 4 }}
          xl={{ span: 6, order: 4 }}
        >
          <SubmitButton
            type="primary"
            size="large"
            block
            style={{ marginTop: "30px" }}
            disabled={isSubmitting}
          >
            {t("translation:create_header")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initialFormValues: FormValues = {
    value: "",
    name: "header",
    number: true,
  };

  const { data: settings } = useQuery({
    queryKey: ["settings", { connectionId: connection.id, type: "header" }],
    queryFn: () => {
      return settingService.getAll(
        buildParams({ connectionId: connection.id, type: "header" }),
      );
    },
    initialData: { settings: [] },
    select: (data: { settings: SettingType[] }) => {
      return data.settings;
    },
  });

  return (
    <div>
      <h2>{t("translation:headers")}</h2>
      <div className="newconnection">
        <div>
          {settings.map((setting) => (
            <EditHeader
              header={setting}
              removeHeader={removeHeader}
              key={setting.id}
            />
          ))}
        </div>
        <Formik
          component={newSettingForm}
          initialValues={initialFormValues}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "value", type: "string", required: true },
          ])}
          enableReinitialize
          onSubmit={addHeadingHandler}
        />
      </div>
    </div>
  );
}

function formatUpdateForm(values: FormValues) {
  return {
    name: values.name,
    value: values.value,
    number: values.number ? 2 : 1,
  };
}

function EditHeader({
  header,
  removeHeader,
}: {
  header: SettingType;
  removeHeader: (setting: SettingType) => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateHeader } = useMutation({
    mutationFn: (setting: SettingType) => {
      return settingService
        .updateSingle(setting.id, setting)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [
          "settings",
          { connectionId: response.setting.connectionId, type: "header" },
        ],
        (oldData: { settings: SettingType[] }) => {
          console.log(oldData);
          return {
            settings: oldData.settings.map((setting: SettingType) => {
              if (setting.id === response.setting.id) {
                return response.setting;
              }
              return setting;
            }),
          };
        },
      );
    },
  });
  const editHeadingForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    isSubmitting,
  }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={2}>{"Header:"}</Col>
        <Col span={5}>
          <Form.Item name="name" hasFeedback={false}>
            <Input
              size="large"
              name="name"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <h1 style={{ textAlign: "center" }}>:</h1>
        </Col>
        <Col span={8}>
          <Form.Item name="value" hasFeedback={false}>
            <Input
              size="large"
              name="value"
              placeholder={t("translation:enter_value")}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="number" label={t("translation:private")}>
            <Checkbox name="number" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton
            type="primary"
            size="large"
            disabled={!dirty || isSubmitting || !isValid}
            block
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
        <Col span={3}>
          <Button
            onClick={() => removeHeader(header)}
            type="default"
            size="large"
            block
            disabled={isSubmitting}
          >
            {t("translation:remove")}
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const handleSubmit = async (values: FormValues) => {
    await updateHeader({ id: header.id, ...formatUpdateForm(values) });
  };
  return (
    <Formik
      enableReinitialize
      component={editHeadingForm}
      initialValues={{
        name: header.name,
        value: header.value,
        number: header.number === 2 ? true : false,
      }}
      validationSchema={simpleSchemaBuilder([
        { name: "name", type: "string", required: true },
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={handleSubmit}
    />
  );
}
