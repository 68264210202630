import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function ClearLogs({ clearLogs }: { clearLogs: () => void }) {
  const { t } = useTranslation();
  return (
    <Button danger onClick={() => clearLogs()}>
      {t("translation:clear")} {t("translation:logs")}
    </Button>
  );
}
