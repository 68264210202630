import React from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, Input } from "formik-antd";
import { epicorThemes } from "/app/src/constants/themes";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration, Setting as SettingType } from "/app/src/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  name: string | undefined;
  baseTable: string | undefined;
}

interface SettingFormValues {
  data: string;
}

export function Setting({ setting }: { setting: SettingType }) {
  const { t } = useTranslation();

  const settingOneForm: (
    props: FormikProps<SettingFormValues>,
  ) => JSX.Element = ({ dirty, isValid }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item name="data" label={setting.name}>
            <Select name="baseTable" size="large" value={setting.value}>
              <Select.Option value="Manual" key="1">
                {t("translation:manual")}
              </Select.Option>
              <Select.Option value="Automatic" key="2">
                {t("translation:automatic")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const settingForm: (props: FormikProps<SettingFormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Form.Item name="data" label={setting.name}>
            <Input
              name="data"
              value={setting.value}
              className="data"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  if (setting.number === 1) {
    return (
      <div>
        <Formik
          component={settingOneForm}
          initialValues={{ data: "" }}
          onSubmit={(values, actions) => {}}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Formik
          component={settingForm}
          initialValues={{ data: "" }}
          onSubmit={(values, actions) => {}}
        />
      </div>
    );
  }
}

export default function Settings({
  integration,
  settings,
}: {
  integration: Integration;
  settings: SettingType[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  const updateIntegrationHandler = async (values: FormValues) => {
    if (integration.id) {
      await updateIntegration({ id: integration.id, name: values.name });
    }
  };

  const newSettingForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={18}>
          <Form.Item name="name" label={t("translation:name")}>
            <Input name="name" className="name" size="large" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="baseTable" label={t("translation:theme")}>
            <Select name="baseTable" size="large">
              {epicorThemes.map((theme) => (
                <Select.Option key={theme.name} value={theme.value}>
                  {theme.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="box">
      <h1>{t("translation:settings")}</h1>
      <Formik
        component={newSettingForm}
        initialValues={{
          name: integration.name,
          baseTable: integration.baseTable,
        }}
        enableReinitialize
        onSubmit={updateIntegrationHandler}
      />

      {settings.map((setting) => (
        <Setting setting={setting} key={setting.id} />
      ))}
    </div>
  );
}
