import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService, settingService } from "/app/src/services";
import {
  App,
  Integration as IntegrationType,
  Setting as SettingType,
} from "/app/src/models";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Status from "./status";
import Settings from "./settings";
import Mappings from "./mappings";
import Tasks from "./tasks";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();

  // Get integration
  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  // Get settings
  const { data: settings } = useQuery({
    queryKey: ["settings", { integrationId, type: "integration" }],
    queryFn: () => {
      return settingService.getAll(
        buildParams({ integrationId: integration.id, type: "integration" }),
      );
    },
    initialData: { settings: [] },
    select: (data: { settings: SettingType[] }) => {
      return data.settings;
    },
  });

  const links = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "status",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/mappings`,
      label: "mappings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/settings`,
      label: "settings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/tasks`,
      label: "logs",
    },
  ];

  return (
    <div className="integration">
      <Helmet>
        <title>{integration.name}</title>
      </Helmet>

      <Row gutter={20}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          lg={{ span: 6, order: 1 }}
        >
          <SideMenuBox links={links} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 2 }}
        >
          {integration.id && (
            <Routes>
              <Route
                path="mappings"
                element={<Mappings integration={integration} />}
              />
              <Route
                path="tasks/*"
                element={<Tasks integration={integration} />}
              />
              <Route
                path="settings"
                element={
                  <Settings integration={integration} settings={settings} />
                }
              />
              <Route path="/" element={<Status integration={integration} />} />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
