import React from "react";

import SingleAccess from "./singleAccess";
import { Access } from "/app/src/models";

/**
 * Component to render the list of accesses for a given resource
 * @param active boolean to determine if the component should be rendered
 * @param data list of accesses to be rendered
 */
export default function Accesses({
  active,
  data,
}: {
  active: boolean;
  data: Access[];
}) {
  if (!active) {
    return null;
  }

  return (
    <>
      {data.map((access: Access) => {
        return <SingleAccess access={access} key={access.resourceId} />;
      })}
    </>
  );
}
