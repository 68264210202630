import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { statusService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { StatusType } from "/app/src/contexts/types";

/**
 * Component for displaying restart button
 * @returns JSX.Element
 *
 */
export default function RestartButton() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(t("translation:confirm_restart"));
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /**
   * Shows modal handler
   */
  const showModal = useCallback(() => {
    setOpen(true);
  }, []);

  /**
   * Cancel handler for modal
   */
  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  /**
   * Ok handler for modal
   */
  const handleOk = useCallback(() => {
    setModalText(t("translation:confirm_restart"));
    setConfirmLoading(true);
    statusService.restart().then(() => {
      queryClient.setQueryData(["status"], (oldData: StatusType) => {
        return {
          ...oldData,
          message: "restarting",
        };
      });
      navigate("/status/restart");
      //navigate
    });
  }, [navigate, queryClient, t]);

  return (
    <>
      <Button block type="primary" danger onClick={showModal}>
        {t("translation:restart_itempath")}
      </Button>
      <Modal
        title={t("translation:restart_itempath")}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}
