import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";

/**
 * Component to display the status of the ItemPath connection in the header
 */
export default function ConnectionHeaderStatus() {
  const { connected } = useStatusContext();
  const { t } = useTranslation();
  if (!connected)
    return (
      <div className="statusMessage warning">
        {t("translation:itempath_api_connection_failed")}
      </div>
    );
}
