import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import deleteIcon from "/app/src/components/generic/title/deleteIcon.svg";
import { isEnterKeyOrClick } from "/app/src/helpers/onClickOnKeyPress";

const { confirm } = Modal;

export default function DeleteButtonPopup({
  deleteObjectFn,
  refresh,
  confirmDeleteText,
}: {
  deleteObjectFn: () => Promise<any>;
  refresh: () => void;
  confirmDeleteText: string;
}) {
  function ShowConfirm(
    okText: string,
    cancelText: string,
    title: string,
    deleteObjectFn: () => Promise<any>,
    refresh: () => void,
  ) {
    confirm({
      okText: okText,
      okButtonProps: { danger: true },
      cancelText: cancelText,
      title: title,
      onOk() {
        deleteObjectFn().then((response) => {
          //refresh the list of reports if channel successfully removed
          if (response.deleted === "true") {
            refresh();
          }
        });
      },
    });
  }

  const { t } = useTranslation();
  const handleEnterPressOrClick = (event) => {
    if (isEnterKeyOrClick(event)) {
      ShowConfirm(
        t("translation:remove"),
        t("translation:do_not_remove"),
        confirmDeleteText,
        deleteObjectFn,
        refresh,
      );
    }
  };

  return (
    <button className="buttonless">
      <img
        src={deleteIcon}
        alt="Delete"
        onClick={handleEnterPressOrClick}
        onKeyDown={handleEnterPressOrClick}
      />
    </button>
  );
}
