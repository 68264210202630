import { useContextForPermission } from "./usePermission";
import { accessService } from "../services";
import { useQuery } from "@tanstack/react-query";
import { buildParams } from "../helpers";
import { Access } from "../models";

/**
 * Hook to get access permissions for a resource. If the resource has an access
 * object, return the permissions from the access object. Otherwise, return the
 * permissions from the role.
 * @returns canView, canEdit, canDelete
 */
export default function useAccessPermission(
  roleId,
  code,
  resourceType,
  resourceId
) {
  const { canEdit, canView, canDelete } = useContextForPermission(code);

  const { data } = useQuery({
    queryKey: ["access", roleId, resourceType, resourceId],
    queryFn: () => {
      return accessService
        .getAll(buildParams({ roleId, resourceType, resourceId }))
        .then((response) => {
          return response.accesses;
        });
    },
    select: (data: Access[]) => {
      if (data.length > 0) {
        return data[0];
      }
      return {};
    },
    initialData: [] as Access[],
    enabled: Boolean(resourceId),
  });
  //if access exists for the resource, return permissions set in access
  if (data?.id) {
    return {
      canView: data.actionList.includes("view"),
      canEdit: data.actionList.includes("edit"),
      canDelete: data.actionList.includes("delete"),
    };
  }
  //no access exists for resource, return permissions from role
  return {
    canView,
    canEdit,
    canDelete,
  };
}
