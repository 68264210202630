import React, { useState } from "react";
import { Row, Col } from "antd";
import Logs from "/app/src/components/generic/components/tasks/logsTable";
import TasksTable from "/app/src/components/generic/components/tasks/tasksTable";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";

export default function CycleCountLogs({
  integration,
}: {
  integration: Integration;
}) {
  const [taskId, setTaskId] = useState<number>();
  const { t } = useTranslation();

  const statusMappings = {
    "1": t("translation:success_created"),
    "2": t("translation:failed_created"),
  };

  return (
    <div>
      <Row gutter={20}>
        <Col span={6}>
          <TasksTable
            integrationId={integration.id as number}
            setTaskId={setTaskId}
            emptyText={t("translation:no_counts_found")}
          />
        </Col>
        <Col span={18}>
          <Logs
            taskId={taskId}
            mappings={statusMappings}
            notFoundText={t("translation:logs_not_found_cycle_count")}
            notSelectedText={t("translation:select_cycle_count")}
          />
        </Col>
      </Row>
    </div>
  );
}
