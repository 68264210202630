import React from "react";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { roleService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { InfoCircleOutlined } from "@ant-design/icons";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string;
  admin: boolean;
}

export default function NewRoleForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const newRoleForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={20}>
        <Col span={12}>
          <Form.Item
            name="name"
            label={t("translation:role_name")}
            hasFeedback={false}
          >
            <Input name="name" size="large" />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name="admin"
            label={t("translation:full_access")}
            tooltip={{
              title: t("translation:full_access_description"),
              icon: <InfoCircleOutlined />,
            }}
          >
            <Checkbox name="admin" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubmitButton type="primary" size="large" disabled={!dirty}>
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div className="box">
      <Formik
        component={newRoleForm}
        initialValues={{ name: "", admin: false }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
        ])}
        onSubmit={(values, actions) => {
          roleService
            .createSingle(values)
            .then((response) => {
              if (response?.errors) {
                handleSubmissionErrors(response.errors, actions.setFieldError);
                actions.setSubmitting(false);
              } else {
                actions.resetForm();
                navigate(`/users`);
              }
            })
            .catch((error) => {
              console.log(error);
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}
