import React from "react";
import { AxiosWrapper } from "./AxiosWrapper";
import { AuthProvider } from "./contexts/authentication";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppBody from "./appBody";
import { msalConfig } from "./authConfig";

import "./stylesheets/index.scss";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  // Rollbar does not currently have typescript support
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.NODE_ENV,
    enabled: false,
    verbose: true, //console log errors that get sent to rollbar
    ignoredMessages: ["BAD REQUEST", "Failed to fetch"],
    checkIgnore: function (isUncaught: boolean, args: any, payload: any) {
      // ignore all 'debug' items
      return payload.level === "debug";
    },
  };

  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <AxiosWrapper />
          <Provider config={rollbarConfig}>
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <AppBody />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ErrorBoundary>
          </Provider>
        </AuthProvider>
      </MsalProvider>
    </BrowserRouter>
  );
}

export default App;
