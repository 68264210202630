import React from "react";
import { Link } from "react-router-dom";
import { Col } from "antd";
import { StorageUnit as StorageUnitType } from "/app/src/models";
export default function StorageUnits({
  storageUnit,
}: {
  storageUnit: StorageUnitType;
}) {
  return (
    <Col span={8}>
      <div className={`storageUnit type_${storageUnit.type}`}>
        <h4>
          <Link to={`/explorer/storage_units/${storageUnit.id}`}>
            {storageUnit.name}
          </Link>
        </h4>
      </div>
    </Col>
  );
}
