import { shelfService, locationService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import Location from "./location";
import { useQuery } from "@tanstack/react-query";
import {
  Carrier as CarrierType,
  Shelf as ShelfType,
  Location as LocationType,
} from "/app/src/models";
export default function Shelf({ carrier }: { carrier: CarrierType }) {
  const { data: shelf } = useQuery({
    queryKey: ["shelf", carrier.id],
    queryFn: () => {
      return shelfService.getAll(
        buildParams({ carrierId: carrier.id, orderby: "Name" })
      );
    },
    initialData: { shelves: [] },
    select: (data: { shelves: ShelfType[] }) => {
      if (data.shelves.length > 0) {
        return data.shelves[0];
      }
      return {};
    },
  });

  const { data: locations } = useQuery({
    queryKey: ["locations", shelf.id],
    queryFn: () => {
      return locationService.getAll(buildParams({ shelfId: shelf.id }));
    },
    initialData: { locations: [] },
    select: (data: { locations: LocationType[] }) => {
      return data.locations;
    },
  });

  return (
    <div className="shelf">
      {locations.map((location) => (
        <Location location={location} shelf={shelf} key={location.id} />
      ))}
    </div>
  );
}
