import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
export default function SystemTypeIndicator() {
  const { t } = useTranslation();
  const { systemType, loading } = useStatusContext();
  if (!loading && systemType)
    return (
      ["test", "development", "quality"].includes(systemType) && (
        <div className="systemType" id={systemType}>
          <p id={systemType}>
            {systemType} {t("translation:system")}
          </p>
        </div>
      )
    );
  else return null;
}
