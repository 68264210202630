import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { orderService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";

import Search from "../search";

import Table from "/app/src/components/generic/tables/table";
import { useSort } from "/app/src/hooks";
import { useTranslation } from "react-i18next";
import { Order as OrderType } from "/app/src/models";
import { Column } from "react-table";
import { useQuery } from "@tanstack/react-query";
export default function Orders() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns: Column<OrderType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
      {
        Header: t("translation:description"),
        accessor: "description",
      },
      {
        Header: t("translation:info1"),
        accessor: "Info1",
      },
    ];
  }, [t]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");

  const goToOrder = (orderId: number) => {
    navigate(`/explorer/orders/${orderId}`);
  };

  //Gets all orders
  const { data: orders, isFetching } = useQuery({
    queryKey: ["orders", page, pageSize, searchString, sort],
    queryFn: () => {
      return orderService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          state: "queued",
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { orders: [] },
    select: (data: { orders: OrderType[] }) => {
      return data.orders;
    },
  });

  //Gets count of all orders
  const { data: ordersCount } = useQuery({
    queryKey: ["ordersCount", searchString],
    queryFn: () => {
      return orderService.getCount(
        buildParams({ search: searchString, state: "queued" }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:orders")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={orders}
        tableColumns={columns}
        length={ordersCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        rowClicked={goToOrder}
        emptyText={t("translation:no_orders")}
      />
    </div>
  );
}
