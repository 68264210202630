import React, { Fragment } from "react";
import { Skeleton } from "antd";

import SnapshotChart from "./snapshotChart";
import ReportTable from "./reportTable";
import { Report, ReportColumn } from "/app/src/models";

/**
 * Component to display the snapshot chart and report table
 * @param param0 props contain report and report columns
 */
export default function Data({
  report,
  columns,
}: {
  report: Report;
  columns: ReportColumn[];
}) {
  return (
    <div className="reportData">
      {report.id ? (
        <>
          <SnapshotChart report={report} />
          <ReportTable columns={columns} report={report} />
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
