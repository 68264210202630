import {
  genericGetSingle,
  genericCreateSingle,
  genericGetAll,
} from "../helpers/apiCall";
import { Order } from "../models";
import { Error } from "../types";

/**
 * Get all orders
 * @param params URLSearchParams
 * @returns Promise<{ orders: Order[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ orders: Order[] }> {
  return genericGetAll("orders", params);
}

/**
 * Get a single order
 * @param id string
 * @returns Promise<{ order: Order }>
 */
function getSingle(id: string): Promise<{ order: Order }> {
  return genericGetSingle("orders", id);
}

function createSingle(order: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ order: Order } | Error> {
  return genericCreateSingle("orders", order);
}

/**
 * Gets the count of orders
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("orders", params);
}

export const orderService = {
  getAll,
  getSingle,
  createSingle,
  getCount,
};
