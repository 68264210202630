import { object, string } from "yup";
import i18next from "/app/src/locales/config";

export const smsSchema = object().shape({
  to: string()
    .required(i18next.t("translation:phone_required"))
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      { message: "Please enter valid number.", excludeEmptyString: false }
    ),
  message: string().required(i18next.t("translation:text_message_required")),
});
