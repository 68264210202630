import React from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Checkbox } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  value: boolean | undefined;
}

/**
 * Component for creating and updating the verify setting
 * @param param0 integration
 */
export default function Verify({ integration }: { integration: Integration }) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const verifySettingQuery = useQuery({
    queryKey: ["setting", "verify", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "verify" }),
      ),
  });

  const { mutateAsync: verifyMutation } = useMutation({
    mutationFn: (values: FormValues) => {
      if (verifySettingQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "verify",
            value: values.value,
            type: "verify",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(verifySettingQuery.data?.settings[0]?.id, {
            value: values.value,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["setting", "verify", integration.id], {
        settings: [data.setting],
      });
    },
  });

  const verifySubmitHandler = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    await verifyMutation(values).finally(() => {
      actions.resetForm();
    });
  };

  const verifyLabelContent = (
    <>
      {t("translation:verify")}
      <ToolTip tip={t("translation:verify_tip")} />
    </>
  );

  /**
   * Form for the verify setting
   */
  const verifyForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={10}>
          <Form.Item name="value" label={verifyLabelContent}>
            <Checkbox name="value" />
          </Form.Item>
        </Col>
        <Col span={14}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <Formik
      component={verifyForm}
      initialValues={{
        value:
          verifySettingQuery.data?.settings[0]?.value === "1" ? true : false,
      }}
      enableReinitialize
      onSubmit={verifySubmitHandler}
    />
  );
}
