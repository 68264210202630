import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Row, Col, message, Button } from "antd";
import { resetPasswordSchema } from "/app/src/schemas";
import { userService } from "/app/src/services";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import { useNumberId } from "../../hooks/useNumberId";

interface FormValues {
  password: string;
}

interface CodeParams {
  code?: string;
}

export default function ResetForm() {
  const { t } = useTranslation();
  const success = () => {
    message.success(t("translation:password_saved_sucessfully"));
    setChanged(true);
  };
  const [changed, setChanged] = useState(false);
  const { user } = useAuthState();
  const { code } = useNumberId() as CodeParams;

  const navigate = useNavigate();

  // redirect to home if already logged in
  useEffect(() => {
    if (user?.id) {
      navigate("/");
    }
  });

  const ResetPasswordForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    errors,
    touched,
    isValid,
    dirty,
  }) => (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item name="password" label={t("translation:new_password")}>
            <Input.Password
              name="password"
              size="large"
              className={
                errors.password && touched.password ? "input-error" : undefined
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:reset_password")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            {!changed ? (
              <div>
                <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  {t("translation:enter_new_password")}
                </h3>
                <Formik
                  component={ResetPasswordForm}
                  validationSchema={resetPasswordSchema}
                  initialValues={{
                    password: "",
                  }}
                  onSubmit={(values, actions) => {
                    if (code) {
                      userService
                        .updateSingle(code, formatForm(values))
                        .then((response) => {
                          if (response?.errors) {
                            handleSubmissionErrors(
                              response.errors,
                              actions.setFieldError,
                            );
                            actions.setSubmitting(false);
                          } else {
                            success();
                          }
                        });
                    } else {
                      actions.setSubmitting(false);
                    }
                  }}
                />
              </div>
            ) : (
              <Button
                onClick={() => {
                  navigate("/login");
                }}
              >
                {t("translation:login")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
function formatForm(values: FormValues) {
  return {
    password: values.password,
  };
}
