import { carrierService } from "/app/src/services";
import Carrier from "./carrier";
import { useQuery } from "@tanstack/react-query";
import {
  StorageUnit as StorageUnitType,
  Carrier as CarrierType,
} from "/app/src/models";

export default function VLM({ storageUnit }: { storageUnit: StorageUnitType }) {
  // Get data
  const { data: carriers } = useQuery({
    queryKey: ["carriers", storageUnit.id],
    queryFn: () => {
      return carrierService.getAllFromStorageUnit(storageUnit.id, {
        orderby: "Name",
      });
    },
    initialData: { carriers: [] },
    select: (data: { carriers: CarrierType[] }) => {
      return data.carriers;
    },
  });

  return (
    <div className="vlm">
      <h1>{storageUnit?.name}</h1>
      {carriers.map((carrier) => (
        <Carrier carrier={carrier} key={carrier.id} />
      ))}
    </div>
  );
}
