import React from "react";
import { Button } from "antd";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";

export default function TestWorkflow({
  workflowId,
}: {
  workflowId: number | undefined;
}) {
  const { t } = useTranslation();

  return (
    <div className="testWorkflow">
      <Button
        type="default"
        block
        onClick={() => {
          if (!workflowId) return;
          workflowService.testSingle(workflowId);
        }}
      >
        {t("translation:test_")}
        {t("translation:workflow")}
      </Button>
    </div>
  );
}
