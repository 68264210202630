import { makeAPIRequest, genericGetAll } from "../helpers/apiCall";
import { apiCall } from "../models";

/**
 * Get all calls
 * @param params URLSearchParams
 * @returns Promise<{ calls: apiCall[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ calls: apiCall[] }> {
  return genericGetAll("calls", params);
}

/**
 * Delete all calls
 * @returns Promise<{ calls: apiCall[] }>
 */
function deleteAll(): Promise<{ calls: apiCall[] }> {
  return makeAPIRequest({ url: "/calls/delete", method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of calls
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("calls", params);
}

export const callService = {
  getAll,
  deleteAll,
  getCount,
};
