import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { logService } from "/app/src/services";
import { buildCreationDateQuery, buildParams, getJSON } from "/app/src/helpers";
import { Sort } from "/app/src/types";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Table from "/app/src/components/generic/tables/table";
import Controls from "./_controls";
import getOrderByQuery from "/app/src/helpers/table";
import ToolTip from "/app/src/components/generic/components/toolTip";

/**
 * Component for displaying the logs of a data push integration
 * @param integration the integration to display the logs for
 */
export default function DataPushLogs({
  integration,
}: {
  integration: Integration;
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState<Sort>([]);
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: [
      "dataPushLogs",
      integration.id,
      page,
      pageSize,
      searchString,
      beforeTime,
      afterTime,
      status,
      sort,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        integrationId: integration.id,
        page,
        limit: pageSize,
        search: searchString,
        creationDate: creationDateQuery,
        status,
        orderby: getOrderByQuery(sort),
        appId: null,
      });
      return logService.getAll(params);
    },
    initialData: { logs: [] },
  });

  const { data: logLength } = useQuery({
    queryKey: [
      "dataPushLogsLength",
      integration.id,
      beforeTime,
      afterTime,
      searchString,
      status,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        integrationId: integration.id,
        search: searchString,
        creationDate: creationDateQuery,
        status,
        appId: null,
      });
      return logService.getCount(params);
    },
    initialData: { count: 0 },
  });

  /**
   * Converts the data to json and then returns the value
   * of the passed in key
   * @param data json string
   * @param key key to return the value of
   */
  function parseOutData(data: string, key: string) {
    try {
      const parsedData = JSON.parse(data);
      //convert to string to avoid react error
      return JSON.stringify(parsedData[key]);
    } catch (e) {
      return data;
    }
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:creationDate"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
      {
        Header: () => (
          <>
            {t("translation:time_ms")}
            <ToolTip tip={t("translation:time_description")} />
          </>
        ),
        accessor: "time",
      },
      {
        Header: t("translation:transaction_id"),
        accessor: "taskName",
      },
      {
        Header: t("translation:sent_data"),
        accessor: "description",
        Cell: ({ cell: { value } }) => <p>{parseOutData(value, "Sent")}</p>,
      },
      {
        Header: t("translation:response"),
        accessor: "description",
        id: "response",
        Cell: ({ cell: { value } }) => (
          <p>{getJSON(parseOutData(value, "Received"))}</p>
        ),
      },
      {
        Header: t("translation:errors"),
        accessor: "error",
        id: "error",
      },
      {
        Header: (
          <>
            {t("translation:status")}
            <ToolTip tip={t("translation:status_description")} />
          </>
        ),
        accessor: "status",
      },
    ];
  }, [t]);

  return (
    <div>
      <Controls
        setSearchString={setSearchString}
        setBeforeTime={setBeforeTime}
        setAfterTime={setAfterTime}
        setStatus={setStatus}
      />
      <Table
        rows={data.logs}
        tableColumns={columns}
        length={logLength.count}
        sort={sort}
        loading={isLoading}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={t("translation:no_logs_found")}
      />
    </div>
  );
}
