import { makeAPIRequest } from "../helpers/apiCall";

function getStatus(params: URLSearchParams = null) {
  return makeAPIRequest({ url: "/status", params, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Restarts ItemPath
 *
 * @returns {Promise<any>}
 *
 */
function restart() {
  return makeAPIRequest({ url: "/restart", method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const statusService = {
  getStatus,
  restart,
};
