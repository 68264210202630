import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { ReportType } from "../models";

/**
 * Gets all report types
 * @param params URLSearchParams
 * @returns Promise<{ report_types: ReportType[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ report_types: ReportType[] }> {
  /**
   * Exports the report of the passed in report id.
   * @param {!URLSearchParams} params URLSearchParams to be converted to query string.
   * @return {Promise<any>} Returns the promise containing data of API response.
   */
  return genericGetAll("report_types", params);
}

/**
 * Gets a single report type
 * @param id number | string
 * @returns Promise<{ report_type: ReportType }>
 */
function getSingle(id: number | string): Promise<{ report_type: ReportType }> {
  /**
   * Exports the report of the passed in report id.
   * @param {!number | string} id id of the model to be fetched.
   * @return {Promise<any>} Returns the promise containing data of API response.
   */
  return genericGetSingle("report_types", id);
}

/**
 * Gets the count of report types
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("report_types", params);
}

export const reportTypeService = {
  getAll,
  getSingle,
  getCount,
};
