import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { Integration } from "/app/src/models";

import ManyTransactions from "./manyTransactions";

export default function Test({ integration }: { integration: Integration }) {
  const { t } = useTranslation();

  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <h2>{t("translation:send_transactions_manually")}</h2>
        <ManyTransactions integration={integration} />
      </Col>
    </Row>
  );
}
