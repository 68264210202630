import { object, string } from "yup";
import i18next from "/app/src/locales/config";

export const newFirstUserSchema = object().shape({
  username: string()
    .trim()
    .required(i18next.t("translation:username_is_required")),

  email: string()
    .trim()
    .email(i18next.t("translation:valid_email_warning"))
    .required(i18next.t("translation:email_is_required")),
  key: string()
    .trim()
    .required(i18next.t("translation:recovery_key_is_required")),

  password: string()
    .trim()
    .required(i18next.t("translation:password_is_required"))
    .min(4, i18next.t("translation:short_password_warning")),

  license: string()
    .trim()
    .required(i18next.t("translation:license_is_required")),
});
