import { genericGetSingle, genericGetAll } from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("zones", params);
}

function getSingle(zoneId: string) {
  return genericGetSingle("zones", zoneId);
}

/**
 * Gets the count of zones
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("zones", params);
}

export const zoneService = {
  getAll,
  getSingle,
  getCount,
};
