import React, { useState, useEffect, useContext } from "react";
import { ResponsiveLineCanvas, Serie } from "@nivo/line";
import { Spin } from "antd";
import { getDateFormat } from "/app/src/helpers/time";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";

import { useAuthState } from "/app/src/contexts/authentication";
import { Widget } from "/app/src/models";

export default function LineChart({ widget }: { widget: Widget }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<Serie[]>([]);

  const { user } = useAuthState();
  const { timeZone } = useContext(TimeZoneContext);
  const { data } = useContext(WidgetSnapshotDataContext);

  const [frequency, setFrequency] = useState<{
    name:
      | "day"
      | "minute"
      | "hour"
      | "month"
      | "year"
      | "millisecond"
      | "second"
      | undefined;
    xFormat: string;
    axisFormat: string;
    interval: number;
  }>({
    name: "day",
    xFormat: "%Y-%m-%d",
    axisFormat: "%b %d",
    interval: 10,
  });

  const addToData = (newData: Serie[]) => {
    setChartData(newData);
  };

  let margins = { top: 5, right: 10, bottom: 50, left: 50 };
  if (widget.size === 1) {
    margins = { top: 5, right: 10, bottom: 50, left: 10 };
  }

  useEffect(() => {
    // React recommends declaring functions inside useEffect if possible. That way
    // any dependencies in the function are caught in the useEffect dependency array
    function updateFrequency(value: string) {
      switch (value) {
        case "minute":
          setFrequency({
            name: "minute",
            xFormat: " %b %d, %I:%M %p",
            axisFormat: "%I:%M %p",
            interval: 10,
          });
          break;
        case "hour":
          setFrequency({
            name: "hour",
            xFormat: " %b %d, %I:%M %p",
            axisFormat: "%I:%M %p",
            interval: 2,
          });
          break;
        case "day":
          setFrequency({
            name: "day",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 2,
          });
          break;
        case "week":
          setFrequency({
            name: "day",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 2,
          });
          break;
        case "month":
          setFrequency({
            name: "month",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 2,
          });
          break;
        case "year":
          setFrequency({
            name: "year",
            xFormat: "%Y-%m",
            axisFormat: "%b %d",
            interval: 2,
          });
          break;
        default:
          console.log("frequency value did not match any case");
      }
    }
    // // Calculate the limit of the data based on the width of the widget
    // var limit = widget.position.substr(0, widget.position.indexOf('-')); //substring the first value -> the width
    // limit = parseInt(limit) * 3;
    if (widget.datasets) {
      widget.datasets.forEach((d) => {
        const dataset = data.snapshotsData.find((x) => x.id === d.id);
        if (dataset !== undefined) {
          if (dataset.data) {
            const dataToAdd = dataset.data as Serie;
            const colour = dataset.colour as string;
            const newData = addData(chartData, dataToAdd, timeZone, colour);
            addToData(newData);
          }
          if (dataset.frequency) {
            updateFrequency(dataset.frequency);
          }
        }
      });
    }
    setLoading(false);
  }, [data, widget.datasets, user, timeZone, chartData]);

  return !loading ? (
    <div className="chart time">
      {chartData && (
        <ResponsiveLineCanvas
          data={chartData}
          enableGridX={false}
          curve="linear"
          margin={margins}
          xScale={{
            type: "time",
            format: "%s",
            useUTC: false,
            precision: frequency.name,
          }}
          lineWidth={4}
          pointSize={10}
          pointBorderWidth={2}
          colors={(line) => line.colour}
          pointColor={"#FFFFFF"}
          pointBorderColor={{ from: "serieColor" }}
          xFormat={`time:${frequency.xFormat}`}
          axisBottom={{
            format: frequency.axisFormat,
            tickValues: `every ${frequency.interval} ${frequency.name}s`,
          }}
        />
      )}
    </div>
  ) : (
    <Spin />
  );
}

function addData(
  data: Serie[],
  newData: Serie,
  timeZone: string,
  colour: string
) {
  // Loop through grouped sets
  if (!Array.isArray(newData)) {
    Object.keys(newData).forEach((key) => {
      const group: {
        id: string;
        colour: string;
        data: { x: string; y: number }[];
      } = {
        id: key,
        data: [],
        colour: colour,
      };
      group["colour"] = colour;
      // Loop through records for each item

      newData[key].forEach((item: { name: string; value: number }) => {
        const record = {
          x: getDateFormat(item["name"], "X", timeZone),
          y: item["value"],
        };
        group["data"].push(record);
      });
      data.push(group);
    });
  }
  return data;
}
