import { useState } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import { integrationService } from "/app/src/services";
import { ColumnType, TextType, GroupingType } from "./mappingTypes";
import { useTranslation } from "react-i18next";
import { newMappingSchema } from "/app/src/schemas/apps/dataPush/newMappingSchema";
import { Integration, ReportColumnType, Mapping } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";

interface FormValues {
  key: string | undefined;
  value: string | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: string | undefined;
  parentMappingId?: number;
}

export default function NewMapping({
  integrationId,
  addMapping,
  columnTypes,
  parentId = undefined,
}: {
  integrationId?: number;
  addMapping: (mapping: Mapping) => Promise<any>;
  columnTypes: ReportColumnType[];
  parentId?: number | undefined;
}) {
  const { t } = useTranslation();
  const [type, setType] = useState("");

  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: Integration }) => {
      return data.integration;
    },
  });

  //number tracks if it a column type(0) or a custom text(1)

  const changeType = (value: string) => {
    setType(value);
  };

  const selectMappingTypeForm: (
    props: FormikProps<FormValues>,
  ) => JSX.Element = ({ setFieldValue, dirty, isValid }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item name={"type"}>
            <Select
              name={"type"}
              size="large"
              placeholder={t("translation:select_mapping_type")}
              onChange={(value, e) => {
                changeType(value);
                setFieldValue("value", "");
                setFieldValue("key", "");
              }}
            >
              <Select.Option value={"column"} id={1}>
                {"Column"}
              </Select.Option>
              <Select.Option value={"text"} id={1}>
                {"Text"}
              </Select.Option>
              <Select.Option value={"grouping"} id={1}>
                {"Grouping"}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          {type !== "" && (
            <NewMappingTypes
              isThemed={Boolean(integration?.baseTable)}
              type={type}
              columnTypes={columnTypes}
              dirty={dirty}
              isValid={isValid}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
  const initValues: FormValues = {
    key: undefined,
    value: undefined,
    type: undefined,
    columnTypeId: undefined,
  };
  return (
    <Formik
      component={selectMappingTypeForm}
      initialValues={initValues}
      validationSchema={newMappingSchema}
      onSubmit={(values, actions) => {
        values["parentMappingId"] = parentId;
        const data = formatForm(values);
        data["integrationId"] = integration.id;

        addMapping(data).then((response) => {
          if (!response?.errors) {
            actions.resetForm();
          }
        });
      }}
    />
  );
}

function NewMappingTypes({
  isThemed,
  type,
  columnTypes,
  dirty,
  isValid,
}: {
  isThemed: boolean;
  type: string;
  columnTypes: ReportColumnType[];
  dirty: boolean;
  isValid: boolean;
}) {
  return (
    <>
      {(function () {
        switch (type) {
          case "column":
            return (
              <ColumnType
                isThemed={isThemed}
                columnTypes={columnTypes}
                dirty={dirty}
                isValid={isValid}
              />
            );
          case "text":
            return <TextType dirty={dirty} isValid={isValid} />;
          case "grouping":
            return <GroupingType dirty={dirty} />;
          default:
            return null;
        }
      })()}
    </>
  );
}

export function formatForm(values: FormValues): Mapping {
  let columnType;
  let value;
  //columns selected from a report will not be an array. Theme columns
  //will be an array(id stored in the 1 index)
  if (Array.isArray(values.columnTypeId)) {
    columnType = values.columnTypeId[1];
    value = values.columnTypeId[0];
  } else {
    value = values?.value;
    columnType = values?.columnTypeId;
  }
  const ret = {
    ...(values?.parentMappingId && {
      parentMappingId: values?.parentMappingId,
    }),
    key: values?.key,
    value: value,
    type: values?.type,
  };
  if (values.type === "column") {
    ret["columnTypeId"] = columnType;
  }
  if (values.type === "text") {
    ret.value = values.value;
  }
  return ret;
}
