import {
  genericGetSingle,
  genericCreateSingle,
  genericGetAll,
} from "../helpers/apiCall";
import { Batch } from "../models";
import { Error } from "../types";

/**
 * Get all batches
 * @param params URLSearchParams
 * @returns Promise<{ batches: Batch[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ batches: Batch[] }> {
  return genericGetAll("batches", params);
}

/**
 * Get a single batch
 * @param batchId string
 * @returns Promise<{ batch: Batch }>
 */
function getSingle(batchId: string): Promise<{ batch: Batch }> {
  return genericGetSingle("batches", batchId);
}

/**
 * Get a single batch
 * @param batch [key: string]: string | number | boolean | Date | undefined;
 * @returns Promise<{ batch: Batch }>
 */
function createSingle(batch: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ batch: Batch } | Error> {
  return genericCreateSingle("batches", batch);
}

/**
 * Gets the count of batches
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("batches", params);
}

export const batchService = {
  getAll,
  getSingle,
  createSingle,
  getCount,
};
