import React from "react";
import { AccessState } from "./types";

const default_state: AccessState = {
  canEdit: false,
  canDelete: false,
  canView: false,
};

const AccessContext = React.createContext<AccessState>(default_state);

export const AccessProvider = AccessContext.Provider;
export const AccessConsumer = AccessContext.Consumer;

export default AccessContext;
