import React from "react";
import { Col, Button, Row } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { WorkflowAction, Integration, App } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  integrationId?: number;
}

export default function CycleCounts({
  app,
  action,
  addWorkflowAction,
  integrations,
  removeWorkflowAction,
  updateAction,
  isNew,
  resetSelectedApp,
}: {
  app: App;
  action: WorkflowAction;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  integrations: Integration[];
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  updateAction: (action: WorkflowAction) => Promise<any>;
  isNew: boolean;
  resetSelectedApp: () => void;
}) {
  const { t } = useTranslation();

  const cycleCountsForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
  }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={3}>
          <span className="blockText">{t("translation:run")}</span>
        </Col>
        <Col span={15}>
          <Form.Item name="integrationId">
            <Select
              name="integrationId"
              size="large"
              placeholder={t("translation:select_integration")}
            >
              {integrations.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col flex="auto">
          <SubmitButton type="primary" size="large" block disabled={!dirty}>
            {isNew ? t("translation:add_action") : t("translation:save")}
          </SubmitButton>
        </Col>
        {!isNew && (
          <Col span={3}>
            <Button
              onClick={() => removeWorkflowAction(action)}
              size="large"
              block
            >
              {t("translation:remove")}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );

  const initialValues: FormValues = {
    integrationId: undefined,
  };
  return (
    <Row justify="start" gutter={16}>
      <Col span={24}>
        <Formik
          enableReinitialize
          component={cycleCountsForm}
          initialValues={{
            ...(isNew
              ? initialValues
              : { integrationId: action.integrationId }),
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "integrationId", type: "number", required: true },
          ])}
          onSubmit={(values, actions) => {
            if (isNew) {
              const newAction = {
                integrationId: values.integrationId,
                appId: app.id,
              } as WorkflowAction;
              addWorkflowAction(newAction).then((response) => {
                if (!response?.errors) {
                  actions.resetForm();
                  resetSelectedApp();
                }
              });
            } else {
              if (action?.id) {
                updateAction({ id: action.id, ...values })
                  .then((response) => {
                    if (!response?.errors) {
                      actions.resetForm();
                    }
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }
            }
          }}
        />
      </Col>
    </Row>
  );
}
