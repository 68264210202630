import React from "react";
import { Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

export default function Controls({
  activeNewWorkflow,
  toggleNewWorkflow,
}: {
  activeNewWorkflow: boolean;
  toggleNewWorkflow: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>{t("translation:all_workflows")}</h1>
        </Col>
        <Col span={16}>
          <div className="controlButtons">
            {activeNewWorkflow ? (
              <Button onClick={() => toggleNewWorkflow()}>
                {t("translation:cancel")}
              </Button>
            ) : (
              <Button type="primary" onClick={() => toggleNewWorkflow()}>
                {t("translation:new_workflow")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
