import { appService } from "../services";
import { Theme } from "../types";
import { buildParams } from "/app/src/helpers/params";
import { themes } from "../constants/themes";

/**
 * Function to check if the Jobs app is active
 */
export function jobAppCheck(themes: Theme[]) {
  return appService.getAll(buildParams({ name: "Jobs" })).then((response) => {
    if (response.apps.length > 0) {
      if (response.apps[0].status !== 0) {
        return themes;
      }
    }
    // remove Jobs and Job Line from themes
    return themes.filter((theme) => {
      return theme.name !== "Job" && theme.name !== "Job Line";
    });
  });
}

/**
 * Gets the base table name from the base table value, using
 * the themes constant
 * @param baseTable the base table of the report
 * @returns the name of the base table
 */
export function getTableName(baseTable: string): string {
  const theme = themes.find((theme) => theme.value === baseTable);
  return theme ? theme.name : "";
}
