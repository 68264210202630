import { storageUnitService, workOrderLineService } from "/app/src/services";
import StorageUnit from "./storageUnit";
import { useQuery } from "@tanstack/react-query";
import { StorageUnit as StorageUnitType, OrderLine } from "/app/src/models";

interface StorageUnitWithCount extends StorageUnitType {
  count: number;
}

export default function CurrentStorageUnitLoad() {
  const getUnits = () => {
    //array contain storage units objects -> {id, name, count}
    const unsorted: StorageUnitWithCount[] = [];
    //get all storage units
    return storageUnitService.getAll().then((response) => {
      //add all the storage units to the array of objects - count is 0
      response.storage_units.forEach((unit: StorageUnitType) => {
        unsorted.push({
          count: 0,
          ...unit,
        });
      });
      //one api call to get all workorderlines
      return workOrderLineService.getAll().then((resp) => {
        //for each work order line - find the storage unit id, and if
        //found, increment the count by 1
        resp.work_order_lines.forEach((line: OrderLine) => {
          const foundUnit = unsorted.find(
            ({ id }) => id === line.storageUnitId
          );
          if (foundUnit) {
            foundUnit.count += 1;
          }
        });

        //sort the array of objects by
        unsorted.sort((a, b) => {
          return b.count - a.count;
        });
        return unsorted;
      });
    });
  };

  const { data: sortedUnits } = useQuery({
    queryKey: ["sortedUnits"],
    queryFn: () => {
      return getUnits().then((response) => {
        return response;
      });
    },
    refetchInterval: 45000,
    initialData: [] as StorageUnitWithCount[],
  });

  return (
    <div className="dashboard">
      {sortedUnits.map((unit) => {
        return <StorageUnit unit={unit} key={unit.id} />;
      })}
    </div>
  );
}
