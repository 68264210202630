import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";

export default function Controls({ app, name }: { app: App; name: string }) {
  const { t } = useTranslation();
  return (
    <Row className="controls">
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 18, order: 1 }}
        lg={{ span: 20, order: 1 }}
      >
        <div className="title">
          <Link to={`/apps/${app.id}`}>{app.name}</Link>
          <span className="separator">{">"}</span>
          <h1>{name}</h1>
        </div>
      </Col>
      <Col
        xs={{ span: 18, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 6, order: 2 }}
        lg={{ span: 4, order: 2 }}
      >
        <div className="controlButtons">
          <Link to="">
            <Button type="link">{t("translation:settings")}</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
}
