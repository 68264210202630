import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function ExportButton() {
  const { t } = useTranslation();
  const { reportId } = useNumberId();
  return (
    <NavLink to={`/reports/${reportId}/export`}>
      {t("translation:export")}
    </NavLink>
  );
}
