import Action from "./action";
import { useTranslation } from "react-i18next";
import { workflowActionService, appService } from "/app/src/services";
import { App, Workflow, WorkflowAction, Report } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

export default function Actions({
  workflow,
  type,
  reports,
}: {
  workflow: Workflow;
  type: string;
  reports: Report[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: apps } = useQuery({
    queryKey: ["apps", "[nor]CSV;XLSX;PDF"],
    queryFn: () => {
      return appService.getAll(
        buildParams({ status: 2, name: "[nor]CSV;XLSX;PDF" }),
      );
    },
    initialData: { apps: [] },
    select: (data: { apps: App[] }) => {
      return data.apps;
    },
  });

  // Get API data
  const { data: workflowActions } = useQuery({
    queryKey: ["actionsByWorkflow", workflow.id, type],
    queryFn: () => {
      return workflowActionService.getAll(
        workflow.id,
        buildParams({ type, parentActionId: "none" }),
      );
    },
    initialData: { workflow_actions: [] },
    select: (data: { workflow_actions: WorkflowAction[] }) => {
      return data.workflow_actions;
    },
  });

  const { mutateAsync: addWorkflowAction } = useMutation({
    mutationFn: (
      workflowAction: Omit<WorkflowAction, "parent" | "children">,
    ) => {
      return workflowActionService
        .createSingle({
          workflowId: workflow.id,
          ...workflowAction,
        })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["actionsByWorkflow", workflow.id, type],
        (oldData: { workflow_actions: WorkflowAction[] }) => {
          return {
            workflow_actions: [
              ...oldData.workflow_actions,
              response.workflow_action,
            ],
          };
        },
      );
    },
  });

  const { mutateAsync: removeWorkflowAction } = useMutation({
    mutationFn: (
      workflowAction: Omit<WorkflowAction, "parent" | "children">,
    ) => {
      return workflowActionService.deleteSingle(workflowAction.id).then(() => {
        const workflowActionId = workflowAction.id;
        return { workflowActionId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["actionsByWorkflow", workflow.id, type],
        (oldData: { workflow_actions: WorkflowAction[] }) => {
          return {
            workflow_actions: oldData.workflow_actions.filter(
              (action) => action.id !== response.workflowActionId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateWorkflowAction } = useMutation({
    mutationFn: (
      updatedAction: Omit<WorkflowAction, "parent" | "children">,
    ) => {
      return workflowActionService
        .updateSingle(updatedAction.id, updatedAction)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["actionsByWorkflow", workflow.id, type],
        (oldData: { workflow_actions: WorkflowAction[] }) => {
          return {
            workflow_actions: oldData.workflow_actions.map((action) => {
              if (action.id === response.workflow_action.id) {
                return response.workflow_action;
              }
              return action;
            }),
          };
        },
      );
    },
  });

  return (
    <div className="actions">
      <h2 style={{ display: workflowActions.length > 0 ? "block" : "none" }}>
        {t("translation:actions")}
      </h2>
      {workflowActions.map((action) => (
        <Action
          updateAction={updateWorkflowAction}
          action={action}
          isNew={false}
          key={action.id}
          apps={apps}
          reports={reports}
          removeWorkflowAction={removeWorkflowAction}
          addWorkflowAction={addWorkflowAction}
        />
      ))}
      <Action
        apps={apps}
        updateAction={updateWorkflowAction}
        addWorkflowAction={addWorkflowAction}
        removeWorkflowAction={removeWorkflowAction}
        isNew={true}
        reports={reports}
      />
    </div>
  );
}
