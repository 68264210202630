import React, { useState, useEffect } from "react";

import { Formik, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { snapshotService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Widget, WidgetDataset, Snapshot } from "/app/src/models";

interface FormValues {
  snapshotId: number | undefined;
  widgetId?: number;
}

/**
 * Component to add a new dataset to a widget
 * @param param0 props containing widget, activeForm, toggleForm, createDataset, getData
 */
export default function NewDataset({
  widget,
  activeForm,
  toggleForm,
  createDataset,
  getData,
}: {
  widget: Widget;
  activeForm: boolean;
  toggleForm: () => void;
  createDataset: (dataset: WidgetDataset) => Promise<any>;
  getData: (dataset: WidgetDataset, widget: Widget) => void;
}) {
  const { t } = useTranslation();
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  // Get Snapshots
  useEffect(() => {
    snapshotService.getAll().then((response) => {
      setSnapshots(response.snapshots);
    });
  }, []);

  /**
   * Map snapshots to options for the select
   */
  const mapSnapshots = (c: Snapshot) => {
    return (
      <Select.Option key={c.id} value={c.id}>
        {c.name} <span style={{ fontStyle: "italic" }}>({c.reportName})</span>
      </Select.Option>
    );
  };

  /**
   * Form for adding a new dataset
   * @param param0 Formik props
   */
  const newDataSetForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isSubmitting,
  }) => (
    <Form>
      <div className="newDatasetForm">
        <div className="snapshotSelect">
          <Form.Item name="snapshotId">
            <SearchFilter
              list={snapshots}
              mapOptionsFn={mapSnapshots}
              name="snapshotId"
              placeholder={t("translation:select_snapshot")}
            />
          </Form.Item>
        </div>
        <div className="newDatasetSubmit">
          <SubmitButton
            type="primary"
            size="large"
            disabled={!dirty || isSubmitting}
          >
            {t("translation:add")}
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
  if (activeForm) {
    return (
      <div className="newDataset">
        <button onClick={() => toggleForm()}>
          {t("translation:add_dataset")}
        </button>
      </div>
    );
  } else {
    return (
      <div className="newDataset">
        {activeForm}
        <Formik
          component={newDataSetForm}
          initialValues={{
            snapshotId: undefined,
          }}
          onSubmit={(formValues, actions) => {
            const values = { ...formValues, widgetId: widget.id };
            createDataset(values).then((response) => {
              if (!response?.errors) {
                getData(response.widget_dataset, widget);
                actions.setSubmitting(false);
                actions.resetForm();
              }
            });
          }}
        />
      </div>
    );
  }
}
