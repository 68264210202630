import React from "react";
import { Switch } from "antd";

/**
 * Component to render a switch with the ability to be toggled to the middle
 * @param disabled: boolean to determine if the switch should be disabled
 * @param isChecked: boolean to determine if the switch should be checked
 * @param isMiddle: boolean to determine if the switch should be toggled to the middle
 * @param handleSwitchChange: function to handle the switch change
 * @returns
 */
export default function PermissionSwitch({
  disabled = false,
  isChecked,
  isMiddle = false,
  handleSwitchChange,
}: {
  disabled?: boolean;
  isChecked: boolean;
  isMiddle?: boolean;
  handleSwitchChange: (checked: boolean) => void;
}) {
  return (
    <Switch
      disabled={disabled}
      checked={isChecked}
      onClick={handleSwitchChange}
      className={isMiddle ? "middle-switch" : ""}
    />
  );
}
