import { object, string, number } from "yup";
import i18next from "/app/src/locales/config";

export const newUserSchema = object().shape({
  username: string().required(i18next.t("translation:username_is_required")),

  email: string()
    .email(i18next.t("translation:valid_email_warning"))
    .required(i18next.t("translation:email_is_required")),

  password: string()
    .required(i18next.t("translation:password_is_required"))
    .min(4, i18next.t("translation:short_password_warning")),

  role: number().required(i18next.t("translation:select_role")),
});
