import { PermissionCode } from "/app/src/types";
import LockedAccess from "./lockedAccess";
import { useContextForPermission } from "/app/src/hooks/usePermission";
/**
 * A wrapper component that will only render its children if the user has the correct permissions
 * @param children the children to render if the user has the correct permissions
 * @param permissionModel the model to check permissions for
 */
export default function PermissionWrapper({
  children,
  permissionModel,
}: {
  children: JSX.Element;
  permissionModel: PermissionCode;
}) {
  const { canView } = useContextForPermission(permissionModel);

  if (!canView) {
    return <LockedAccess />;
  } else {
    return children;
  }
}
