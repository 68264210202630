import React, { useState } from "react";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import {
  newConnectionSchema,
  newConnectionSchemaNoAuth,
} from "/app/src/schemas";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection as ConnectionType } from "/app/src/models";

interface FormValues {
  name: string | undefined;
  type: string | undefined;
  url: string | undefined;
  auth: string | undefined;
  username: string | undefined;
  password: string | undefined;
}

export default function NewConnection({
  toggleNew,
  addConnection,
  connectionTypes,
}: {
  toggleNew: () => void;
  addConnection: (connection: ConnectionType) => Promise<any>;
  connectionTypes: { name: string }[];
}) {
  const { t } = useTranslation();
  const [type, setType] = useState<string>();
  const [authType, setAuthType] = useState<string>();
  const handleTypeChange = (value: string) => {
    setType(value);
  };
  const handleAuthChange = (value: string) => {
    setAuthType(value);
  };

  const newConnectionForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row>
        <Col span={22}>
          <h3>
            {t("translation:create")} {t("translation:new_connection")}
          </h3>
        </Col>
        <Col span={2}>
          <CloseOutlined
            onClick={toggleNew}
            style={{ float: "right", color: "red" }}
          />
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("translation:name")}>
            <Input suffix name="name" className="user" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="type" label={t("translation:type")}>
            <Select
              name="type"
              className="user"
              size="large"
              onChange={handleTypeChange}
            >
              {connectionTypes.map((type) => {
                return (
                  <Select.Option value={type.name} key={type.name}>
                    {type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="url" label={t("translation:address")}>
            <Input suffix name="url" className="url" size="large" />
          </Form.Item>
        </Col>
        {type === "API" ? (
          <Col span={12}>
            <Form.Item name="auth" label={t("translation:authentication_type")}>
              <Select
                name="auth"
                size="large"
                className="user"
                onChange={handleAuthChange}
              >
                <Select.Option key={1} value={"Basic"}>
                  {"Basic"}
                </Select.Option>
                <Select.Option key={2} value={"No Auth"}>
                  {"No Auth"}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <Col span={12} />
        )}
        {authType !== "No Auth" && (
          <Col span={12}>
            <Form.Item name="username" label={t("translation:username")}>
              <Input suffix name="username" className="user" size="large" />
            </Form.Item>
          </Col>
        )}
        {authType !== "No Auth" && (
          <Col span={12}>
            <Form.Item name="password" label={t("translation:password")}>
              <Input.Password name="password" className="user" size="large" />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || !isValid}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initialFormValues: FormValues = {
    username: "",
    password: "",
    url: "",
    name: "",
    type: "",
    auth: "",
  };

  return (
    <div className="box">
      <Formik
        component={newConnectionForm}
        validationSchema={
          authType !== "No Auth"
            ? newConnectionSchema
            : newConnectionSchemaNoAuth
        }
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          addConnection(formatForm(values))
            .then(() => {
              toggleNew();
            })
            .catch((errors) => {
              handleSubmissionErrors(errors, actions.setFieldError);
            });
        }}
      />
    </div>
  );
}

function formatForm(values: FormValues) {
  const formatted = {
    username: values.username,
    password: values.password,
    url: values.url,
    name: values.name,
    type: values.type,
  };
  if (values.type === "API") {
    formatted["type"] = values.auth;
  }
  return formatted;
}
