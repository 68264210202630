import React, { useState, useEffect } from "react";
import { Row } from "antd";
import {
  mappingService,
  reportColumnTypeService,
  reportColumnService,
} from "/app/src/services";
import EditMapping from "./editMapping";
import NewMapping from "./newMapping";
import { useMountedState } from "/app/src/hooks";
import { Integration, Mapping, ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

export default function Mappings({
  integration,
}: {
  integration: Integration;
}) {
  const [columnTypes, setColumnTypes] = useState<ReportColumnType[]>([]);
  const isMounted = useMountedState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (integration?.baseTable) {
      reportColumnTypeService
        .getAll(buildParams({ baseTable: integration.baseTable }))
        .then((response) => {
          isMounted() && setColumnTypes(response.report_column_types);
        });
    } else {
      if (integration?.reportId) {
        reportColumnService
          .getAllFromReport(integration.reportId)
          .then((response) => {
            isMounted() && setColumnTypes(response.report_columns);
          });
      }
    }
  }, [integration, isMounted]);

  //Get mappings
  const { data: mappings } = useQuery({
    queryKey: ["mappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "key",
          parentMappingId: "none",
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const { mutateAsync: addMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService.createSingle(mapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  return (
    <div className="box">
      <h1>{integration.number} Mappings</h1>
      {mappings?.map((mapping) => (
        <div className="mapping" key={mapping.id}>
          <Row justify="start" gutter={16}>
            <EditMapping
              isThemed={integration.baseTable !== null}
              mapping={mapping}
              columnTypes={columnTypes}
              updateMapping={updateMapping}
              removeMapping={removeMapping}
            />
          </Row>
        </div>
      ))}
      <div className="newMapping">
        <NewMapping
          integrationId={integration.id}
          addMapping={addMapping}
          columnTypes={columnTypes}
        />
      </div>
    </div>
  );
}
