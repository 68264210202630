import { useState } from "react";
import { Row, Col } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { ReportColumn as ReportColumnT, ReportFilter } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportColumnService } from "/app/src/services";

interface FormValues {
  name: string | undefined;
}

export const ReportColumn = ({
  column,
  canEdit,
  canDelete,
  updateColumn,
}: {
  column: ReportColumnT;
  canEdit: boolean;
  canDelete: boolean;
  updateColumn: (updatedCol: ReportColumnT) => Promise<any>;
}) => {
  const [editingName, setEditingName] = useState(false);

  const handleEditClick = (): void => {
    setEditingName(true);
  };

  const queryClient = useQueryClient();

  const { mutateAsync: deleteColumn } = useMutation({
    mutationFn: (columnId: number) => {
      return reportColumnService.deleteSingle(columnId);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["reportColumns", column.reportId],
        (oldData: { report_columns: ReportColumnT[] }) => {
          return {
            report_columns: oldData.report_columns.filter(
              (col) => col.id !== column.id,
            ),
          };
        },
      );
      // Remove any filters that use this column
      queryClient.setQueryData(
        ["reportFilters", column.reportId],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: oldData.report_filters.filter(
              (filter) => filter.reportColumnId !== column.id,
            ),
          };
        },
      );
    },
  });

  const columnForm: (props: FormikProps<FormValues>) => JSX.Element = () => (
    <Form>
      <Row>
        <Col span={20}>
          <Form.Item name="name" hasFeedback={false}>
            <Input type="text" name="name" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton className="saveButton" type="primary" block>
            <img src={saveIcon} alt="Save" />
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <Row gutter={20} className="columnContainer">
      <Col span={8} className="name">
        {!editingName ? (
          <div style={{ paddingTop: "8px" }}>
            {column.table} | {column.originalName}
          </div>
        ) : (
          <div style={{ paddingTop: "15px" }} />
        )}{" "}
        {editingName ? (
          <Formik
            component={columnForm}
            enableReinitialize
            initialValues={{
              name: column.name,
            }}
            validationSchema={simpleSchemaBuilder([
              { name: "name", type: "string", required: true },
            ])}
            onSubmit={(values, actions) => {
              updateColumn({ id: column.id, name: values.name }).then(() => {
                setEditingName(false);
                actions.setSubmitting(false);
              });
            }}
          />
        ) : (
          <div style={{ display: "flex" }}>
            <h3>{column.name}</h3>
            <div style={{ marginLeft: "8px" }}>
              <EditButton onClick={handleEditClick} disabled={!canEdit} />
            </div>
          </div>
        )}
      </Col>
      <Col span={1} offset={15} className="actions">
        <div className="titleActions">
          <DeleteButton
            onClick={() => deleteColumn(column.id)}
            disabled={!canDelete}
          />
        </div>
      </Col>
    </Row>
  );
};
