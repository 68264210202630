import { object, string, number } from "yup";
import i18next from "/app/src/locales/config";

export const attachmentSchema = object().shape({
  app: string().required(i18next.t("translation:required")),
  reportId: number().required(i18next.t("translation:required")),
  integrationId: number().when("app", {
    is: (app) => /CSV/.test(app),
    then: (schema) =>
      schema.required(i18next.t("translation:required")).nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
});
