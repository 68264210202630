import React, { useState } from "react";
import Shelf from "./shelf";
import { Carrier as CarrierType } from "/app/src/models";
export default function Carrier({ carrier }: { carrier: CarrierType }) {
  const [showShelf] = useState(true);

  return (
    <div className="carrier">
      <h3>{carrier.name}</h3>
      {showShelf && <Shelf carrier={carrier} />}
    </div>
  );
}
