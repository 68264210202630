import React from "react";
import { ConnectionState } from "./types";

const ConnectionsStatusContext = React.createContext<ConnectionState>({
  connectionState: { "PowerPick SQL": 1, "PowerPick Web Services": 1 },
});

export const ConnectionsStatusProvider = ConnectionsStatusContext.Provider;
export const ConnectionsStatusConsumer = ConnectionsStatusContext.Consumer;

export default ConnectionsStatusContext;
