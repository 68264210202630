import deleteIcon from "/app/src/components/generic/title/deleteIcon.svg";

const DeleteButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button className="deleteButton" onClick={onClick} disabled={disabled}>
      <img src={deleteIcon} alt="Delete" />
    </button>
  );
};

export default DeleteButton;
