import React, { Select } from "formik-antd";

/**
 * Function used to map the options of the select component
 * @param option name and id of the option
 * @returns JSX.Element
 */
export function mapWarehouses(option: { name?: string; id?: string }) {
  return (
    <Select.Option value={option.id} key={option.id}>
      {option.name}
    </Select.Option>
  );
}
