import { object, string } from "yup";
import isIpAddress from "/app/src/helpers/validators";
import i18next from "/app/src/locales/config";

export const connectionSchema = object().shape({
  username: string().required(i18next.t("translation:username_is_required")),
  password: string().required(i18next.t("translation:password_is_required")),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test("test-name", "Not a valid address", (value) => {
      return isIpAddress(value);
    }),
});

export const connectionSchemaNoPwd = object().shape({
  name: string().required(i18next.t("translation:connection_is_required")),
  username: string().required(i18next.t("translation:username_is_required")),
  password: string(),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test("test-name", "Not a valid address", (value) => {
      return isIpAddress(value);
    }),
});

export const connectionSchemaNoAuth = object().shape({
  name: string().required(i18next.t("translation:connection_is_required")),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test("test-name", "Not a valid address", (value) => {
      return isIpAddress(value);
    }),
});
