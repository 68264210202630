import { Integration } from "/app/src/models";
import CurrentStorageUnitLoad from "./currentStorageUnitLoad";
import DivertTool from "./divertTool";

export default function DisplayRouter({ display }: { display: Integration }) {
  switch (display.number) {
    case 1:
      return <CurrentStorageUnitLoad />;
    case 2:
      return <DivertTool />;
    default:
      return null;
  }
}
