import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("workflow_criteria", params);
}

function getByWorkflow(
  workflowId: number | string,
  params: URLSearchParams = null
) {
  return makeAPIRequest({
    url: `/workflows/${workflowId}/workflow_criteria`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getSingle(id: number | string) {
  return genericGetSingle("workflow_criteria", id);
}

function createSingle(workflowCriteria: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("workflow_criteria", workflowCriteria);
}

function updateSingle(
  id: number | string,
  workflowCriteria: {
    [key: string]: string | number | boolean | Date | undefined;
  }
) {
  return genericUpdateSingle("workflow_criteria", id, workflowCriteria);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("workflow_criteria", id);
}

/**
 * Gets the count of workflow criteria
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return getAll(params);
}

/**
 * Gets the count of workflow criteria for a workflow
 * @param workflowId Workflow ID
 * @param params URLSearchParams
 */
function getCountByWorkflow(
  workflowId: number | string,
  params: URLSearchParams = null
) {
  params.append("countOnly", "true");
  return getByWorkflow(workflowId, params);
}

export const workflowCriteriaService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getByWorkflow,
  getCount,
  getCountByWorkflow,
};
