import React from "react";
import { Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

/**
 * Component to show the connection lost popup
 * @param param0 props containing status of API connection
 */
export default function ConnectionTest({ status }: { status: boolean }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //isFetching determines the Spin and button disabled state
  const [isFetching, setIsFetching] = React.useState(false);
  //The handler for the button to retry connection
  const handleOk = () => {
    setIsFetching(true);
    queryClient
      .fetchQuery({
        queryKey: ["status"],
        retry: false,
      })
      .then(() => {
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  return (
    <div>
      <Modal
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Reconnect"
        title={t("translation:connection_failed")}
        open={status}
        onOk={handleOk}
        okButtonProps={{ disabled: isFetching }}
      >
        {isFetching ? <Spin /> : t("translation:connection_failed_message")}
      </Modal>
    </div>
  );
}
