import { Col, Button } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { WorkflowAction, Report } from "/app/src/models";

export default function File({
  reports,
  isNew,
  removeWorkflowAction,
  dirty,

  action,
}: {
  reports: Report[];
  isNew: boolean;
  removeWorkflowAction?: (action: WorkflowAction) => void;
  dirty: boolean;
  action?: WorkflowAction;
}) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (removeWorkflowAction && action) {
      removeWorkflowAction(action);
    }
  };

  return (
    <>
      <Col span={7}>
        <Form.Item name="reportId">
          <Select
            name="reportId"
            size="large"
            placeholder={t("translation:select_report")}
          >
            {reports.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={5}>
        {/* Placeholder Col, this keeps ui consistent when selecting different file attachments */}
      </Col>
      <Col span={5}>
        <SubmitButton type="primary" size="large" block disabled={!dirty}>
          {isNew ? t("translation:add_action") : t("translation:save")}
        </SubmitButton>
      </Col>
      {!isNew && (
        <Col span={3}>
          <Button onClick={handleClick} size="large" block>
            {t("translation:remove")}
          </Button>
        </Col>
      )}
    </>
  );
}
