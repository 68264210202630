import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { ReportColumn } from "../models";
import { Error } from "../types";

/**
 * Get all report columns
 * @param params URLSearchParams
 * @returns Promise<{ report_columns: ReportColumn[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ report_columns: ReportColumn[] }> {
  return genericGetAll("report_columns", params);
}

/**
 * Get all report columns from a report
 * @param reportId number
 * @param params { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_columns: ReportColumn[] }>
 */
function getAllFromReport(
  reportId: number,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
) {
  return makeAPIRequest({
    url: `/reports/${reportId}/report_columns`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// For getting report details
/**
 * Get a single report column
 * @param id number | string
 * @returns Promise<{ report_column: ReportColumn }>
 */
function getSingle(
  id: number | string,
): Promise<{ report_column: ReportColumn }> {
  return genericGetSingle("report_columns", id);
}

/**
 * Create a single report column
 * @param reportColumn { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_column: ReportColumn } | Error>
 */
function createSingle(reportColumn: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ report_column: ReportColumn } | Error> {
  return genericCreateSingle("report_columns", reportColumn);
}

/**
 * Delete a single report column
 * @param reportColumnId number
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  reportColumnId: number,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("report_columns", reportColumnId);
}

/**
 * Update a single report column
 * @param id number | string
 * @param reportColumn { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_column: ReportColumn } | Error>
 */
function updateSingle(
  id: number | string,
  reportColumn: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ report_column: ReportColumn } | Error> {
  return genericUpdateSingle("report_columns", id, reportColumn);
}

/**
 * Gets the count of report_columns
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("report_columns", params);
}

export const reportColumnService = {
  getAll,
  getAllFromReport,
  getSingle,
  createSingle,
  deleteSingle,
  updateSingle,
  getCount,
};
