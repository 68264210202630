import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { MaterialProperty } from "../models";

/**
 * Get all material properties
 * @param params URLSearchParams
 * @returns Promise<{ material_properties: MaterialProperty[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ material_properties: MaterialProperty[] }> {
  return genericGetAll("material_properties", params);
}

/**
 * Get a single material property
 * @param id string
 * @returns Promise<{ material_property: MaterialProperty }>
 */
function getSingle(
  id: string,
): Promise<{ material_property: MaterialProperty }> {
  return genericGetSingle("material_properties", id);
}

/**
 * Gets the count of material properties
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("material_properties", params);
}

export const materialPropertyService = {
  getAll,
  getSingle,
  getCount,
};
