import React from "react";
import { Link } from "react-router-dom";

import { Col } from "antd";

export default function singleApp({ app }) {
  const appSelector = `${app.name.replace(" ", "_").toLowerCase()}_app`;

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
    >
      <div className="appBox" id={appSelector}>
        <div className="icon" />
        <div className="info">
          <h3>
            <Link to={`/apps/${app.id}`}>{app.name}</Link>
          </h3>
          {app.description}
        </div>
      </div>
    </Col>
  );
}
