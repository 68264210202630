import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Switch } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

import { integrationService, connectionService } from "/app/src/services";
import Headers from "./headers";
import Filters from "./filters";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ConnectionSettings from "./connectionSettings";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface IntegrationFormValues {
  name: string | undefined;
  type: string | number | undefined;
  connectionId: number | undefined;
}
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  function formatForm(values: IntegrationFormValues): Integration {
    return {
      name: values.name,
      connectionId: values.connectionId,
      number: values.type,
    };
  }

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  const updateIntegrationHandler = async (
    values: IntegrationFormValues,
    actions: FormikHelpers<IntegrationFormValues>,
  ) => {
    if (integration?.id) {
      await updateIntegration({
        id: integration.id,
        ...formatForm(values),
      }).then(() => {
        actions.resetForm();
      });
    }
  };

  const toggleIntegrationStatus = useCallback(
    (value: boolean) => {
      //value is a bool - convert to number
      if (integration?.id) {
        updateIntegration({ id: integration.id, status: Number(value) });
      }
    },
    [integration, updateIntegration],
  );

  const types = ["JSON"];

  const { data: connections } = useQuery({
    queryKey: ["connections", "[or]Basic;No Auth"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({ type: "[or]Basic;No Auth" }),
      );
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });

  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = ({ dirty, isSubmitting }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={t("translation:integration_name")}
            hasFeedback={false}
          >
            <Input
              name="name"
              placeholder={t("translation:enter_name")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="type" label={t("translation:format")}>
            <Select
              name="type"
              size="large"
              placeholder={t("translation:select_format")}
            >
              {types.map((t) => (
                <Select.Option value={t} key={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="connectionId" label={t("translation:connection")}>
            <Select
              name="connectionId"
              size="large"
              placeholder={t("translation:select_connection")}
            >
              {connections.map((c) => (
                <Select.Option value={c.id} key={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:update")} {t("translation:integration")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <div className="box">
        <Row>
          <Col span={23}>
            <h1>{t("translation:general")}</h1>
          </Col>
          <Col span={1}>
            <h4>{t("translation:active")}:</h4>
            <Switch
              className="toggleSwitch"
              onChange={(e) => {
                toggleIntegrationStatus(e);
              }}
              checked={integration?.status === 1}
            />
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
            type: integration.number,
            connectionId: integration.connectionId,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </div>
      <ConnectionSettings integration={integration} />
      <Headers integration={integration} />
      <Filters integration={integration} />
    </>
  );
}
