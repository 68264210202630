import React from "react";
import { NavLink } from "react-router-dom";
import { Role as RoleType } from "/app/src/models";
export default function Role({ role }: { role: RoleType }) {
  return (
    <li className="role">
      <NavLink to={`/users/roles/${role.id}`}>{role.name}</NavLink>
    </li>
  );
}
