import React from "react";
import { useTranslation } from "react-i18next";
import Mssql from "./kardex/mssql";
import WebServices from "./kardex/webServices";

export default function Kardex() {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t("translation:kardex_settings")}</h1>
      <Mssql />
      <WebServices />
    </div>
  );
}
