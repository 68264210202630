import React from "react";
import { Routes, Route } from "react-router-dom";

import Hue from "./app";
import { App } from "/app/src/models";
export default function HueApp({ app }: { app: App }) {
  return (
    <Routes>
      <Route path="/" element={<Hue app={app} />} />
    </Routes>
  );
}
