import React, { useContext } from "react";
import { Navigate, Routes as AppRoutes, Route } from "react-router-dom";
import NotFound from "./components/generic/components/notFound";
import { useTranslation } from "react-i18next";
import Dashboard from "./components/dashboard";
import Reports from "./components/reports";
import Logs from "./components/logs";
import Status from "./components/apps/status";
import Apps from "./components/apps";
import Workflows from "./components/workflows";
import Settings from "./components/settings";
import Users from "./components/users";
import NewSignup from "./components/users/newSignup";
import Login from "./components/users/login";
import SingleSignOn from "./components/users/singleSignOn";
import Displays from "./components/apps/displays";
import DisabledCheck from "./components/generic/components/disabled";
import PrivateRoute from "./components/links/privateRoute";
import ConnectionsStatusContext from "/app/src/contexts/ConnectionsStatusContext";
import License from "./components/users/license";
import { useAuthState } from "./contexts/authentication";
import PermissionWrapper from "./components/generic/components/permissions";
import Explorer from "./components/explorer";

/**
 * Register all the routes for the application
 */
export default function Routes() {
  const { t } = useTranslation();
  const { connectionState } = useContext(ConnectionsStatusContext);
  const { authType: validationType } = useAuthState();

  return (
    <AppRoutes>
      <Route
        element={validationType === "azure" ? <SingleSignOn /> : <Login />}
        path="login"
      />
      <Route
        element={
          <PrivateRoute>
            <DisabledCheck
              name="Access"
              message={t("translation:enter_valid_license_warning")}
            />
          </PrivateRoute>
        }
        path="locked"
      />
      <Route element={<NewSignup />} path="signup" />
      <Route element={<License />} path="license" />
      <Route
        element={
          <PrivateRoute>
            <Navigate to={{ pathname: "/dashboard" }} />
          </PrivateRoute>
        }
        path={"/"}
      />
      <Route
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
        path={"dashboard"}
      />
      <Route
        element={
          <PrivateRoute>
            <DisabledCheck
              name="Explorer"
              message={`${t(
                "translation:powerpick_database_connection_failed",
              )} ${t("translation:explorer_disabled")}`}
              connections={[connectionState["PowerPick SQL"]]}
            >
              <PrivateRoute>
                <PermissionWrapper permissionModel="EXPL">
                  <Explorer />
                </PermissionWrapper>
              </PrivateRoute>
            </DisabledCheck>
          </PrivateRoute>
        }
        path="explorer/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
        path="reports/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Apps />
          </PrivateRoute>
        }
        path="apps/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Workflows />
          </PrivateRoute>
        }
        path="workflows/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
        path="settings/*"
      />
      <Route element={<Users />} path="users/*" />
      <Route
        element={
          <PrivateRoute>
            <Displays app={{}} />
          </PrivateRoute>
        }
        path="displays/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Logs />
          </PrivateRoute>
        }
        path="logs/*"
      />
      <Route
        element={
          <PrivateRoute>
            <Status />
          </PrivateRoute>
        }
        path="status/*"
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>
        }
      />
    </AppRoutes>
  );
}
