import React, { useState, useEffect } from "react";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import {
  updateObjectInStateArray,
  deleteObjectInStateArray,
  addObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import { reportColumnTypeService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

export default function CustomColumnTypes() {
  const [columnTypes, setColumnTypes] = useState<ReportColumnType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    reportColumnTypeService
      .getAll(buildParams({ custom: "[or]custom;variance" }))
      .then((response) => setColumnTypes(response.report_column_types))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const addReportColumnType = (columnType: ReportColumnType) => {
    return addObjectInStateArray(
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.createSingle,
    );
  };

  const removeColumnType = (columnType: ReportColumnType) => {
    return deleteObjectInStateArray(
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.deleteSingle,
    );
  };

  const updateColumnType = (
    columnTypeId: number,
    columnType: ReportColumnType,
  ) => {
    return updateObjectInStateArray(
      columnTypeId,
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.updateSingle,
    );
  };
  return (
    <div className="box">
      <h1>{t("translation:report_column_types")}</h1>
      <Row>
        <Col span={3}>
          <h3 className="">{t("translation:name")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:theme")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:table")}</h3>
        </Col>
        <Col span={4}>
          <h3>{t("translation:column")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:custom")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:integration")}</h3>
        </Col>
      </Row>
      {columnTypes.map((columnType) => (
        <ColumnType
          columnType={columnType}
          key={columnType.id}
          removeColumnType={removeColumnType}
          updateColumnType={updateColumnType}
        />
      ))}
      <NewColumnType addReportColumnType={addReportColumnType} />
    </div>
  );
}

interface FormValues {
  name: string | undefined;
  baseTable: string | undefined;
  table: string | undefined;
  custom: string | undefined;
  tableColumn: string | undefined;
  integrationId: number | undefined;
}

export function ColumnType({
  columnType,
  removeColumnType,
  updateColumnType,
}: {
  columnType: ReportColumnType;
  removeColumnType: (columnType: ReportColumnType) => Promise<any> | undefined;
  updateColumnType: (
    columnTypeId: number,
    columnType: ReportColumnType,
  ) => Promise<any>;
}) {
  const { t } = useTranslation();

  const columnTypeForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={3}>
          <Form.Item name="name">
            <Input size="large" name="name" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="baseTable">
            <Input size="large" name="baseTable" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="table">
            <Input size="large" name="table" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="tableColumn">
            <Input size="large" name="tableColumn" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="custom">
            <Input size="large" name="custom" />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="integrationId">
            <Input size="large" name="integrationId" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton disabled={!dirty} type="primary" size="large" block>
            {t("translation:save")}
          </SubmitButton>
        </Col>
        <Col span={3}>
          <Button
            onClick={() => removeColumnType(columnType)}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="columnTypes">
      <Formik
        component={columnTypeForm}
        enableReinitialize
        initialValues={{
          name: columnType.name,
          table: columnType.table,
          tableColumn: columnType.tableColumn,
          baseTable: columnType.baseTable,
          custom: columnType.custom,
          integrationId: columnType.integrationId,
        }}
        onSubmit={(values, actions) => {
          if (columnType?.id) {
            updateColumnType(columnType.id, values).then((response) => {
              actions.setSubmitting(false);
              if (!response?.errors) {
                actions.resetForm();
              }
            });
          }
        }}
      />
    </div>
  );
}

export function NewColumnType({
  addReportColumnType,
}: {
  addReportColumnType: (columnType: ReportColumnType) => Promise<any>;
}) {
  const { t } = useTranslation();
  const initialFormValues: FormValues = {
    custom: "",
    name: "",
    baseTable: "",
    table: "",
    tableColumn: "",
    integrationId: undefined,
  };
  const newMappingForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={4}>
          <Form.Item name="name">
            <Input
              size="large"
              name="name"
              placeholder={t("translation:name")}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="baseTable">
            <Input
              size="large"
              name="baseTable"
              placeholder={t("translation:theme")}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="table">
            <Input
              size="large"
              name="table"
              placeholder={t("translation:table")}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="tableColumn">
            <Input
              size="large"
              name="tableColumn"
              placeholder={t("translation:column")}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="custom">
            <Input
              size="large"
              name="custom"
              placeholder={t("translation:custom")}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="integrationId">
            <Input
              size="large"
              name="integrationId"
              placeholder={t("translation:integration")}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton disabled={!dirty} type="primary" size="large" block>
            {t("translation:new_column_type")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="newMapping">
      <Formik
        component={newMappingForm}
        initialValues={initialFormValues}
        onSubmit={(values, actions) => {
          addReportColumnType(values).then((response) => {
            if (!response?.errors) {
              actions.resetForm();
            }
          });
        }}
      />
    </div>
  );
}
