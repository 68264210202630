import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Location } from "../models";

/**
 * Get all locations
 * @param params URLSearchParams
 * @returns Promise<{ locations: Location[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ locations: Location[] }> {
  return genericGetAll("locations", params);
}

/**
 * Get a single location
 * @param id string
 * @returns Promise<{ location: Location }>
 */
function getSingle(id: string): Promise<{ location: Location }> {
  return genericGetSingle("locations", id);
}

/**
 * Gets the count of locations
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("locations", params);
}

export const locationService = {
  getAll,
  getSingle,
  getCount,
};
