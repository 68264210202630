import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useTranslation } from "react-i18next";

/**
 * Component to display the status of the license in the header
 */
export default function StatusMessage() {
  const { t } = useTranslation();

  //the initial state of license is "loading",
  //use this to show the initial "Connecting to ItemPath API"
  const { message, licenseExpiry } = useStatusContext();
  switch (message) {
    case "invalid":
      return (
        <div className="statusMessage warning">
          {t("translation:license_invalid")}
        </div>
      );
    case "warning":
      return (
        <div className="statusMessage caution">
          {t("translation:license_expiring_soon")}
        </div>
      );
    case "locked":
      return (
        <div className="statusMessage warning">
          {t("translation:license_expired_access_locked")}
        </div>
      );
    case "expired":
      return (
        <div className="statusMessage caution">
          {t("translation:license_expired_access_will_be_denied")}{" "}
          {licenseExpiry}
        </div>
      );
    case "warehouseLimit":
      return (
        <div className="statusMessage warning">
          {t("translation:exceeded_license_warehouse_limit")}
        </div>
      );
    case "loading":
      return (
        <div className="statusMessage connecting">
          {t("translation:connecting_to_itempath_api")}
        </div>
      );
    default:
      return null;
  }
}
