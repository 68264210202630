import React, { useState, useEffect } from "react";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikHelpers } from "formik";
import { Row, Col, Button } from "antd";
import { webServicesSchema, webServicesSchemaNoPwd } from "/app/src/schemas";
import { connectionService } from "/app/src/services";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { testConnection } from "/app/src/helpers/connectionsStatus";
import Setting from "/app/src/components/settings/setting";
import { Connection as ConnectionType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { handlePromiseError } from "/app/src/helpers/api";

type FormValues = {
  url: string | undefined;
  username: string | undefined;
  password?: string;
};

/**
 * Component to display the PowerPick Web Services connection settings
 */
export default function WebServices() {
  const { t } = useTranslation();

  const [connection, setConnection] = useState<ConnectionType>({});
  const [isTesting, setIsTesting] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    url: "",
    username: "",
    password: "",
  });

  function formatForm(values: FormValues) {
    const formatted: {
      username: string | undefined;
      url: string | undefined;
      password?: string;
    } = {
      username: values.username,
      url: values.url,
    };
    if (values.password) {
      formatted.password = values.password;
    }
    return formatted;
  }

  useEffect(() => {
    setInitialFormValues({
      url: connection.url,
      username: connection.username,
      password: "",
    });
  }, [connection]);

  useEffect(() => {
    connectionService
      .getAll(buildParams({ type: "PowerPick Web Services" }))
      .then((response) => {
        setConnection(response.connections[0]);
      });
  }, []);

  /**
   * Function to update the connection
   * @param connection connection to update
   */
  const updateConnection = (connection: ConnectionType) => {
    return connectionService
      .updateSingle(connection.id, connection)
      .then((response) => {
        if ("connection" in response) {
          setConnection(response.connection);
        }
      });
  };

  function handleSubmit(
    values: FormValues,
    actions: FormikHelpers<{
      username: string | undefined;
      url: string | undefined;
      password?: string;
    }>,
  ) {
    if (connection?.id) {
      connectionService
        .updateSingle(connection.id, values)
        .then(handlePromiseError)
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  }
  return (
    <div className="box">
      <Formik
        validationSchema={
          connection.passwordExists ? webServicesSchemaNoPwd : webServicesSchema
        }
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSubmit(formatForm(values), actions);
        }}
      >
        {({ dirty, isValid }) => (
          <Form layout="vertical">
            <Row justify="start" gutter={16} style={{ paddingBottom: "10px" }}>
              <Col span={20}>
                <h2>{t("translation:web_services_connection")}</h2>
              </Col>
              <Col
                span={1}
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {connection.status === 0 ? (
                  <WarningTwoTone
                    twoToneColor="red"
                    style={{ fontSize: "32px" }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "32px" }}
                  />
                )}
              </Col>
              <Col span={3}>
                <div style={{ float: "right" }}>
                  <Button
                    disabled={isTesting}
                    onClick={(e) => {
                      e.stopPropagation();
                      testConnection(
                        setIsTesting,
                        connection,
                        updateConnection,
                      );
                    }}
                  >
                    {isTesting
                      ? t("translation:testing___")
                      : t("translation:test_") + t("translation:connection")}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row justify="start" gutter={16}>
              <Col span={8}>
                <h3>{t("translation:web_services_url")}</h3>
                <div>
                  <span>{t("translation:web_services_url_description")}</span>
                  <ToolTip tip={t("translation:web_services_url_tooltip")} />
                </div>
              </Col>
              <Col span={14} offset={2}>
                <Form.Item name="url" className="no-label">
                  <Input suffix name="url" className="token" size="large" />
                </Form.Item>
                <Setting
                  settingNumber={201}
                  settingType="checkbox"
                  defaultValue="1"
                  appId={null}
                />
              </Col>
            </Row>
            <Row justify="start" gutter={16}>
              <Col span={8}>
                <h3>{t("translation:power_pick_user_password")}</h3>
                <p>{t("translation:ppg_user_access_rights")}</p>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="username" className="no-label">
                  <Input
                    suffix
                    placeholder={t("translation:power_pick_user")}
                    name="username"
                    className="user"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="password" className="no-label">
                  <Input.Password
                    className="password"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => {
                      if (connection.passwordExists) {
                        e.target.placeholder = "***********";
                      }
                    }}
                    placeholder={
                      connection.passwordExists
                        ? "***********"
                        : t("translation:power_pick_password")
                    }
                    name="password"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start" gutter={16}>
              <Col span={4} offset={20}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  disabled={!dirty || !isValid}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
