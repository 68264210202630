import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TwitterPicker, ColorResult } from "react-color";
import { snapshotService } from "/app/src/services";
import { Snapshot, WidgetDataset } from "/app/src/models";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";

export default function Dataset({
  dataset,
  updateDataset,
  deleteDataset,
}: {
  dataset: WidgetDataset;
  updateDataset: (datasetId: number, dataset: WidgetDataset) => Promise<any>;
  deleteDataset: (dataset: WidgetDataset) => Promise<any> | undefined;
}) {
  const [snapshot, setSnapshot] = useState<Snapshot>({});
  const [colourOpen, setColourOpen] = useState(false);

  // Get Snapshots
  useEffect(() => {
    if (dataset.snapshotId) {
      snapshotService
        .getSingle(dataset.snapshotId)
        .then((response) => {
          setSnapshot(response.snapshot);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataset]);

  const toggleColour = () => {
    setColourOpen(!colourOpen);
  };

  const updateColour = (colour: ColorResult) => {
    if (dataset.id) {
      updateDataset(dataset.id, { colour: colour.hex });
    }
    setColourOpen(false);
  };

  const handleDeleteClick = (): void => {
    deleteDataset(dataset);
  };

  return (
    <div className="dataset">
      <Link className="link" to={`/reports/${snapshot.reportId}/snapshots`}>
        {snapshot.name}{" "}
        <span style={{ fontStyle: "italic" }}>({snapshot.reportName})</span>
      </Link>
      <div className="colorPicker">
        <button
          className="colorButton"
          onClick={() => toggleColour()}
          style={{ background: dataset.colour }}
        />

        {colourOpen && (
          <div className="popover">
            <TwitterPicker
              triangle="top-right"
              color={dataset.colour}
              onChangeComplete={updateColour}
            />
          </div>
        )}
      </div>
      <DeleteButton onClick={handleDeleteClick} />
    </div>
  );
}
