import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Button } from "antd";
import csvToList from "/app/src/helpers/csv";
import HistoryRecord from "./historyRecord";
import { Integration } from "/app/src/models";
import { Formik } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { sendTransactionsSchema } from "/app/src/schemas/apps/dataPush/sendTransactionSchema";

/**
 * Component to allow the user to send many transactions at once using a CSV string
 */
export default function ManyTransactions({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const [historyIds, setHistoryIds] = useState([]);
  /**
   * Update the data state when the input changes
   * @param e The event for the input change
   */
  const handleInputChange = (e) => {
    setData(e.target.value);
  };

  /**
   * Update the exportStateType of the transactions to 10
   * Track loading state and the number of transactions successfully updated
   */
  const handleResendClick = () => {
    const historyIds = csvToList(data);
    setHistoryIds(historyIds);
  };

  /**
   * Handler for 'Clear' button. Clears the data state
   */
  const handleClearClick = () => {
    setData("");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        data: "",
      }}
      validationSchema={sendTransactionsSchema}
      onSubmit={handleResendClick}
    >
      {({ dirty }) => (
        <Form layout="vertical">
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="data" hasFeedback={false}>
                  <Input.TextArea
                    value={data}
                    rows={4}
                    name="data"
                    onChange={handleInputChange}
                    placeholder={t("translation:send_transactions_using_csv")}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingTop: "10px" }}>
              <Col span={4} offset={16}>
                <SubmitButton
                  type="primary"
                  onClick={handleResendClick}
                  disabled={data.trim() === "" || !dirty}
                  block
                >
                  {t("translation:send_manually")}
                </SubmitButton>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  onClick={handleClearClick}
                  disabled={data.trim() === ""}
                  block
                >
                  {t("translation:clear")}
                </Button>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                {historyIds.length > 0 && (
                  <Row gutter={20}>
                    <Col span={3}>
                      <h4>{t("translation:transaction_id")}</h4>
                    </Col>
                    <Col span={9}>
                      <h4>{t("translation:data_sent")}</h4>
                    </Col>
                    <Col span={9}>
                      <h4>{t("translation:data_received")}</h4>
                    </Col>
                    <Col span={3}>{t("translation:status")}</Col>
                  </Row>
                )}
                {historyIds.map((historyId) => (
                  <HistoryRecord
                    key={historyId}
                    transactionId={historyId}
                    integration={integration}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
}
