import { string, object } from "yup";
import i18next from "/app/src/locales/config";

export const licenseSchema = object().shape({
  username: string()
    .trim()
    .required(i18next.t("translation:username_is_required")),
  password: string()
    .trim()
    .required(i18next.t("translation:password_is_required")),
  license: string()
    .trim()
    .required(i18next.t("translation:license_is_required")),
});
