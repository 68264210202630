import React, { useState, useEffect, useContext } from "react";
import { Spin } from "antd";
import { ResponsivePie } from "@nivo/pie";
import { Widget } from "/app/src/models";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";
import { HistoricalData, CurrentData } from "/app/src/types";

type PieDatum = {
  id: string;
  value: number;
  colour: string;
};

export default function DashboardPieChart({ widget }: { widget: Widget }) {
  const [chartData, setChartData] = useState<PieDatum[]>([]);
  const [loading, setLoading] = useState(true);
  const [grouped, setGrouped] = useState<boolean>(false);
  const { data } = useContext(WidgetSnapshotDataContext);

  const addToData = (newData: PieDatum[]) => {
    setChartData(newData);
  };

  useEffect(() => {
    let newData: PieDatum[] = [];
    if (widget.datasets) {
      widget.datasets.forEach((d) => {
        const dataset = data.snapshotsData.find((x) => x.id === d.id);
        if (dataset && dataset.data && dataset.colour) {
          if (dataset.groupColumnName) {
            setGrouped(true);
          }
          // Format
          newData = addData(newData, dataset.data, dataset.colour);
        }
      });
    }
    setLoading(false);
    addToData(newData);
  }, [widget.datasets, data.snapshotsData]);

  if (loading) {
    return <Spin />;
  } else {
    return (
      <div className="chart pie">
        <ResponsivePie
          data={chartData}
          innerRadius={0.2}
          padAngle={0.7}
          cornerRadius={3}
          colors={grouped ? { scheme: "nivo" } : (pie) => pie.data.colour}
          margin={{ top: 20, right: 0, bottom: 50, left: 0 }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        />
      </div>
    );
  }
}

function addData(
  allData: PieDatum[],
  newData: HistoricalData | CurrentData[],
  colour: string
) {
  if (Array.isArray(newData)) {
    newData.forEach((item) => {
      const group: PieDatum = {
        id: item["name"],
        value: item["value"] as number,
        colour: colour,
      };

      allData = [...allData, group];
    });
  }
  return allData;
}
