import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { PlusOutlined } from "@ant-design/icons";
import { Setting, Widget } from "/app/src/models";
import { settingService } from "/app/src/services";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { useTranslation } from "react-i18next";
import TrendLine from "./trendLine";
import { Col, Row } from "antd";

interface FormValues {
  value: string;
  name: string;
  number: string;
}

/**
 * Trendlines are used to display a trendline on a bar chart
 * @param widget the widget to get the trendlines for
 * @returns component to display the trendlines
 */
export default function TrendLines({ widget }: { widget: Widget }) {
  const { t } = useTranslation();
  const [trendLines, setTrendLines] = useState<Setting[]>([]);
  useEffect(() => {
    settingService
      .getAll(buildParams({ type: "trendline", widgetId: widget.id }))
      .then((response) => {
        setTrendLines(response.settings);
      });
  }, [widget.id]);

  /**
   * Function to replace old trendline with new one
   * @param trendLine the updated trendline
   */
  const updateTrendLines = (trendLine: Setting) => {
    const newTrendLines = trendLines.map((trend) => {
      if (trend.id === trendLine.id) {
        return trendLine;
      }
      return trend;
    });
    setTrendLines(newTrendLines);
  };
  /**
   * Function to delete a trendline. Find the trendline in the array and remove it
   * @param trendLine the trendline to delete
   */
  const deleteTrendLine = (trendLine: Setting) => {
    settingService.deleteSingle(trendLine.id).then(() => {
      const newTrendLines = trendLines.filter((trend) => {
        return trendLine.id !== trend.id;
      });
      setTrendLines(newTrendLines);
    });
  };

  /**
   * Form to create a new trendline
   * @param props the formik props
   * @returns the form
   */
  const newTrendLineForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    isSubmitting,
  }) => (
    <Form>
      <Row justify="start" gutter={4}>
        <Col span={12}>
          <Form.Item name="name" hasFeedback={false}>
            <Input name="name" placeholder={t("translation:name")} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="number" hasFeedback={false}>
            <Input name="number" placeholder={t("translation:number")} />
          </Form.Item>
        </Col>
        <Col span={4} offset={4}>
          <div style={{ float: "right" }}>
            <SubmitButton
              type="primary"
              icon={<PlusOutlined />}
              disabled={!dirty || !isValid || isSubmitting}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div>
      <div className="trendlines">
        {trendLines.map((trendLine) => {
          return (
            <TrendLine
              key={trendLine.id}
              trendLine={trendLine}
              updateTrendlines={updateTrendLines}
              deleteTrendline={deleteTrendLine}
            />
          );
        })}
      </div>
      <Formik
        initialValues={{ value: "#0080FF", name: "", number: "" }}
        component={newTrendLineForm}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "number", type: "number", required: true },
        ])}
        enableReinitialize
        onSubmit={(values, actions) => {
          settingService
            .createSingle(formatForm(values, widget.id))
            .then((response) => {
              setTrendLines([...trendLines, response.setting]);
              actions.resetForm();
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

/**
 * Function to take form values and widgetId and return a setting object
 * @param values values passed from the form
 * @param widgetId The widget id to which the trendline belongs
 * @returns the setting object to be saved
 */
function formatForm(values: FormValues, widgetId: number) {
  const value = { color: values.value, value: values.number };
  //convert to json string
  return {
    widgetId,
    name: values.name,
    value: JSON.stringify(value),
    number: 1,
    type: "trendline",
  };
}
