import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Kit } from "../models";
import { Error } from "../types";

/**
 * Get all kits
 * @param params URLSearchParams
 * @returns Promise<{ kits: Kit[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ kits: Kit[] }> {
  return genericGetAll("kits", params);
}

/**
 * Get a single kit
 * @param id string
 * @returns Promise<{ kit: Kit }>
 */
function getSingle(id: string): Promise<{ kit: Kit }> {
  return genericGetSingle("kits", id);
}

/**
 * Update a single kit
 * @param id string
 * @param kit { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ kit: Kit } | Error>
 */
function updateSingle(
  id: string,
  kit: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ kit: Kit } | Error> {
  return genericUpdateSingle("kits", id, kit);
}

/**
 * Create a single kit
 * @param kit { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ kit: Kit } | Error>
 */
function createSingle(kit: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ kit: Kit } | Error> {
  return genericCreateSingle("kits", kit);
}

/**
 * Delete a single kit
 * @param id string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: string | number,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("kits", id);
}

/**
 * Gets the count of kits
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("kits", params);
}

export const kitService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
