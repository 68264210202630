import React from "react";
import Number from "./number";
import { Row } from "antd";
import { Widget } from "/app/src/models";
export default function NumberChart({ widget }: { widget: Widget }) {
  return (
    <div className="numberChart">
      <Row>
        {widget.datasets &&
          widget.datasets.map(
            (dataset, index) =>
              dataset.snapshotId && (
                <Number
                  datasetId={dataset.id}
                  snapshotsAmount={
                    widget.datasets === undefined ? 0 : widget.datasets.length
                  }
                  colour={dataset.colour}
                  key={index}
                />
              )
          )}
      </Row>
    </div>
  );
}
