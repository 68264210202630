import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Settings from "./settings";
import OrderBuilder from "./builder";
import { App, Integration as IntegrationType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();
  const [integration, setIntegration] = useState<IntegrationType>({});

  // Get integration
  useEffect(() => {
    if (integrationId) {
      integrationService
        .getSingle(integrationId)
        .then((response) => setIntegration(response.integration));
    }
  }, [integrationId]);

  const links = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "builder",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/settings`,
      label: "settings",
    },
  ];

  return (
    <div className="integration">
      <Helmet>
        <title>{integration.name}</title>
      </Helmet>

      <Row gutter={20}>
        <Col span={6}>
          <SideMenuBox links={links} />
        </Col>
        <Col span={18}>
          {integration.id && (
            <Routes>
              <Route path="/" element={<OrderBuilder app={app} />} />
              <Route
                path="settings"
                element={<Settings integration={integration} />}
              />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
