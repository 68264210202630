import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { Column } from "react-table";
import getOrderByQuery from "/app/src/helpers/table";

import { connectionService } from "/app/src/services";
import DeleteButtonPopup from "/app/src/components/generic/components/buttons/DeleteButtonPopup";
import Table from "/app/src/components/generic/tables/table";
import Controls from "./_controls";
import NewIntegration from "./newIntegrationForm";
import { useTranslation } from "react-i18next";
import { App, Connection } from "/app/src/models";
import { useSort } from "/app/src/hooks";
import { buildParams } from "/app/src/helpers/params";

/**
 * Component for the Philips Hue app
 * @param app: Philips Hue app
 */
export default function Hue({ app }: { app: App }) {
  const { t } = useTranslation();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [activeNewIntegration, setActiveNewIntegration] = useState(false);
  //get the list of integrations that match the app Id -> this returns the connected hubs
  const refreshTable = React.useCallback(() => {
    connectionService
      .getAll(
        buildParams({
          limit: pageSize,
          page,
          type: "[or]Hue Local;Hue Remote",
        }),
      )
      .then((response) => {
        setConnections(response.connections);
      });
  }, [page, pageSize]);
  //columns found in the table that holds the list of connected hubs
  const columns: Column<Connection>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
      {
        Header: t("translation:access_type"),
        accessor: "type",
        Cell: ({ cell: { value } }) => {
          return value === "Hue Local" ? (
            <p>Local Access</p>
          ) : (
            <p>Remote Access</p>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <DeleteButtonPopup
            deleteObjectFn={() => connectionService.deleteSingle(value)}
            refresh={refreshTable}
            confirmDeleteText={t("translation:confirm_remove_bridge")}
          />
        ),
      },
    ];
  }, [t, refreshTable]);

  /**
   * Toggle the new integration form
   */
  const toggleNewIntegration = () => {
    setActiveNewIntegration(!activeNewIntegration);
  };

  // Get number of connected hubs
  useEffect(() => {
    connectionService
      .getCount(
        buildParams({
          type: "[or]Hue Local;Hue Remote",
        }),
      )
      .then((response) => {
        setLength(response.count);
      });
  }, []);

  useEffect(() => {
    connectionService
      .getAll(
        buildParams({
          limit: pageSize,
          page,
          type: "[or]Hue Local;Hue Remote",
          orderby: getOrderByQuery(sort),
        }),
      )
      .then((response) => {
        setConnections(response.connections);
      });
  }, [page, pageSize, sort]);

  return (
    <div className="app epicorImport">
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>

      <Controls
        name={app.name as string}
        activeNewIntegration={activeNewIntegration}
        setActiveNewIntegration={setActiveNewIntegration}
        refresh={refreshTable}
      />
      <Row gutter={20}>
        <Col span={6}>
          <NewIntegration
            connections={connections}
            toggleNewIntegration={toggleNewIntegration}
            refresh={refreshTable}
          />
        </Col>
        <Col span={18}>
          <Table
            rows={connections}
            tableColumns={columns}
            length={length}
            sort={sort}
            setSort={setSort}
            enableRowSelection={false}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            emptyText={t("translation:no_bridges_connected")}
          />
        </Col>
      </Row>
    </div>
  );
}
