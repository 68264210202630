import React, { useEffect, useState } from "react";
import { callService, userService } from "/app/src/services";
import { Helmet } from "react-helmet";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useSort } from "/app/src/hooks";
import Table from "/app/src/components/generic/tables/table";
import { buildParams } from "/app/src/helpers/params";
import { getDateFormat } from "/app/src/helpers/time";
import Controls from "./controls";
import { useTranslation } from "react-i18next";
import { User, apiCall } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { roundDecimal } from "../../helpers/data";
import { Modal } from "antd";

/**
 * Build the params for the api call. Calculates the creation date range, and
 * builds the params for the search, status, and user.
 */
function buildCallParams(
  searchString: string,
  afterTime: string,
  beforeTime: string,
  selectedStatus: string,
  selectedUser: string,
) {
  const creationDate = [];
  if (afterTime !== "") {
    creationDate.push(`[gt]${getDateFormat(afterTime, "YYYY-MM-DDTHH:mm:ss")}`);
  }
  if (beforeTime !== "") {
    creationDate.push(
      `[lt]${getDateFormat(beforeTime, "YYYY-MM-DDTHH:mm:ss")}`,
    );
  }

  return buildParams({
    search: searchString,
    creationDate,
    code: selectedStatus,
    userId: selectedUser,
  });
}

/**
 * Component for displaying the api calls
 */
export default function Calls() {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedUser, setSelectedUser] = useState("all");
  const queryClient = useQueryClient();

  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:date/time"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          if (value !== null) {
            return (
              <DateTime date={value} format={"MMMM Do YYYY, h:mm:ss.SS a"} />
            );
          } else {
            return <> </>;
          }
        },
      },
      {
        Header: t("translation:code"),
        accessor: "code",
      },
      {
        Header: t("translation:duration"),
        accessor: "duration",
        Cell: ({ cell: { value } }) => {
          if (value !== null) {
            return <>{roundDecimal(value, 1)} ms</>;
          } else {
            return <> </>;
          }
        },
      },
      {
        Header: t("translation:endpoint"),
        accessor: "endpoint",
      },
      {
        Header: t("translation:user"),
        accessor: "username",
      },

      {
        Header: t("translation:body"),
        accessor: "body",
      },
      {
        Header: t("translation:response"),
        accessor: "response",
        maxWidth: 1000,
      },
      {
        Header: t("translation:error"),
        accessor: "error",
      },
    ];
  }, [t]);

  //Get the list of all users
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => {
      return userService.getAll();
    },
    initialData: { users: [] },
    select: (data: { users: User[] }) => {
      return data.users;
    },
  });

  //handler for clearing logs button
  const clearLogs = () => {
    confirm({
      title: t("translation:confirm_delete_logs"),
      okText: t("translation:delete"),
      cancelText: t("translation:cancel"),
      okButtonProps: { danger: true },
      onOk() {
        callService.deleteAll().then(() => {
          queryClient.setQueryData(
            [
              "apiCalls",
              page,
              searchString,
              afterTime,
              beforeTime,
              selectedStatus,
              selectedUser,
            ],
            { calls: [] },
          );
          queryClient.setQueryData(
            [
              "callCount",
              page,
              searchString,
              afterTime,
              beforeTime,
              selectedStatus,
              selectedUser,
            ],
            { count: 0 },
          );
        });
      },
    });
  };

  //get the count
  const { data: callCount } = useQuery({
    queryKey: [
      "callCount",
      page,
      searchString,
      afterTime,
      beforeTime,
      selectedStatus,
      selectedUser,
    ],
    queryFn: () => {
      const countParams = buildCallParams(
        searchString,
        afterTime,
        beforeTime,
        selectedStatus,
        selectedUser,
      );
      return callService.getCount(countParams);
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //get the list of api calls
  const { data: apiCalls, isFetching } = useQuery({
    queryKey: [
      "apiCalls",
      page,
      pageSize,
      searchString,
      afterTime,
      beforeTime,
      selectedStatus,
      selectedUser,
      sort,
    ],
    queryFn: () => {
      const params = buildCallParams(
        searchString,
        afterTime,
        beforeTime,
        selectedStatus,
        selectedUser,
      );
      params.append("page", String(page));
      params.append("limit", String(pageSize));
      params.append("orderby", getOrderByQuery(sort));
      return callService.getAll(params);
    },
    initialData: { calls: [] },
    select: (data: { calls: apiCall[] }) => {
      return data.calls;
    },
  });

  useEffect(() => {
    setPage(0);
  }, [searchString, afterTime, beforeTime, selectedStatus, selectedUser]);

  return (
    <div className="calls">
      <Helmet>
        <title>{t("translation:api_calls")} - ItemPath</title>
      </Helmet>
      <Controls
        clearLogs={clearLogs}
        setSelectedStatus={setSelectedStatus}
        setSelectedUser={setSelectedUser}
        setAfterTime={setAfterTime}
        setBeforeTime={setBeforeTime}
        users={users}
        setSearchString={setSearchString}
      />

      <Table
        loading={isFetching}
        rows={apiCalls}
        tableColumns={columns}
        length={callCount}
        sort={sort}
        setSort={setSort}
        rowClicked={() => {}}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        emptyText={"No Calls Found"}
      />
    </div>
  );
}
