import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { reportService, userReportService } from "/app/src/services";
import NewReportForm from "./newReportForm";
import Filters from "./filters";
import Controls from "./controls";
import { Row, Col, Tag, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "/app/src/components/generic/tables/table";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import DeleteButtonPopup from "/app/src/components/generic/components/buttons/DeleteButtonPopup";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Report as ReportType } from "/app/src/models";
import { themes as themesList } from "/app/src/constants/themes";
import DuplicateReport from "./duplicateReport";
import { useSort } from "/app/src/hooks";
import { jobAppCheck } from "/app/src/helpers/themes";
import { buildParams } from "/app/src/helpers/params";
import { useAuthState } from "/app/src/contexts/authentication";
import { Cell, Column } from "react-table";
import { Sort } from "/app/src/types";
import FavouriteStar from "../generic/components/favouriteStar";
import { useContextForPermission } from "/app/src/hooks/usePermission";

// Custom component to render Themes
const Theme = ({ value }: { value: string }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return <Tag className="theme">{value}</Tag>; // skipcq: JS-0394
};

/**
 * Component to display a list of reports, with filters, controls, and table
 */
export default function ReportList() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { canCreate, canDelete } = useContextForPermission("REPO");

  const [themes, setThemes] = useState([]);
  const [isAllReports, setIsAllReports] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([{ id: "name", desc: false }]);
  const [searchString, setSearchString] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("all");
  const [activeNew, setActiveNew] = useState(false);
  const [activeImportReport, setActiveImportReport] = useState(false);
  const invalidateReports = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["reports"] });
    queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
  }, [queryClient]);
  const toggleNew = () => {
    setActiveNew(!activeNew);
  };
  const toggleImportReport = () => {
    setActiveImportReport(!activeImportReport);
  };

  const goToReport = (reportId: number) => {
    navigate(`/reports/${reportId}`);
  };
  const unFavouriteReport = useCallback(
    (reportId: number) => {
      userReportService.unfavouriteReport(user.id, reportId).then(() => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        if (!isAllReports) {
          queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
        }
      });
    },
    [user.id, queryClient, isAllReports],
  );
  useEffect(() => {
    jobAppCheck(themesList).then((response) => {
      setThemes(response);
    });
  }, []);
  const setAllReports = useCallback(() => {
    setIsAllReports(true);
  }, []);
  const setFavourites = useCallback(() => {
    setIsAllReports(false);
  }, []);

  const favouriteReport = useCallback(
    (reportId: number) => {
      userReportService.favouriteReport(user.id, reportId).then(() => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      });
    },
    [user.id, queryClient],
  );

  useEffect(() => {
    setPage(0);
  }, [searchString, selectedTheme, isAllReports]);

  /**
   * Function to get reports
   * @param size the page size
   * @param currentPage the current page
   * @param theme the selected report theme
   * @param search search string
   * @param sortBy column to sort by
   * @param allReports boolean to determine if all reports or favourited reports
   * @returns Promise<any>
   */
  const getReports = (
    size: number,
    currentPage: number,
    theme: string,
    search: string,
    sortBy: Sort,
    allReports: boolean,
  ) => {
    const getAllParams = buildParams({
      limit: size,
      page: currentPage,
      theme,
      search,
      orderby: getOrderByQuery(sortBy),
      roleId: user.roleId,
    });
    if (allReports) {
      return reportService.getAll(getAllParams);
    }
    return userReportService.getFavouritedReports(user.id, getAllParams);
  };

  /**
   * The function to get the count of reports
   * @param search search string
   * @param theme report theme to filter by
   * @param allReports boolean to determine if all reports or favourited reports
   * @returns Promise<any>
   */
  const getReportsCount = (
    search: string,
    theme: string,
    allReports: boolean,
  ) => {
    const getCountParams = buildParams({
      countOnly: true,
      search,
      theme,
      roleId: user.roleId,
    });
    if (allReports) {
      return reportService.getAll(getCountParams);
    }
    return userReportService.getFavouritedReports(user.id, getCountParams);
  };

  const getReportsQuery = useQuery({
    queryKey: [
      "reports",
      page,
      pageSize,
      searchString,
      selectedTheme,
      sort,
      isAllReports,
    ],
    queryFn: () =>
      getReports(
        pageSize,
        page,
        selectedTheme,
        searchString,
        sort,
        isAllReports,
      ),
    keepPreviousData: true,
    staleTime: 30 * 1000,
    placeholderData: { reports: [] },
  });

  const getReportsCountQuery = useQuery({
    queryKey: ["reportsCount", searchString, selectedTheme, isAllReports],
    queryFn: () => getReportsCount(searchString, selectedTheme, isAllReports),
    keepPreviousData: true,
    staleTime: 30 * 1000,
    placeholderData: { count: 0 },
  });

  const columns: Column<ReportType>[] = React.useMemo(() => {
    const columnHeaders: object[] = [
      {
        Header: "",
        accessor: "favouritedUsers",
        clickable: false,
        disableSortBy: true,
        Cell: (cell: Cell) => {
          if (Array.isArray(cell.value)) {
            return (
              <FavouriteStar
                isFavourite={cell.value.includes(user.id)}
                objectId={cell.row.original.id}
                favouriteFn={favouriteReport}
                unfavouriteFn={unFavouriteReport}
              />
            );
          }
          return null;
        },
      },
      {
        Header: t("translation:report_name"),
        accessor: "name",
      },
      {
        Header: t("translation:theme"),
        accessor: "baseTable",
        Cell: (cell: Cell) => {
          return <Theme value={cell.value} />;
        },
      },
      {
        Header: t("translation:date"),
        accessor: "creationDate",
        Cell: (cell: Cell) => {
          return <DateTime date={cell.value} />;
        },
      },
      {
        Header: "",
        id: "delete",
        accessor: "id",
        clickable: false,
        Cell: (cell: Cell) => (
          <div style={{ display: "flex" }}>
            {canCreate && <DuplicateReport reportId={cell.value} />}
            {canDelete && (
              <DeleteButtonPopup
                confirmDeleteText={t("translation:confirm_delete_report")}
                deleteObjectFn={() => reportService.deleteSingle(cell.value)}
                refresh={invalidateReports}
              />
            )}
          </div>
        ),
      },
    ];
    return columnHeaders as Column<ReportType>[];
  }, [
    t,
    user.id,
    favouriteReport,
    unFavouriteReport,
    canCreate,
    canDelete,
    invalidateReports,
  ]);

  return (
    <div className="reports">
      <Helmet>
        <title>{t("translation:reports")} - ItemPath</title>
      </Helmet>

      {canCreate && (
        <Controls
          toggleNew={toggleNew}
          activeNew={activeNew}
          goToReport={goToReport}
          toggleImportReport={toggleImportReport}
          activeImportReport={activeImportReport}
        />
      )}
      {activeNew ? <NewReportForm themes={themes} /> : ""}
      <Row gutter={20}>
        <Col span={5}>
          <Row>
            <Col span={12}>
              <Button
                size="large"
                type={isAllReports ? "primary" : "default"}
                className={isAllReports ? "disabledButton" : "fullWidth"} // skipcq: JS-0394
                onClick={setAllReports}
              >
                {t("translation:all_reports")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                type={!isAllReports ? "primary" : "default"}
                className={!isAllReports ? "disabledButton" : "fullWidth"} // skipcq: JS-0394
                onClick={setFavourites}
              >
                {t("translation:favourites")}
              </Button>
            </Col>
          </Row>
          <Filters
            themes={themes}
            setSearchString={setSearchString}
            setSelectedTheme={setSelectedTheme}
          />
        </Col>
        <Col span={19}>
          <Table
            rows={getReportsQuery.data?.reports}
            loading={getReportsQuery?.isFetching || getReportsQuery?.isLoading}
            tableColumns={columns}
            length={getReportsCountQuery.data?.count}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            rowClicked={goToReport}
            emptyText={
              isAllReports
                ? t("translation:no_reports_found")
                : t("translation:no_favourite_reports")
            }
          />
        </Col>
      </Row>
    </div>
  );
}
