import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import Calls from "./calls";
import { Route, Routes } from "react-router-dom";

export default function Logs() {
  const [title] = useState("Logs");

  return (
    <>
      <Helmet>
        <title>{title} - ItemPath</title>
      </Helmet>
      <Routes>
        <Route element={<Calls />} path="/" />
      </Routes>
    </>
  );
}
