import {
  genericGetSingle,
  genericCreateSingle,
  genericGetAll,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("tasks", params);
}

function getSingle(taskId: number | string) {
  return genericGetSingle("tasks", taskId);
}

function createSingle(task: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("tasks", task);
}

/**
 * Gets the count of tasks
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("tasks", params);
}

export const taskService = {
  getAll,
  getSingle,
  createSingle,
  getCount,
};
