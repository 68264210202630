import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { ResponsiveBarCanvas, BarDatum } from "@nivo/bar";

import { snapshotDataService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { SnapshotData } from "/app/src/models";
export default function ReportBarChart({ snapshotId }: { snapshotId: number }) {
  const [data, setData] = useState<BarDatum[]>([]);
  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<string[]>([]);
  const [index, setIndex] = useState<string>();

  const addToData = (newData: BarDatum[], newKey: string, index: string) => {
    setData(newData);
    setKeys([newKey]);
    setIndex(index);
  };

  useEffect(() => {
    snapshotDataService
      .getAll(
        snapshotId,
        buildParams({ isHistorical: "false", refresh: "true" })
      )
      .then((response: { snapshot: SnapshotData }) => {
        // Format
        addToData(
          formatData(response.snapshot.data, response.snapshot),
          response.snapshot.name,
          response.snapshot.reportColumnName
        );
      });

    setLoading(false);
  }, [snapshotId]);

  if (loading) {
    return <Spin />;
  } else {
    return (
      <div className="chart">
        <ResponsiveBarCanvas
          data={data}
          groupMode="stacked"
          indexBy={index}
          keys={keys}
          enableGridX={false}
          padding={0.3}
          borderRadius={5}
          colors={["#0080FF", "#00DAFF"]}
          margin={{ top: 10, right: 0, bottom: 25, left: 50 }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
        />
      </div>
    );
  }
}

function formatData(newData: BarDatum[], snapshot: SnapshotData): BarDatum[] {
  const data: BarDatum[] = [];
  if (newData) {
    newData.forEach((item) => {
      const group = {
        [snapshot.reportColumnName]: item["name"],
        [snapshot.name]: item["value"],
      };
      data.push(group);
    });
  }
  return data;
}
