import React from "react";
import { Row, Col } from "antd";

import FiltersButton from "./filtersButton";
import ColumnsButton from "./columnsButton";
import SnapshotsButton from "./snapshotsButton";
import ExportButton from "./exportButton";
import DataButton from "./dataButton";
import Title from "./title";
import { Report } from "/app/src/models";
import PermissionIcons from "./permissionIcons";

export default function Controls({
  report,
  columnsCount,
  filtersCount,
  snapshotsCount,
}: {
  report: Report;
  columnsCount: number;
  filtersCount: number;
  snapshotsCount: number;
}) {
  return (
    <div className="controls">
      <Row align="bottom">
        <Col>
          <Title report={report} />
        </Col>
        <Col flex="auto" className="controlTabs">
          <Row>
            <Col span={24} style={{ marginBottom: "15px" }}>
              <PermissionIcons />
            </Col>
            <Col span={24}>
              <FiltersButton filtersCount={filtersCount} />
              <ColumnsButton columnsCount={columnsCount} />
              <SnapshotsButton snapshotsCount={snapshotsCount} />
              <ExportButton />
              <DataButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
