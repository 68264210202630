import { useState, useEffect } from "react";
import { InputNumber } from "antd";
import { materialService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Material, OrderLine } from "/app/src/models";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";

export default function Line({
  line,
  removeLine,
  updateLineQuantity,
}: {
  line: OrderLine;
  removeLine: (lineId: string) => void;
  updateLineQuantity: (lineId: string, newQuantity: number) => void;
}) {
  const { t } = useTranslation();
  const [material, setMaterial] = useState<Material>({});
  useEffect(() => {
    if (line.materialId) {
      materialService
        .getSingle(line.materialId)
        .then((response) => setMaterial(response.material))
        .catch((error) => {
          console.log(error);
        });
    }
  }, [line]);

  const handleDeleteClick = (): void => {
    if (line.materialId) {
      removeLine(line.materialId);
    }
  };

  return (
    <div className={`line ${material?.currentQuantity ? "" : "outOfStock"}`}>
      <div className="title">
        <h4>{material.name}</h4>
        <span className="stock ">
          {t("translation:in_stock")}: {material.currentQuantity}
        </span>
      </div>
      <div className="quantity">
        <InputNumber
          defaultValue={line.quantity}
          name="quantity"
          placeholder=""
          size="large"
          onChange={(value) => {
            if (line?.materialId && value) {
              updateLineQuantity(line.materialId, value);
            }
          }}
        />
      </div>
      <div className="actions">
        <DeleteButton onClick={handleDeleteClick} />
      </div>
    </div>
  );
}
