import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import MaxRecords from "./maxRecords";
import Timeout from "./timeout";
import Verify from "./verify";
import Path from "./path";
import Retries from "./retries";

/**
 * Component to display the connection settings for a Data Push Integration
 * @param integration Integration
 */
export default function ConnectionSettings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  return (
    <div className="box">
      <h1>{t("translation:connection")}</h1>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Path integration={integration} />
        </Col>
        <Col span={4}>
          <Verify integration={integration} />
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Timeout integration={integration} />
        </Col>
        <Col span={6}>
          <MaxRecords integration={integration} />
        </Col>
        <Col span={6}>
          <Retries integration={integration} />
        </Col>
      </Row>
    </div>
  );
}
