import React, { useEffect, useContext } from "react";
import { PageHeader } from "antd";
import { Row, Col } from "antd";
import EditWidget from "./edit";
import { DragOutlined } from "@ant-design/icons";
import Chart from "./chart";
import ErrorTip from "../../generic/components/errorTip";
import { useTranslation } from "react-i18next";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";
import { Widget as WidgetType } from "/app/src/models";

export default function Widget({
  widget,
  updateWidget,
  deleteWidget,
  locked,
  isEditing,
}: {
  widget: WidgetType;
  updateWidget: (widgetId: number, widget: WidgetType) => Promise<any>;
  deleteWidget: (widgetId: number) => Promise<any> | undefined;
  locked: boolean;
  isEditing: boolean;
}) {
  const { data } = useContext(WidgetSnapshotDataContext);
  const { t } = useTranslation();

  useEffect(() => {
    let timeout = 3600000;
    if (widget?.frequency) {
      timeout = widget.frequency * 1000;
    }
    const id = setInterval(() => {
      // if the popup is not open, test status
      if (widget.datasets) {
        for (let x = 0; x < widget?.datasets.length; x++) {
          const dataset = widget.datasets[x];
          data.getData(dataset, widget);
        }
      }
    }, timeout);
    return () => {
      clearInterval(id);
    };
  }, [widget, data]);

  return (
    <div>
      {isEditing ? (
        <EditWidget
          widget={widget}
          updateWidget={updateWidget}
          deleteWidget={deleteWidget}
        />
      ) : (
        <div>
          <div style={{ display: locked === false ? "none" : "" }}>
            <Row>
              <Col>
                <PageHeader ghost={false} title={widget.name} />
              </Col>
              <Col>
                {!validChartType(widget.chartType, widget.isHistorical) && (
                  <ErrorTip tip={t("translation:invalid_chart_type")} />
                )}
              </Col>
            </Row>
            {validChartType(widget.chartType, widget.isHistorical) && (
              <Chart widget={widget} />
            )}
          </div>
          <Row
            justify="center"
            align="middle"
            style={{ display: locked === true ? "none" : "", height: "300px" }}
          >
            <Col span={4}>
              <DragOutlined style={{ fontSize: "64px", color: "#82878e" }} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function validChartType(
  type: string | undefined,
  isHistorical: boolean | undefined,
) {
  if (isHistorical && type === "line") {
    return true;
  } else if (
    !isHistorical &&
    (type === "bar" || type === "number" || type === "pie")
  ) {
    return true;
  } else {
    return false;
  }
}
