/**
 * Component for displaying connection status
 * @returns JSX.Element
 *
 */
export default function ConnectionStatus() {
  return (
    <div>
      <div className="box">
        <div className="box-header">
          <h3>PowerPick MSSQL</h3>
        </div>
        <div>&nbsp;</div>
        <div className="box-header">
          <h3>PowerPick Web Services</h3>
        </div>
      </div>
    </div>
  );
}
