import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("views", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("views", id);
}

function updateSingle(
  id: number | string,
  view: { [key: string]: string | number | boolean | Date | undefined }
) {
  return genericUpdateSingle("views", id, view);
}

function createSingle(view: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("views", view);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("views", id);
}

/**
 * Gets the count of views
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("views", params);
}

export const viewService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
