import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { ReportColumnType } from "../models";
import { Error } from "../types";

/**
 * Get all report column types
 * @param params URLSearchParams
 * @returns Promise<{ report_column_types: ReportColumnType[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ report_column_types: ReportColumnType[] }> {
  return genericGetAll("report_column_types", params);
}

/**
 * Get a single report column type
 * @param id number | string
 * @returns Promise<{ report_column_type: ReportColumnType }>
 */
function getSingle(id: number | string) {
  return genericGetSingle("report_column_types", id);
}

/**
 * Create a single report column type
 * @param reportColumnType { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_column_type: ReportColumnType } | Error>
 */
function createSingle(reportColumnType: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ report_column_type: ReportColumnType } | Error> {
  return genericCreateSingle("report_column_types", reportColumnType);
}

/**
 * Delete a single report column type
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("report_column_types", id);
}

/**
 * Update a single report column type
 * @param id number | string
 * @param reportColumnType { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_column_type: ReportColumnType } | Error>
 */
function updateSingle(
  id: number | string,
  reportColumnType: {
    [key: string]: string | number | boolean | Date | undefined;
  },
): Promise<{ report_column_type: ReportColumnType } | Error> {
  return genericUpdateSingle("report_column_types", id, reportColumnType);
}

/**
 * Gets the count of reportColumnTypes
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("report_column_types", params);
}

export const reportColumnTypeService = {
  getAll,
  getSingle,
  createSingle,
  deleteSingle,
  updateSingle,
  getCount,
};
