import { NavLink } from "react-router-dom";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function FiltersButton({
  filtersCount,
}: {
  filtersCount: number;
}) {
  const { reportId } = useNumberId();
  const { t } = useTranslation();

  return (
    <NavLink to={`/reports/${reportId}/filters`}>
      {t("translation:filters")}
      <Badge
        count={filtersCount}
        style={{
          backgroundColor: "#fff",
          color: "#999",
          boxShadow: "0 0 0 1px #d9d9d9 inset",
          marginLeft: "5px",
          marginTop: "-3px",
        }}
      />
    </NavLink>
  );
}
