// @ts-nocheck
// ignoring typescript errors as Select from formik-antd is not properly typed
// the antd version they use is a few versions behind. Specific errors have to do
// with filterOption and filterSort params of Select
import React from "react";
import { Select } from "formik-antd";

type genericOption = {
  id?: number | string;
  name?: string;
};

export function SearchFilter({
  list,
  placeholder = "",
  name,
  sort = false,
  allowClear = false,
  mapOptionsFn = defaultMapOptionsFn,
  onChange = () => {},
  disabled = false,
}: {
  list: genericOption[];
  placeholder?: string;
  name: string;
  sort?: boolean;
  allowClear?: boolean;
  mapOptionsFn?: (option: genericOption) => JSX.Element;
  onChange?: (value: any) => void;
  disabled?: boolean;
}) {
  return (
    <Select
      name={name}
      size="large"
      disabled={disabled}
      allowClear={allowClear}
      placeholder={placeholder}
      style={{ width: "100%" }} // skipcq: JS-0394
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => filterOptions(input, option)}
      filterSort={
        sort
          ? (optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
          : undefined
      }
    >
      {list.map((c) => {
        if (mapOptionsFn) {
          return mapOptionsFn(c);
        } else {
          return defaultMapOptionsFn(c);
        }
      })}
    </Select>
  );
}

function filterOptions(input: string, option) {
  if (Array.isArray(option.children)) {
    return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

function defaultMapOptionsFn(option: genericOption) {
  return (
    <Select.Option value={option.id} key={option.id}>
      {option.name}
    </Select.Option>
  );
}
