import { useContext } from "react";
import PermissionContext from "../contexts/PermissionContext";
import { checkForPermissionLevel } from "../helpers/permissions";

/**
 * Hook to get permission levels from the PermissionContext given a permission code
 * @param code Permission code to check
 * @returns canEdit, canDelete, canCreate, canView
 */
export function useDashboardPermission() {
  const permissions = useContext(PermissionContext);
  const isLocked = checkForPermissionLevel(permissions, "DASH", "MISC");
  return {
    dashboardLocked: isLocked,
    isLoading: permissions.isLoading,
  };
}
