import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Warehouse } from "../models";

/**
 * For getting all warehouses
 * @param params URLSearchParams
 * @returns Promise<{warehouses: Warehouse[]}>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ warehouses: Warehouse[] }> {
  return genericGetAll("warehouses", params);
}

// For getting order details
function getSingle(id: string): Promise<{ warehouse: Warehouse }> {
  return genericGetSingle("warehouses", id);
}

/**
 * Gets the count of warehouses
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  if (!params) params = new URLSearchParams();
  params.append("countOnly", "true");
  return genericGetAll("warehouses", params);
}

export const warehouseService = {
  getAll,
  getSingle,
  getCount,
};
