import React from "react";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { Task } from "/app/src/models";
export default function CurrentTasks({ tasks }: { tasks: Task[] }) {
  return (
    <div className="queuedTasks">
      <ul>
        {tasks.map((result: Task) => (
          <li key={result.id}>
            <div className="data">{result.data}</div>
            <div className="data">{result.status}</div>
            <div className="date">
              <DateTime
                date={result.creationDate}
                format={"YYYY-MM-DD HH:mm:ss"}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
