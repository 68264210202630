import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("workflows", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("workflows", id);
}

function testSingle(id: number | string) {
  return makeAPIRequest({ url: `/workflows/${id}/test`, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function createSingle(workflow: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("workflows", workflow);
}

function updateSingle(
  id: number | string,
  workflow: { [key: string]: string | number | boolean | Date | undefined }
) {
  return genericUpdateSingle("workflows", id, workflow);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("workflows", id);
}

/**
 * Gets the count of workflows
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflows", params);
}

export const workflowService = {
  getAll,
  getSingle,
  testSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
