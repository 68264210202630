import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("settings", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("settings", id);
}

function createSingle(setting: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("settings", setting);
}

function updateSingle(
  id: number | string,
  setting: { [key: string]: string | number | boolean | Date | undefined }
) {
  return genericUpdateSingle("settings", id, setting);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("settings", id);
}

function updateSettings(settings: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({ url: "/settings", body: settings, method: "put" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getSingleSetting(
  params: { [key: string]: string | number | boolean | Date | undefined } = {}
) {
  return makeAPIRequest({
    url: "/settings/config",
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of settings
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("settings", params);
}

export const settingService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  updateSettings,
  getSingleSetting,
  getCount,
};
