import { string, object } from "yup";

import i18next from "/app/src/locales/config";

export const userProfileSchema = object().shape({
  email: string().email(i18next.t("translation:valid_email_warning")),

  password: string().min(4, i18next.t("translation:short_password_warning")),

  language: string(),
  //current password is required if user has entered a new password
  currentPassword: string().when("password", {
    is: (password) => password,
    then: (schema) =>
      schema.required(i18next.t("translation:current_password_is_required")),
    otherwise: (schema) => schema,
  }),
});
