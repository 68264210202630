import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function RefreshSetting({
  refresh,
  newSetting,
  setNewSetting,
}: {
  refresh: () => void;
  newSetting: boolean;
  setNewSetting: (newSetting: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Button
      type="primary"
      onClick={() => {
        refresh();
        setNewSetting(!newSetting);
      }}
    >
      {t("translation:refresh_list")}
    </Button>
  );
}
