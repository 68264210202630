export const httpErrorCodes = [
  { name: "0 Failed To Send", value: "0" },
  { name: "301 Moved Permanently", value: "301" },
  { name: "302 Found", value: "302" },
  { name: "400 Bad Request", value: "400" },
  { name: "401 Unauthorized", value: "401" },
  { name: "403 Forbidden", value: "403" },
  { name: "404 Not Found", value: "404" },
  { name: "405 Method Not Allowed", value: "405" },
  { name: "406 Not Acceptable", value: "406" },
  { name: "407 Proxy Authentication Required", value: "407" },
  { name: "408 Request Timeout", value: "408" },
  { name: "409 Conflict", value: "409" },
  { name: "410 Gone", value: "410" },
  { name: "411 Length Required", value: "411" },
  { name: "413 Payload Too Large", value: "413" },
  { name: "500 Internal Server Error", value: "500" },
  { name: "501 Not Implemented", value: "501" },
  { name: "502 Bad Gateway", value: "502" },
  { name: "503 Service Unavailable", value: "503" },
  { name: "504 Gateway Timeout", value: "504" },
  { name: "505 HTTP Version Not Supported", value: "505" },
  { name: "511 Network Authentication Required", value: "511" },
];

export const httpSuccessCodes = [
  { name: "200 OK", value: "200" },
  { name: "201 Created", value: "201" },
  { name: "202 Accepted", value: "202" },
];
