import { useState } from "react";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { Formik } from "formik";
import { Widget } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import "/app/src/components/dashboard/widget/edit/editTitle.scss";

export default function Title({
  widget,
  updateWidget,
}: {
  widget: Widget;
  updateWidget: (widgetId: number, widget: Widget) => void;
}) {
  const [editingTitle, setEditingTitle] = useState(false);

  const handleEditClick = (): void => {
    setEditingTitle(true);
  };

  return (
    <div className="title">
      {editingTitle ? (
        <Formik
          initialValues={{
            name: widget.name,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={(values, actions) => {
            if (widget.id) {
              updateWidget(widget.id, { name: values.name });
            }
            actions.resetForm();
            setEditingTitle(false);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="formGroup">
                <div className="inputContainer">
                  <input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    name="name"
                    className={`titleInput ${
                      props.errors.name ? "errorInput" : ""
                    }`}
                  />
                  <div id="feedback">
                    {props.errors.name && <p>{props.errors.name}</p>}
                  </div>
                </div>
                <button className="saveButton" type="submit">
                  <img src={saveIcon} alt="Save" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div className="noEdit">
          <span className="title">{widget.name}</span>
          <EditButton onClick={handleEditClick} />
        </div>
      )}
    </div>
  );
}
