import { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { buildParams } from "/app/src/helpers/params";
import {
  deleteObjectInStateArray,
  addObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import {
  reportService,
  fileService,
  appService,
  integrationService,
} from "/app/src/services";
import { useTranslation } from "react-i18next";
import File from "./file";
import { useAuthState } from "/app/src/contexts/authentication";
import { Report, App, File as FileType, Integration } from "/app/src/models";
import { reportExportSchema } from "/app/src/schemas/reports/reportSchema";

const { confirm } = Modal;
interface FormValues {
  exportType: string | undefined;
  integrationId: number | undefined;
}

export default function Export({ report }: { report: Report }) {
  const { user } = useAuthState();
  const { t } = useTranslation();
  const [template, setTemplate] = useState();
  const [reportFiles, setReportFiles] = useState<FileType[]>([]);
  const [apps, setApps] = useState<App[]>([]);
  const [selectedApp, setSelectedApp] = useState<App>({});
  const [integrations, setIntegrations] = useState<Integration[]>([]);

  //get the integrations for the selected file type(app)
  useEffect(() => {
    integrationService
      .getAll(buildParams({ appId: selectedApp.id }))
      .then((response) => {
        setIntegrations(response.integrations);
      });
  }, [selectedApp]);

  //get the file apps - needed for getting integrations
  useEffect(() => {
    appService
      .getAll(buildParams({ name: "[or]CSV;XLSX" }))
      .then((response) => {
        setApps(response.apps);
      });
  }, []);

  const removeFile = (reportFile: FileType) => {
    return deleteObjectInStateArray(
      reportFile,
      reportFiles,
      setReportFiles,
      fileService.deleteSingle,
    );
  };

  const addFile = (reportFile: FileType) => {
    return addObjectInStateArray(
      reportFile,
      reportFiles,
      setReportFiles,
      fileService.createSingle,
      true,
    );
  };
  //this does not use the generic functions,
  //as there is no API update occurring - instead
  //it is an update of only the front end object.
  //this occurs when the newly generated file's location
  //gets fetched
  const updateFile = (reportFile: FileType) => {
    setReportFiles(
      reportFiles.map((file) =>
        file.id === reportFile.id
          ? {
              ...file,
              location: reportFile.location,
              name: reportFile.name,
              fileType: reportFile.fileType,
            }
          : file,
      ),
    );
  };

  useEffect(() => {
    if (report?.id) {
      reportService
        .exportSingle(report.id, buildParams({ export: "json" }))
        .then((response) => setTemplate(response));
    }
  }, [report.id]);

  useEffect(() => {
    if (report?.id) {
      fileService
        .getAll(
          buildParams({
            reportId: report.id,
            orderby: "creationDate:desc",
            limit: 20,
          }),
        )
        .then((response) => {
          setReportFiles(response.files);
        });
    }
  }, [report.id]);

  function HandleExportClick(
    okText: string,
    cancelText: string,
    title: string,
  ) {
    confirm({
      icon: "",
      okText: okText,
      okButtonProps: { type: "primary" },
      cancelText: cancelText,
      title: title,
      onOk() {
        //create the link to download the json file
        const data = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(template),
        )}`;

        //easy way to trigger download popup:
        const link = document.createElement("a");
        link.href = data;
        link.download = `${report.name}-template.itempath.json`;
        link.click();
      },
      onCancel() {},
    });
  }

  // the integrationId placeholder text should change based
  // on the selected export type
  const getPlaceholder = () => {
    switch (selectedApp?.name) {
      case "CSV":
        return t("translation:select_delimiter");
      case "XLSX":
        return t("translation:blank");
      default:
        return "";
    }
  };

  const exportForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={6}>{t("translation:template_description")}</Col>
        <Col span={4}>
          <Button
            onClick={() =>
              HandleExportClick(
                t("translation:download"),
                t("translation:cancel"),
                t("translation:confirm_template_download"),
              )
            }
            size="large"
            block
          >
            {t("translation:download_template")}
          </Button>
        </Col>
        <Col span={3} offset={4}>
          <Form.Item name="exportType">
            <Select
              name="exportType"
              size="large"
              onChange={(value) => {
                setSelectedApp(JSON.parse(value));
              }}
              placeholder={t("translation:select_file_type")}
            >
              {apps.map((c) => (
                <Select.Option key={c.id} value={JSON.stringify(c)}>
                  {c.name}
                </Select.Option>
              ))}{" "}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="integrationId">
            <Select
              style={{
                display: selectedApp?.name !== "CSV" ? "none" : undefined,
              }}
              name="integrationId"
              size="large"
              placeholder={getPlaceholder()}
            >
              {integrations.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton type="primary" size="large" block>
            {t("translation:generate_new_file")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initialFormValues: FormValues = {
    exportType: "",
    integrationId: undefined,
  };
  return (
    <div className="reportExport">
      <div className="export">
        <Formik
          component={exportForm}
          initialValues={initialFormValues}
          validationSchema={reportExportSchema}
          onSubmit={(values, actions) => {
            if (user?.id && report?.id) {
              addFile(
                formatForm(values, user.id, report.id, integrations),
              ).then((response) => {
                actions.setSubmitting(false);
                if (!response?.errors) {
                  actions.resetForm();
                }
              });
            }
          }}
        />
      </div>
      <div className="box" style={{ marginTop: "15px" }}>
        <h1>{t("translation:generated_files")}</h1>
        {reportFiles.map((reportFile) => (
          <File
            file={reportFile}
            removeFile={removeFile}
            updateFile={updateFile}
            key={reportFile.id}
          />
        ))}
      </div>
    </div>
  );
}
function formatForm(
  values: FormValues,
  userId: number | undefined,
  reportId: number | undefined,
  integrations: Integration[],
): FileType {
  let exportType = "CSV";
  if (values.exportType) {
    exportType = JSON.parse(values.exportType).name;
  }
  let integrationId = values.integrationId;
  if (exportType === "XLSX") {
    integrationId = integrations[0].id;
  }

  const ret = {
    exportType,
    reportId,
    userId,
    integrationId,
  };

  return ret;
}
