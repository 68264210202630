import React, { useState, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col, message, Modal, Button } from "antd";
import { Form, Select, SubmitButton } from "formik-antd";
import { hueService } from "/app/src/services";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Connection } from "/app/src/models";

const { confirm } = Modal;

interface FormValues {
  type: string;
  hub: string;
}

export default function NewIntegration({
  connections,
  toggleNewIntegration,
  refresh,
}: {
  connections: Connection[];
  toggleNewIntegration: () => void;
  refresh: () => void;
}) {
  const { t } = useTranslation();
  const [hubs, setHubs] = useState<string[]>([]);
  const [local, setLocal] = useState(false);
  const [connectedHubs, setConnectedHubs] = useState<string[]>([]);
  const { Option } = Select;

  useEffect(() => {
    if (connections) {
      setConnectedHubs(getConnectedIds(connections));
    }
  }, [connections]);

  useEffect(() => {
    //only get local bridges if user is choosing to connect to a local hub
    hueService
      .getAllHubs({})
      .then((response) => {
        if (response?.id) {
          setHubs([response.id]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const resetForm = () => {
    setLocal(!local);
  };

  const newLocalForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row justify="end" gutter={16}>
        <Col span={12}>
          <Form.Item name="type">
            <Select name="type" size="large">
              <Option value="Local">{t("translation:local")}</Option>
              <Option value="Remote">{t("translation:remote")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <SubmitButton type="primary" size="large" block>
            {t("translation:select_access_type")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  const newRemoteForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item name="hub">
            <Select
              name="hub"
              placeholder={t("translation:select_bridge_id")}
              size="large"
            >
              {/* Filter out the hubs that are already connected */}
              {hubs
                .filter((x) => !connectedHubs.includes(x))
                .map((id: string) => (
                  <Option value={id} key={id}>
                    {id}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <SubmitButton type="primary" size="large" block>
            {t("translation:select_bridge")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="box">
      <div className="newIntegration">
        <Row justify="start" gutter={[16, 16]}>
          <Col span={12}>
            <h3>
              {t("translation:add")} {t("translation:new_bridge")}
            </h3>
          </Col>
          {local && (
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={resetForm}
              />
            </Col>
          )}
        </Row>
        {!local ? (
          <Formik
            component={newLocalForm}
            initialValues={{
              type: "Local",
              hub: "",
            }}
            onSubmit={(values, actions) => {
              if (values.type === "Local") {
                setLocal(true);
              } else {
                toggleNewIntegration();
                connectBridge();
              }
              actions.setSubmitting(false);
            }}
          />
        ) : (
          <Formik
            component={newRemoteForm}
            initialValues={{
              type: "Local",
              hub: "",
            }}
            onSubmit={(values, actions) => {
              ShowLocalConfirm(
                t("translation:confirm"),
                t("translation:cancel"),
                t("translation:confirm_bridge_dialogue"),
                t("translation:bridge_connection_success"),
                t("translation:bridge_connection_failure"),
                values,
                actions.setSubmitting,
                refresh,
              ); //connect local bridge
            }}
          />
        )}
      </div>
    </div>
  );
}

function openURL(url: string) {
  if (typeof window !== "undefined") {
    window.open(url);
  }
}

function connectBridge() {
  hueService
    .getURL({})
    .then((response) => {
      if (response.errors) {
        message.error(response.errors[0]);
      } else {
        openURL(response.url);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function getConnectedIds(connections: Connection[]) {
  const result = [];
  for (const i in connections) {
    if (connections[parseInt(i)].type === "Hue Local") {
      const id = connections[parseInt(i)].url as string;
      result.push(id);
    }
  }
  return result;
}

function HandleLocalUserCreateResponse(
  successText: string,
  errorText: string,
  result: { result: string },
) {
  //result will either be "link button not pressed" or
  //the newly created integration
  if (result["result"] !== "link button not pressed") {
    message.success(successText);
  } else {
    message.error(errorText);
  }
}

function ShowLocalConfirm(
  okText: string,
  cancelText: string,
  titleText: string,
  successText: string,
  failureText: string,
  values: FormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  refresh: () => void,
) {
  confirm({
    okText: okText,
    okButtonProps: { danger: true },
    cancelText: cancelText,
    title: titleText,
    onOk() {
      hueService
        .createUser({
          id: values.hub.toString(),
        })
        .then((response) =>
          HandleLocalUserCreateResponse(successText, failureText, response),
        );
    },
    afterClose() {
      setSubmitting(false);
      refresh();
    },
  });
}
