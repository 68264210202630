import React, { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { Report, ReportColumn, Snapshot } from "/app/src/models";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { snapshotSchema } from "/app/src/schemas/snapshotSchema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { snapshotService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import SnapshotForm from "./snapshotForm";

interface FormValues {
  name?: string;
  reportColumnId?: number;
  groupColumnId?: number;
  subGroupColumnId?: number;
  type?: string;
  sortDirection?: string;
  groupRange?: string;
}

/**
 * Formats the form values to match the API schema. Also adds the reportId to the form values.
 */
function formatForm(values: FormValues, reportId: number | undefined) {
  return {
    reportColumnId: values.reportColumnId,
    groupColumnId: values.groupColumnId,
    subGroupColumnId: values.subGroupColumnId,
    name: values.name,
    type: values.type,
    sortDirection: values.sortDirection,
    reportId,
    groupRange: values.groupRange,
  };
}

/**
 * Component for creating a new snapshot.
 */
export default function NewSnapshot({
  report,
  reportColumns,
}: {
  report: Report;
  reportColumns: ReportColumn[];
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: addSnapshot } = useMutation({
    mutationFn: (snapshot: {
      [key: string]: string | number | boolean | Date | undefined;
    }) => {
      return snapshotService.createSingle(snapshot).then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["snapshots", report.id],
        (oldData: { snapshots: Snapshot[] }) => {
          return { snapshots: [...oldData.snapshots, data.snapshot] };
        },
      );
    },
  });

  /**
   * Handles the submission of the form. Calls the addSnapshot function and resets the form.
   */
  const addSnapshotHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      const formattedForm = formatForm(values, report.id);
      await addSnapshot(formattedForm)
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        })
        .finally(() => {
          actions.resetForm();
        });
    },
    [addSnapshot, report.id],
  );

  return (
    <div className="newSnapshot">
      <Formik
        validationSchema={snapshotSchema}
        initialValues={{
          type: "count",
        }}
        onSubmit={addSnapshotHandler}
      >
        <SnapshotForm reportColumns={reportColumns} />
      </Formik>
    </div>
  );
}
