import { useState, useEffect } from "react";
import { Row, Col, Select, Radio } from "antd";
import BarChart from "./charts/barChart";
import LineChart from "./charts/lineChart";

import { snapshotService, reportService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Report } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

import { Snapshot } from "/app/src/models";

export default function SnapshotChart({ report }: { report: Report }) {
  const { t } = useTranslation();
  // Count of Snapshots
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [historicalData, setHistoricalData] = useState(0);
  const [selectedSnapshotId, setSelectedSnapshotId] = useState(
    report.defaultSnapshotId,
  );

  useEffect(() => {
    snapshotService
      .getAll(buildParams({ reportId: report.id }))
      .then((response) => setSnapshots(response.snapshots));
  }, [report.id]);

  return snapshots.length > 0 ? (
    <div className="snapshotChart">
      {selectedSnapshotId &&
        (historicalData ? (
          <LineChart snapshotId={selectedSnapshotId} />
        ) : (
          <BarChart snapshotId={selectedSnapshotId} />
        ))}
      <div className="chartControls">
        <Row justify="start" gutter={16}>
          <Col span={5}>
            <Select
              size="large"
              placeholder={t("translation:pick") + t("translation:_a_chart")}
              defaultValue={selectedSnapshotId}
              className="fullWidth" // skipcq: JS-0394
              onChange={(value) => {
                if (!report?.id) return;
                setSelectedSnapshotId(value);

                reportService.updateSingle(report.id, {
                  defaultSnapshotId: value,
                });
              }}
            >
              {snapshots.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Radio.Group
              defaultValue="0"
              size="large"
              name="timeChart"
              onChange={(e) => {
                setHistoricalData(Number(e.target.value));
              }}
            >
              <Radio.Button value="0">{t("translation:now")}</Radio.Button>
              <Radio.Button value="1">
                {t("translation:over_time")}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </div>
    </div>
  ) : null;
}
