import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Button, Progress, Spin, Skeleton, Col } from "antd";
import { processService } from "/app/src/services";
import SingleOrder from "./singleOrder";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";

export default function ProcessingOrders({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { integrationId, processId } = useParams();
  const [step, setStep] = useState<string>("Waiting");
  const [orders, setOrders] = useState<string[]>([]);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [progress, setProgress] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const returnToOrderList = () => {
    // Remove the Process if we've cancelled it
    setStep("Cancelled");
    if (processId) {
      processService
        .deleteSingle(processId)
        .then((response) => {
          navigate(`/apps/${app.id}/integrations/${integrationId}`);
        })
        .catch((error) => {
          navigate(`/apps/${app.id}/integrations/${integrationId}`);
        });
    }
  };

  useEffect(() => {
    let value = (currentOrder + 1) / orders.length;
    value = value * 100;
    setProgress(value);
  }, [currentOrder, orders]);

  // Poll for PPG process changes. It needs to wait until PowerPick is ready
  useEffect(() => {
    if (
      step !== "ActiveProcessesChanged" &&
      step !== "Finished" &&
      step !== "Cancelled"
    ) {
      const poll = setInterval(() => {
        console.log("Polling..");
        if (processId) {
          processService
            .getSingle(processId)
            .then((response) => {
              console.log(step);
              setStep(response.process.step as string);
              if (
                response.process.step === "ActiveProcessesChanged" ||
                response.process.step === "Finished"
              ) {
                setOrders(response.process.orders);
                clearInterval(poll);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, 2000);
      return () => {
        clearInterval(poll);
      };
    }
  }, [step, app, processId]);

  switch (step) {
    default:
      return null;
    case "Finished":
      return (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {t("translation:processing_no_lines_available")}
              </h3>
              <Button
                block
                size="large"
                type="primary"
                danger
                onClick={returnToOrderList}
              >
                {t("translation:cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      );
    case "NewProcessInitialized":
      return (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {t("translation:processing_waiting_for_ppg")}...
              </h3>
              <Spin>
                <Skeleton title={false} paragraph={{ rows: 5, width: 100 }} />
              </Spin>
              <Button
                block
                size="large"
                type="primary"
                danger
                onClick={returnToOrderList}
              >
                {t("translation:cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      );
    case "ActiveProcessesChanged":
      return (
        <div>
          {orders.map((order, index) => (
            <SingleOrder
              orderId={order}
              currentOrder={currentOrder}
              index={index}
              key={index}
              cancelOrder={returnToOrderList}
              setButtonDisabled={setButtonDisabled}
            />
          ))}
          <Row justify="center">
            <Progress percent={progress} showInfo={false} />
            {t("translation:order")} {currentOrder + 1} / {orders.length}
            {currentOrder === orders.length - 1 ? (
              <Button
                disabled={buttonDisabled}
                className="next"
                type="primary"
                block
                onClick={() => returnToOrderList()}
              >
                {t("translation:finish_processing")}
              </Button>
            ) : (
              <Button
                disabled={buttonDisabled}
                className="next"
                type="primary"
                block
                onClick={() => {
                  setCurrentOrder(currentOrder + 1);
                }}
              >
                {t("translation:go_to_next_order")}
              </Button>
            )}
          </Row>
        </div>
      );
  }
}
