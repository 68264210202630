import React, { useCallback } from "react";
import { accessService } from "/app/src/services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PermissionSwitch from "../permissionSwitch";
import { Access } from "/app/src/models";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Component to render a single access
 * @param access access to be rendered
 **/
export default function SingleAccess({ access }: { access: Access }) {
  const queryClient = useQueryClient();

  const { mutateAsync: accessUpdate } = useMutation({
    mutationFn: (data: Access) =>
      accessService.updateSingle(access.id, data).then(handlePromiseError),
    onSuccess: () => {
      return queryClient.invalidateQueries([
        "modifiedResources",
        access.roleId,
        access.resourceType,
      ]);
    },
  });

  const { mutateAsync: accessCreate } = useMutation({
    mutationFn: (data: Access) =>
      accessService.createSingle(data).then(handlePromiseError),
    onSuccess: () => {
      return queryClient.invalidateQueries([
        "modifiedResources",
        access.roleId,
        access.resourceType,
      ]);
    },
  });

  const handleSwitchChange = useCallback(
    (checked: boolean, actionType: string) => {
      let actionList = access.actionList;
      if (checked) {
        actionList.push(actionType);
      } else {
        if (actionType === "view") {
          actionList = [];
        } else {
          actionList = actionList.filter(
            (action: string) => action !== actionType,
          );
        }
      }
      if (access.id === null) {
        //create new access
        accessCreate({
          roleId: access.roleId,
          resourceId: access.resourceId,
          resourceType: access.resourceType,
          action: actionList.join(","),
        });
      } else {
        accessUpdate({ action: actionList.join(",") });
      }
    },
    [
      access.actionList,
      access.id,
      access.resourceId,
      access.resourceType,
      access.roleId,
      accessCreate,
      accessUpdate,
    ],
  );

  const handleViewClick = useCallback(
    (checked: boolean) => {
      handleSwitchChange(checked, "view");
    },
    [handleSwitchChange],
  );

  const handleEditClick = useCallback(
    (checked: boolean) => {
      handleSwitchChange(checked, "edit");
    },
    [handleSwitchChange],
  );
  const handleDeleteClick = useCallback(
    (checked: boolean) => {
      handleSwitchChange(checked, "delete");
    },
    [handleSwitchChange],
  );

  const checkIsToggled = useCallback(
    (code: string): boolean => {
      return access.actionList.includes(code);
    },
    [access.actionList],
  );

  const checkViewIsToggled = useCallback(() => {
    return checkIsToggled("view");
  }, [checkIsToggled]);
  const checkEditIsToggled = useCallback(() => {
    return checkIsToggled("edit");
  }, [checkIsToggled]);
  const checkDeleteIsToggled = useCallback(() => {
    return checkIsToggled("delete");
  }, [checkIsToggled]);

  return (
    <>
      <div className="align-left">{access.resourceName}</div>
      <div>
        <PermissionSwitch
          handleSwitchChange={handleViewClick}
          isChecked={checkViewIsToggled()}
        />
      </div>
      <div />
      <div>
        <PermissionSwitch
          handleSwitchChange={handleEditClick}
          isChecked={checkEditIsToggled()}
          disabled={!checkViewIsToggled()}
        />
      </div>
      <div>
        <PermissionSwitch
          handleSwitchChange={handleDeleteClick}
          isChecked={checkDeleteIsToggled()}
          disabled={!checkViewIsToggled()}
        />
      </div>
    </>
  );
}
