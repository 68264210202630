import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { DynamicField } from "../models";

/**
 * Get all dynamic fields
 * @param params URLSearchParams
 * @returns Promise<{ dynamic_fields: DynamicField[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ dynamic_fields: DynamicField[] }> {
  return genericGetAll("dynamic_fields", params);
}

/**
 * Get a single dynamic field
 * @param id string
 * @returns Promise<{ dynamic_field: DynamicField }>
 */
function getSingle(id: string): Promise<{ dynamic_field: DynamicField }> {
  return genericGetSingle("dynamic_fields", id);
}

/**
 * Gets the count of dynamic fields
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("dynamic_fields", params);
}

export const dynamicFieldService = {
  getAll,
  getSingle,
  getCount,
};
