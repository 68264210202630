import { makeAPIRequest } from "../helpers/apiCall";

// For getting report data only
function getAll(
  reportId: number | string,
  params: URLSearchParams = null,
): Promise<any> {
  return makeAPIRequest({
    url: `/reports/${reportId}/report_rows`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of report_rows
 * @param reportId Report ID
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(
  reportId: number | string,
  params: URLSearchParams = null,
): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return getAll(reportId, params);
}

export const reportRowService = {
  getAll,
  getCount,
};
