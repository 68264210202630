import React, { useState, useEffect, useCallback } from "react";
import { Button, Row, Col } from "antd";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import {
  updateObjectInStateArray,
  deleteObjectInStateArray,
  addObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import { widgetService, snapshotDataService } from "/app/src/services";
import WidgetArea from "./WidgetArea";
import { WidgetSnapshotDataProvider } from "/app/src/contexts/WidgetSnapshotDataContext";
import { WidgetSnapshotErrorProvider } from "/app/src/contexts/WidgetSnapshotErrorContext";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Widget, View, WidgetDataset, SnapshotData } from "/app/src/models";
import { FormattedWidgetData } from "/app/src/types";
import { buildParams } from "/app/src/helpers/params";

export default function Widgets({
  view,
  editingView,
}: {
  view: View;
  editingView: boolean;
}) {
  const { t } = useTranslation();
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [locked, setLocked] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuthState();
  const [errors, setErrors] = useState<number[]>([]);
  const [snapshotsData, setSnapshotsData] = useState<FormattedWidgetData[]>([]);
  // Update Widget in list
  const updateWidget = (widgetId: number | string, widget: Widget) => {
    return updateObjectInStateArray(
      widgetId,
      widget,
      widgets,
      setWidgets,
      widgetService.updateSingle,
    );
  };

  const deleteWidget = (widgetId: number) => {
    setSnapshotsData(snapshotsData.filter((o) => o.widgetId !== widgetId));
    return deleteObjectInStateArray(
      { id: widgetId },
      widgets,
      setWidgets,
      widgetService.deleteSingle,
    );
  };

  const createNewWidget = () => {
    return addObjectInStateArray(
      { viewId: view.id },
      widgets,
      setWidgets,
      widgetService.createSingle,
    );
  };
  //toggling the movement to on should set editing to false
  const toggleLocked = () => {
    if (locked) {
      setIsEditing(false);
    }
    setLocked(!locked);
  };

  const getSnapshotData = (
    snapshotId: number,
    params: { [key: string]: string | number | boolean | Date | undefined },
  ) => {
    return snapshotDataService.getAll(snapshotId, buildParams(params));
  };

  //toggling editing to on should set the movement to locked
  const toggleIsEditing = () => {
    if (!isEditing) {
      setLocked(true);
    }
    setIsEditing(!isEditing);
  };

  // if the user toggles editing view to false,
  // ensure the widget editing is disabled and movement
  // is locked
  useEffect(() => {
    if (editingView === false) {
      setIsEditing(false);
      setLocked(true);
    }
  }, [editingView, widgets]);

  const getData = useCallback((dataset: WidgetDataset, widget: Widget) => {
    setSnapshotsData((currData) => currData.filter((o) => o.id !== dataset.id));
    if (dataset.snapshotId) {
      snapshotDataService
        .getAll(
          dataset.snapshotId,
          buildParams({
            isHistorical: widget.isHistorical,
          }),
        )
        .then((response) => {
          const newData = formatDataset(dataset, response.snapshot, widget.id);
          //remove widgetId from errors
          setErrors((currErrors) => currErrors.filter((o) => o !== widget.id));
          setSnapshotsData((currData) => [...currData, newData]);
        })
        .catch(() => {
          setErrors((currErrors) => [...currErrors, widget.id]);
        });
    }
  }, []);

  // Get dashboard data
  useEffect(() => {
    if (view?.id) {
      setSnapshotsData([]);
      const getAllWidgetData = async (allWidgets: Widget[]) => {
        for (let i = 0; i < allWidgets.length; i++) {
          const widget = allWidgets[i];
          if (widget?.datasets && widget?.id) {
            for (let x = 0; x < widget?.datasets?.length; x++) {
              const dataset = widget.datasets[x];
              if (dataset.snapshotId) {
                await getSnapshotData(dataset.snapshotId, {
                  isHistorical: widget.isHistorical,
                })
                  .then((response) => {
                    const newData = formatDataset(
                      dataset,
                      response.snapshot,
                      widget.id,
                    );
                    setErrors((currErrors) =>
                      currErrors.filter((o) => o !== widget.id),
                    );

                    setSnapshotsData((currData) => [...currData, newData]);
                  })
                  .catch(() => {
                    setErrors((currErrors) => [...currErrors, widget.id]);
                  });
              }
            }
          }
        }
      };
      widgetService
        .getAll(buildParams({ viewId: view.id }))
        .then((response) => {
          setWidgets(response.widgets);
          getAllWidgetData(response.widgets);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setWidgets([]);
    }
  }, [view.id]);
  return (
    <div className="dashboard" style={{ paddingTop: "15px" }}>
      {editingView && (
        <Row gutter={10} justify="end">
          <Col>
            <Button key={1} type="primary" onClick={createNewWidget}>
              {t("translation:add_new_widget")}
            </Button>
          </Col>
          <Col>
            <Button
              key={2}
              type={locked || isEditing ? "default" : "primary"}
              className="moveButton" // skipcq: JS-0394
              onClick={toggleLocked}
            >
              {locked || isEditing ? (
                <>
                  <LockOutlined /> {t("translation:edit_layout")}
                </>
              ) : (
                <>
                  <UnlockOutlined /> {t("translation:finish_editing")}
                </>
              )}{" "}
            </Button>
          </Col>
          <Col>
            <Button
              key={3}
              type={!isEditing ? "default" : "primary"}
              className="moveButton" // skipcq: JS-0394
              onClick={toggleIsEditing}
            >
              {!isEditing ? (
                <>
                  <LockOutlined /> {t("translation:edit_widgets")}
                </>
              ) : (
                <>
                  <UnlockOutlined /> {t("translation:finish_editing")}
                </>
              )}
            </Button>
          </Col>
        </Row>
      )}
      <WidgetSnapshotErrorProvider value={{ errors }}>
        <WidgetSnapshotDataProvider
          value={{ data: { snapshotsData, getData, setSnapshotsData } }}
        >
          {user?.id && Boolean(widgets) ? (
            <WidgetArea
              widgets={widgets}
              updateWidget={updateWidget}
              deleteWidget={deleteWidget}
              isEditing={isEditing}
              locked={locked}
            />
          ) : null}
        </WidgetSnapshotDataProvider>
      </WidgetSnapshotErrorProvider>
    </div>
  );
}

function formatDataset(
  dataset: WidgetDataset,
  snapshot: SnapshotData,
  widgetId: number | undefined,
): FormattedWidgetData {
  return {
    frequency: snapshot?.trigger?.triggerFrequency,
    name: snapshot.name,
    id: dataset.id,
    colour: dataset.colour,
    data: snapshot["data"],
    reportColumnName: snapshot["reportColumnName"],
    reportColumnId: snapshot["reportColumnId"],
    widgetId: widgetId,
    subGroupColumnName: snapshot["subGroupColumnName"],
    groupColumnName: snapshot["groupColumnName"],
  };
}
