import React from "react";

import Workflow from "../workflow";
import WorkflowList from "./workflowList";
import { Routes, Route } from "react-router-dom";
import Logs from "../workflow/logs";
export default function Workflows() {
  return (
    <Routes>
      <Route path=":workflowId/*" element={<Workflow />} />
      <Route path=":workflowId/logs" element={<Logs />} />
      <Route path="/" element={<WorkflowList />} />
    </Routes>
  );
}
