import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { kitService } from "/app/src/services";

import Search from "../search";
import getOrderByQuery from "/app/src/helpers/table";

import Table from "/app/src/components/generic/tables/table";
import { useSort } from "/app/src/hooks";
import { useTranslation } from "react-i18next";
import { Kit as KitType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { Column } from "react-table";
import { useQuery } from "@tanstack/react-query";

export default function Kits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns: Column<KitType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
    ];
  }, [t]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");

  const goToKit = (kitId: number) => {
    navigate(`/explorer/kits/${kitId}`);
  };

  const { data: kits, isFetching } = useQuery({
    queryKey: ["kits", page, pageSize, searchString, sort],
    queryFn: () => {
      return kitService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { kits: [] },
    select: (data: { kits: KitType[] }) => {
      return data.kits;
    },
  });

  const { data: kitsCount } = useQuery({
    queryKey: ["kitsCount", searchString],
    queryFn: () => {
      return kitService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:kits")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={kits}
        tableColumns={columns}
        length={kitsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        rowClicked={goToKit}
        emptyText={t("translation:no_kits_found")}
      />
    </div>
  );
}
