import { useState, useCallback } from "react";

//src code: https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
export function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);

  return [value, toggle] as const; // https://bobbyhadz.com/blog/typescript-not-all-constituents-of-type-are-callable
}
