import React, { useState } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import { RightSquareOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, Input, SubmitButton } from "formik-antd";

import NewMapping, { formatForm } from "./newMapping";
import { mappingService } from "/app/src/services";
import { ColumnType, TextType } from "./mappingTypes";
import { useTranslation } from "react-i18next";
import { Mapping, ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  key: string | undefined;
  value: string | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: string | undefined;
  parentMappingId: number | undefined;
}

function EditGroupingType({
  mapping,
  updateMapping,
  removeMapping,
  columnTypes,
}: {
  mapping: Mapping;
  updateMapping: (mapping: Mapping) => Promise<any>;
  removeMapping: (mapping: Mapping) => Promise<any> | undefined;
  columnTypes: ReportColumnType[];
}) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const queryClient = useQueryClient();

  const { data: children } = useQuery({
    queryKey: ["mappings", { parentMappingId: mapping.id }],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({ parentMappingId: mapping.id }),
      );
    },
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const handleCircleClick = () => {
    setEditing(true);
  };

  const { mutateAsync: addMappingChild } = useMutation({
    mutationFn: (newMapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService.createSingle(newMapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMappingChild } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMappingChild } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  const onSubmitUpdateMapping = async (
    values: Omit<Mapping, "parentMapping" | "children">,
  ) => {
    if (mapping?.id) {
      await updateMapping({ id: mapping.id, ...values });
    }
  };

  const editGroupingForm: (
    props: FormikProps<{ key: string | undefined }>,
  ) => JSX.Element = ({ dirty }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={2}>{"Group Mapping:"}</Col>
        <Col span={16}>
          <Form.Item name="key" hasFeedback={false}>
            <Input
              size="large"
              name="key"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton type="primary" size="large" block disabled={!dirty}>
            {t("translation:save")}
          </SubmitButton>
        </Col>
        <Col span={3}>
          <Button
            onClick={() => removeMapping(mapping)}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <Formik
        enableReinitialize
        component={editGroupingForm}
        initialValues={{ key: mapping.key }}
        validationSchema={simpleSchemaBuilder([
          { name: "key", type: "string", required: true },
        ])}
        onSubmit={onSubmitUpdateMapping}
      />
      <Row justify="start" gutter={16}>
        <Col span={24}>
          {children?.map((child) => (
            <div key={child.id}>
              <Row justify="start" gutter={16}>
                <Col offset={3} span={1}>
                  <RightSquareOutlined
                    style={{ fontSize: "28px", color: "#82878e" }}
                  />
                </Col>
                <Col span={20}>
                  <EditMapping
                    isThemed={false}
                    columnTypes={columnTypes}
                    mapping={child}
                    removeMapping={removeMappingChild}
                    updateMapping={updateMappingChild}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col offset={3} span={1}>
          <RightSquareOutlined style={{ fontSize: "28px", color: "#82878e" }} />
        </Col>
        <Col span={20}>
          {editing ? (
            <NewMapping
              integrationId={mapping.integrationId}
              addMapping={addMappingChild}
              columnTypes={columnTypes}
              parentId={mapping.id}
            />
          ) : (
            <PlusCircleOutlined
              style={{ fontSize: "28px", color: "#1890ff" }}
              onClick={handleCircleClick}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default function EditMapping({
  isThemed,
  columnTypes,
  mapping,
  updateMapping,
  removeMapping,
}: {
  isThemed: boolean;
  columnTypes: ReportColumnType[];
  mapping: Mapping;
  updateMapping: (mapping: Mapping) => Promise<any>;
  removeMapping: (mapping: Mapping) => Promise<any> | undefined;
}) {
  const updateMappingHandler = async (values: FormValues) => {
    if (mapping?.id) {
      const formattedValues = formatForm(values);
      await updateMapping({ id: mapping.id, ...formattedValues });
    }
  };

  const columnTypeForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form>
      <ColumnType
        isThemed={isThemed}
        columnTypes={columnTypes}
        removeMapping={removeMapping}
        mapping={mapping}
        dirty={dirty}
        isValid={isValid}
      />
    </Form>
  );
  const textTypeForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form>
      <TextType
        removeMapping={removeMapping}
        mapping={mapping}
        dirty={dirty}
        isValid={isValid}
      />
    </Form>
  );

  const getInitialValues = () => {
    let columnType:
      | number
      | [string | undefined, number | undefined]
      | undefined = mapping.columnTypeId;
    if (isThemed) {
      columnType = [mapping.value, mapping.columnTypeId];
    }
    return {
      key: mapping.key,
      value: mapping.value,
      columnTypeId: columnType,
      parentMappingId: mapping.parentMappingId,
      type: mapping.type,
    };
  };
  return (
    <Col span={24}>
      {(function () {
        switch (mapping.type) {
          case "column":
            return (
              <Formik
                enableReinitialize
                component={columnTypeForm}
                initialValues={getInitialValues()}
                validationSchema={simpleSchemaBuilder([
                  { name: "key", type: "string", required: true },
                ])}
                onSubmit={updateMappingHandler}
              />
            );
          case "text":
            return (
              <Formik
                enableReinitialize
                component={textTypeForm}
                initialValues={getInitialValues()}
                validationSchema={simpleSchemaBuilder([
                  { name: "key", type: "string", required: true },
                  { name: "value", type: "string", required: true },
                ])}
                onSubmit={updateMappingHandler}
              />
            );
          case "grouping":
            return (
              <EditGroupingType
                mapping={mapping}
                updateMapping={updateMapping}
                removeMapping={removeMapping}
                columnTypes={columnTypes}
              />
            );
          default:
            break;
        }
      })()}
    </Col>
  );
}
