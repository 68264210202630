import { getUserFromStorage, isTokenExpired } from "./helpers";
import { State, Action } from "./types";
import moment from "moment-timezone";

//If there is any bad data in local storage, use empty data
//for initial data
function getInitialState(): State {
  const user = getUserFromStorage();
  const authState: State = {
    user,
    isAuthenticated: false,
    loading: false,
    errorMessage: null,
    authType: process.env.REACT_APP_AZURE_OAUTH_APPLICATION_ID
      ? "azure"
      : "basic",
  };
  if (user?.accessToken && !isTokenExpired(user.accessToken)) {
    authState.isAuthenticated = true;
  }
  return authState;
}

export const initialState = getInitialState();

/**
 * Reducer for the authentication context. Handles all actions related to authentication
 */
export const AuthReducer = (initialState: State, action: Action): State => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null,
      };
    case "LOGIN_SUCCESS":
      moment.tz.setDefault(action.payload.timeZone);
      return {
        ...initialState,
        user: {
          id: action.payload.id,
          username: action.payload.username,
          refreshToken: action.payload.refreshToken,
          accessToken: action.payload.accessToken,
          isAdmin: Boolean(action.payload.isAdmin),
          roleId: action.payload.roleId,
          timeZone: action.payload.timeZone,
          viewId: action.payload.viewId,
          language: action.payload.language,
        },
        isAuthenticated: true,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: { refreshToken: "" },
        isAuthenticated: false,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    case "REQUEST_REFRESH":
      return {
        ...initialState,
        loading: true,
        errorMessage: null,
      };
    case "REFRESH_SUCCESS":
      return {
        ...initialState,
        loading: false,
        isAuthenticated: true,
        errorMessage: action.error,
        user: {
          ...initialState.user,
          accessToken: action.payload.accessToken,
        },
      };
    case "UPDATE_USER":
      moment.tz.setDefault(action.payload.timeZone);
      return {
        ...initialState,
        loading: false,
        user: {
          ...initialState.user,
          isAdmin: action.payload?.isAdmin,
          roleId: action.payload.roleId,
          timeZone: action.payload.timeZone,
          language: action.payload.language,
          viewId: action.payload.viewId,
        },
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
