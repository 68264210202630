import { NavLink } from "react-router-dom";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function ColumnsButton({
  columnsCount,
}: {
  columnsCount: number;
}) {
  const { t } = useTranslation();
  const { reportId } = useNumberId();

  return (
    <NavLink to={`/reports/${reportId}/columns`}>
      {t("translation:columns")}
      <Badge
        count={columnsCount}
        style={{
          backgroundColor: "#fff",
          color: "#999",
          boxShadow: "0 0 0 1px #d9d9d9 inset",
          marginLeft: "5px",
          marginTop: "-3px",
        }}
      />
    </NavLink>
  );
}
