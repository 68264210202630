import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { LocationContent } from "../models";

/**
 * Get all location contents
 * @param params URLSearchParams
 * @returns Promise<{ location_contents: LocationContent[] }>
 */
function getAll(params: URLSearchParams = null) {
  return genericGetAll("location_contents", params);
}

/**
 * Get a single location content
 * @param id string
 * @returns Promise<{ location_content: LocationContent }>
 */
function getSingle(id: string): Promise<{ location_content: LocationContent }> {
  return genericGetSingle("location_contents", id);
}

/**
 * Gets the count of location contents
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("location_contents", params);
}

export const locationContentService = {
  getAll,
  getSingle,
  getCount,
};
