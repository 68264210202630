import { Formik, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Integration, Setting } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

function formatForm(values: FormValues, integrationId: number): Setting {
  return {
    name: values.name,
    integrationId,
    type: "header",
    value: values.value,
    number: values.number ? 2 : 1,
  };
}
function formatUpdateForm(values: UpdateFormValues): Setting {
  return {
    name: values.name,
    value: values.value,
    number: values.number ? 2 : 1,
  };
}

function EditHeader({
  header,
  removeHeader,
  updateHeader,
}: {
  header: Setting;
  removeHeader: (setting: Setting) => Promise<any> | undefined;
  updateHeader: (setting: Setting) => Promise<any>;
}) {
  const { t } = useTranslation();

  const updateHeaderHandler = async (values: FormValues) => {
    if (header?.id) {
      const formattedForm = formatUpdateForm(values);
      await updateHeader({ id: header.id, ...formattedForm });
    }
  };

  const editHeadingForm: (
    props: FormikProps<UpdateFormValues>,
  ) => JSX.Element = ({ dirty, isSubmitting }) => (
    <Form>
      <Row justify="start" gutter={16}>
        <Col span={2}>{"Header:"}</Col>
        <Col span={5}>
          <Form.Item name="key">
            <Input
              size="large"
              name="name"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <h1 style={{ textAlign: "center" }}>:</h1>
        </Col>
        <Col span={8}>
          <Form.Item name="value">
            <Input
              size="large"
              name="value"
              placeholder={t("translation:enter_value")}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="number" label={t("translation:private")}>
            <Checkbox name="number" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
        <Col span={3}>
          <Button
            onClick={() => removeHeader(header)}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Formik
      component={editHeadingForm}
      enableReinitialize
      initialValues={{
        name: header.name,
        value: header.value,
        number: header.number === 2,
      }}
      validationSchema={simpleSchemaBuilder([
        { name: "name", type: "string", required: true },
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={updateHeaderHandler}
    />
  );
}

interface FormValues {
  name: string;
  value: string;
  number: boolean;
}
interface UpdateFormValues {
  name: string | undefined;
  value: string | undefined;
  number: boolean;
}
export default function Headers({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: addHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService.createSingle(setting).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: [...oldData.settings, response.setting],
          };
        },
      );
    },
  });

  const { mutateAsync: removeHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService.deleteSingle(setting.id).then(() => {
        const settingId = setting.id;
        return { settingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.filter(
              (setting) => setting.id !== response.settingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateHeader } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService
        .updateSingle(setting.id, setting)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", integration.id, "header"],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.map((setting) => {
              if (setting.id === response.setting.id) {
                return response.setting;
              }
              return setting;
            }),
          };
        },
      );
    },
  });

  const { data: settings } = useQuery({
    queryKey: ["settings", integration.id, "header"],
    queryFn: () => {
      return settingService.getAll(
        buildParams({ integrationId: integration.id, type: "header" }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { settings: [] },
    select: (data: { settings: Setting[] }) => {
      return data.settings;
    },
  });

  const addHeaderHandler = async (values: FormValues) => {
    if (integration?.id) {
      const formattedForm = formatForm(values, integration.id);
      await addHeader(formattedForm);
    }
  };

  const createSettingForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={6}>
          <Form.Item
            name="name"
            label={t("translation:key")}
            hasFeedback={false}
          >
            <Input
              name="name"
              placeholder={t("translation:enter_key")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="value"
            label={t("translation:value")}
            hasFeedback={false}
          >
            <Input
              name="value"
              placeholder={t("translation:enter_value")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name="number"
            label={t("translation:private")}
            tooltip={{
              title: t("translation:private_description"),
              icon: <QuestionCircleOutlined />,
            }}
          >
            <Checkbox name="number" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:create_header")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div className="box">
      <h1>{t("translation:headers")}</h1>
      <div className="newIntegration">
        <div>
          {settings.map((setting) => (
            <EditHeader
              header={setting}
              removeHeader={removeHeader}
              updateHeader={updateHeader}
              key={setting.id}
            />
          ))}
        </div>
        <Formik
          initialValues={{
            value: "",
            name: "",
            number: false,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "value", type: "string", required: true },
          ])}
          component={createSettingForm}
          onSubmit={addHeaderHandler}
        />
      </div>
    </div>
  );
}
