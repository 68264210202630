import { isJSON } from "/app/src/helpers";
import { AuthUserType } from "./types";

/**
 * Get the user from local storage
 */
export function getUserFromStorage(): AuthUserType {
  const currentUser = localStorage.getItem("currentUser");

  if (currentUser && isJSON(currentUser)) {
    return JSON.parse(currentUser);
  } else {
    return {};
  }
}

/**
 * Check if the token is expired
 */
export function isTokenExpired(token: string) {
  try {
    return Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;
  } catch (err) {
    return true;
  }
}
