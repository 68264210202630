import React from "react";
import { Workflow } from "/app/src/models";

import Title from "./title";

export default function Controls({ workflow }: { workflow: Workflow }) {
  return (
    <div className="controls">
      <div className="d-flex bd-highlight">
        <Title workflow={workflow} />
      </div>
    </div>
  );
}
