import React, { useEffect, useState } from "react";

import { logService } from "/app/src/services";
import Table from "/app/src/components/generic/tables/table";
import Status from "./status";
import AppText from "./appText";
import { useTranslation } from "react-i18next";
import { useSort } from "/app/src/hooks";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { Log as LogType } from "/app/src/models";

/**
 * Shows the logs for a workflow run
 * @param param0 object containing workflowHistoryId
 */
export default function ActionLogs({
  workflowHistoryId,
}: {
  workflowHistoryId: number | undefined;
}) {
  const { t } = useTranslation();
  const columns = React.useMemo(() => {
    return [
      {
        Header: "",
        accessor: "status",
        Cell: ({ cell: { value } }) => <Status value={value} />,
      },
      {
        Header: t("translation:action"),
        accessor: "appId",
        Cell: ({ cell: { value } }) => <AppText appId={value} />,
      },
      {
        Header: t("translation:description"),
        accessor: "description",
      },
      {
        Header: t("translation:error"),
        accessor: "error",
      },
    ];
  }, [t]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const notFoundText = t("translation:logs_not_found_workflow_history");
  const notSelectedText = t("translation:workflow_record_not_selected");
  const [emptyText, setEmptyText] = useState(notSelectedText);

  useEffect(() => {
    if (workflowHistoryId) {
      setEmptyText(notFoundText);
    }
  }, [workflowHistoryId, notFoundText]);

  const { data: logs, isFetching } = useQuery({
    queryKey: ["logs", page, pageSize, workflowHistoryId, sort],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          limit: pageSize,
          page,
          workflowHistoryId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { logs: [] },
    select: (data: { logs: LogType[] }) => {
      return data.logs;
    },
  });

  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", workflowHistoryId],
    queryFn: () => {
      return logService.getCount(
        buildParams({
          workflowHistoryId,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={emptyText}
      />
    </div>
  );
}
