import { useEffect, useState, useContext } from "react";

import { Formik, FormikProps } from "formik";
import { Row, Col, Select } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import Time from "./time";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { getDateFormat, reverseDateFormat } from "/app/src/helpers/time";
import { TriggerType, Trigger } from "/app/src/models";
import { triggerService, triggerTypeService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { triggerSchema } from "/app/src/schemas/triggerSchema";
import { useQuery } from "@tanstack/react-query";

interface FormValues {
  triggerFrequency: string | undefined;
  triggerTime: string;
  triggerInterval: string | undefined;
  triggerNumber: string[] | string;
  workflowId: number | undefined;
}

export default function Triggers({
  object,
  updateTrigger,
}: {
  object: { id?: number; trigger?: Trigger };
  updateTrigger: (trigger: Trigger) => void;
}) {
  const { t } = useTranslation();
  const [triggerNumbers, setTriggerNumbers] = useState<string | string[]>([]);
  const [selectedTriggerType, setSelectedTriggerType] = useState<string>("");
  const { timeZone } = useContext(TimeZoneContext);

  useEffect(() => {
    //need to convert the trigger type object into json string, which is used by
    //the dropdown for the value prop
    if (!object?.trigger) {
      return;
    }
    if (object.trigger?.triggerType) {
      setSelectedTriggerType(JSON.stringify(object.trigger?.triggerType));
    }

    //trigger numbers track which weekdays the weekly trigger occurs -
    //need to split from a space-separated list into an array
    if (
      object.trigger?.triggerFrequency === "week" &&
      object.trigger?.triggerNumber
    ) {
      setTriggerNumbers(object.trigger.triggerNumber.split(" "));
    } else {
      setTriggerNumbers(object.trigger.triggerNumber);
    }
  }, [object]);

  const handleTypeChange = (value: string) => {
    setSelectedTriggerType(value);
  };

  const { data: triggerTypes } = useQuery({
    queryKey: ["triggerTypes"],
    queryFn: () => {
      return triggerTypeService.getAll();
    },
    initialData: { trigger_types: [] },
    select: (data: { trigger_types: TriggerType[] }) => {
      return data.trigger_types;
    },
  });

  const triggerForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form>
      {selectedTriggerType ? (
        <>
          {JSON.parse(selectedTriggerType).name === "Time" && (
            <Row justify="start" gutter={16}>
              <Col span={3}>
                <span className="blockText">{t("translation:every")}</span>
              </Col>
              <Col span={2}>
                <Form.Item name="triggerInterval">
                  <InputNumber
                    name="triggerInterval"
                    size="large"
                    min={1}
                    max={31}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Time />
              <Col span={3}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  disabled={!dirty || !isValid}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Col>
            </Row>
          )}
          {JSON.parse(selectedTriggerType).name === "Order Processed" && (
            <Row justify="start" gutter={16}>
              <Col span={3} offset={21}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  disabled={!dirty}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Col>
            </Row>
          )}
          {JSON.parse(selectedTriggerType).name === "Order Created" && (
            <Row justify="start" gutter={16}>
              <Col span={3} offset={21}>
                <SubmitButton
                  type="primary"
                  size="large"
                  block
                  disabled={!dirty}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Col>
            </Row>
          )}
        </>
      ) : null}
    </Form>
  );
  return (
    <div className="triggers">
      <h2>{t("translation:trigger")}</h2>
      <Row justify="start" gutter={16}>
        <Col span={4}>
          <Select
            placeholder={t("translation:select_trigger_type")}
            size="large"
            style={{ width: "100%" }}
            value={selectedTriggerType}
            onChange={handleTypeChange}
          >
            {triggerTypes.map((type) => {
              if (type.name === "Time") {
                return (
                  <Select.Option value={JSON.stringify(type)} key={type.id}>
                    {type.name}
                  </Select.Option>
                );
              }
              return null;
            })}
          </Select>
        </Col>
        <Col span={20}>
          <Formik
            component={triggerForm}
            enableReinitialize
            validationSchema={triggerSchema}
            initialValues={{
              triggerFrequency: object.trigger?.triggerFrequency,
              triggerInterval: object.trigger?.triggerInterval,
              triggerTime: getDateFormat(
                object.trigger?.triggerTime,
                "YYYY-MM-DDTHH:mm:ssZ",
                timeZone,
              ),
              triggerNumber: triggerNumbers,
              workflowId: object.id,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(
                object,
                values,
                actions.setSubmitting,
                JSON.parse(selectedTriggerType),
                updateTrigger,
                actions.setFieldError,
                timeZone,
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

function handleSubmit(
  object: { id?: number; trigger?: Trigger },
  values: FormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  selectedTriggerType: TriggerType,
  updateTrigger: (trigger: Trigger) => void,
  setFieldError: (field: string, message: string | undefined) => void,
  timeZone?: string,
) {
  //conditionally update or create trigger
  if (object?.trigger?.id) {
    triggerService
      .updateSingle(
        object.trigger.id,
        formatForm(values, selectedTriggerType, timeZone),
      )
      .then((response) => {
        if (response.errors) {
          handleSubmissionErrors(response.errors, setFieldError);
        } else {
          updateTrigger(response.trigger);
        }
        setSubmitting(false);
      });
  } else {
    triggerService
      .createSingle(formatForm(values, selectedTriggerType))
      .then((response) => {
        if (response.errors) {
          handleSubmissionErrors(response.errors, setFieldError);
        } else {
          updateTrigger(response.trigger);
        }
        setSubmitting(false);
      });
  }
}

function formatForm(
  values: FormValues,
  selectedTriggerType: TriggerType,
  timeZone?: string,
) {
  if (Array.isArray(values.triggerNumber)) {
    values.triggerNumber = values.triggerNumber.join(" ");
  }
  const time = reverseDateFormat(
    values.triggerTime,
    "YYYY-MM-DDTHH:mm:ssZ",
    timeZone,
  );
  return {
    triggerTypeId: selectedTriggerType.id,
    triggerFrequency: values.triggerFrequency,
    triggerInterval: values.triggerInterval,
    triggerTime: time,
    triggerNumber: values.triggerNumber,
    workflowId: values.workflowId,
  };
}
