import React, { useState, useEffect, useContext } from "react";
import { Spin } from "antd";
import _ from "lodash";
import { ResponsiveBar, BarDatum, ComputedDatum } from "@nivo/bar";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";
import { Widget } from "/app/src/models";
import {
  FormattedWidgetData,
  HistoricalData,
  CurrentData,
} from "/app/src/types";
import { settingService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { CartesianMarkerProps, DatumValue } from "@nivo/core";
import { calculateHighestYValue } from "../helpers";

export default function DashboardBarChart({ widget }: { widget: Widget }) {
  const [chartData, setChartData] = useState<BarDatum[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [index, setIndex] = useState<string>();
  const [subGrouped, setSubGrouped] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [trendLines, setTrendLines] = useState<
    CartesianMarkerProps<DatumValue>[]
  >([]);
  const [maxY, setMaxY] = useState<number>(undefined);
  const [margins, setMargins] = useState<{
    top: number;
    right: number;
    bottom: number;
    left: number;
  }>({ top: 5, right: 10, bottom: 85, left: 50 });
  const { data } = useContext(WidgetSnapshotDataContext);

  const addToData = (newData: BarDatum[], i: string) => {
    setChartData(newData);
    setIndex(i);
  };
  useEffect(() => {
    if (subGrouped) {
      setMargins({ top: 5, right: 100, bottom: 85, left: 50 });
    }
  }, [subGrouped]);

  useEffect(() => {
    if (widget.size === 1) {
      setMargins({ top: 5, right: 10, bottom: 85, left: 10 });
    }
  }, [widget.size]);
  useEffect(() => {
    let max = 0;
    const datasets: FormattedWidgetData[] = [];
    if (data && widget.datasets) {
      // get the datasets for the widget this barchart belongs to
      widget.datasets.forEach((d) => {
        const dataset = data.snapshotsData.find((x) => x.id === d.id);
        if (dataset !== undefined) {
          datasets.push(dataset);
        }
      });
      // pass this widget's datasets to the helper function to calculate the highest y value
      max = calculateHighestYValue(datasets, trendLines);
      setMaxY(max + 10); //offset to avoid trendline title being cut off
    }
  }, [data, trendLines, widget.datasets]);

  useEffect(() => {
    settingService
      .getAll(buildParams({ type: "trendline", widgetId: widget.id }))
      .then((response) => {
        const trends: CartesianMarkerProps<DatumValue>[] = [];

        response.settings.forEach((trendLine) => {
          const color = JSON.parse(trendLine.value).color;
          const number: number = JSON.parse(trendLine.value).value;
          trends.push({
            axis: "y",
            value: Number(number),
            lineStyle: { stroke: color, strokeWidth: 2 },
            legend: trendLine.name,
            legendOrientation: "horizontal",
            textStyle: { fontSize: 10 },
          });
        });
        setTrendLines(trends);
      });
  }, [widget.id]);

  useEffect(() => {
    const data_keys: string[] = [];
    let found = true;
    let allData: BarDatum[] = [];
    if (widget.datasets) {
      widget.datasets.forEach((d) => {
        const dataset = data.snapshotsData.find((x) => x.id === d.id);
        if (dataset !== undefined) {
          if (dataset.subGroupColumnName) {
            setSubGrouped(true);
            const subGroupKeys = calculateSubGroupKeys(
              dataset.data as CurrentData[]
            );
            subGroupKeys.forEach((key) => {
              data_keys.push(key);
            });
            allData = formatSubGroupedData(allData, dataset.data, dataset);
          } else {
            allData = formatData(allData, dataset.data, dataset);
            data_keys.push(dataset.name as string);
          }
          addToData(allData, dataset.reportColumnName as string);
          setKeys(data_keys);
        } else {
          found = false;
        }
      });
    }
    setLoading(!found);
  }, [widget.datasets, data.snapshotsData]);

  if (loading) {
    return <Spin />;
  } else {
    return (
      <div className="chart">
        <ResponsiveBar
          data={chartData}
          keys={keys}
          colors={subGrouped ? { scheme: "nivo" } : getColor}
          groupMode="grouped"
          legends={
            subGrouped
              ? [
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    symbolSize: 20,
                  },
                ]
              : []
          }
          indexBy={index}
          enableGridY={true}
          gridYValues={[0]}
          padding={0.3}
          margin={margins}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          maxValue={maxY && maxY}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: -35,
          }}
          markers={trendLines}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: "10px",
                },
              },
            },
          }}
        />
      </div>
    );
  }
}

function formatData(
  allData: BarDatum[],
  newData: HistoricalData | CurrentData[] | undefined,
  dataset: FormattedWidgetData
): BarDatum[] {
  const index = dataset.reportColumnName as string;

  if (Array.isArray(newData)) {
    newData.forEach((item) => {
      const dataSetName = dataset.name as string;
      const match = _.find(allData, _.matchesProperty(index, item["name"]));
      if (match && dataset?.name) {
        match[dataSetName] = item["value"];
        const colorKey = `${dataSetName}colour`;
        match[colorKey] = dataset.colour;
      } else {
        const group: BarDatum = {};

        const colorKey = `${dataSetName}colour`;
        group[colorKey] = dataset.colour;
        group[index] = item["name"];
        group[dataSetName] = item["value"] as number;
        allData.push(group);
      }
    });
  }
  return allData;
}

/**
 * Formats the data for a subGrouped bar chart
 * @param allData data to be added to
 * @param newData the new data that needs to be formatted and added
 * @param dataset dataset that the data belongs to
 * @returns
 */
function formatSubGroupedData(
  allData: BarDatum[],
  newData: HistoricalData | CurrentData[] | undefined,
  dataset: FormattedWidgetData
): BarDatum[] {
  const index = dataset.reportColumnName as string;

  if (Array.isArray(newData)) {
    newData.forEach((item) => {
      //check to see if there is a subGroupColumnName

      const group: BarDatum = {};
      group[index] = item["name"];
      for (const property of Object.keys(
        item["value"] as { [key: string]: number }
      )) {
        group[property] = item["value"][property];
      }
      allData.push(group);
    });
  }
  return allData;
}

/**
 * getColor - gets the colour of the passed in bar
 * @param bar the bar to get the colour for
 * @returns colour of the bar
 */
function getColor(bar: ComputedDatum<BarDatum>) {
  const colorKey = `${bar.id}colour`;
  return bar.data[colorKey] as string;
}

/**
 * Goes through all the data and gets list of all the sub group keys
 * @param data the data to calculate the sub group keys for
 * @returns string array of the sub group keys
 */
function calculateSubGroupKeys(data: CurrentData[]) {
  const subGroupKeys: string[] = [];
  data.forEach((item) => {
    for (const property in item["value"] as { [key: string]: number }) {
      if (!subGroupKeys.includes(property)) {
        subGroupKeys.push(property);
      }
    }
  });
  return subGroupKeys;
}
