import { genericGetSingle, genericGetAll } from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("storage_rules", params);
}

function getSingle(id: string) {
  return genericGetSingle("storage_rules", id);
}

/**
 * Gets the count of storage rules
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("storage_rules", params);
}

export const storageRuleService = {
  getAll,
  getSingle,
  getCount,
};
