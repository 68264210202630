import React from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { Modal, Button } from "antd";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function DeleteForm({
  workflowId,
}: {
  workflowId: number | undefined;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      danger
      block
      onClick={() =>
        ShowConfirm(
          t("translation:delete"),
          t("translation:do_not_delete"),
          t("translation:confirm_delete_workflow"),
          t("translation:delete_workflows_tip"),
          workflowId,
          navigate,
        )
      }
      style={{ marginBottom: "20px" }}
    >
      {t("translation:delete")} {t("translation:workflow")}
    </Button>
  );
}

function ShowConfirm(
  okText: string,
  cancelText: string,
  title: string,
  content: string,
  workflowId: number | undefined,
  navigate: NavigateFunction,
) {
  confirm({
    okText: okText,
    okButtonProps: { danger: true },
    cancelText: cancelText,
    title: title,
    content: content,
    onOk() {
      if (workflowId) {
        workflowService.deleteSingle(workflowId).then(() => {
          navigate("/workflows");
        });
      }
    },
    onCancel() {},
  });
}
