import { useState, useEffect } from "react";
import { Row, Col, Progress } from "antd";
import "./_status.scss";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { statusService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { delay } from "/app/src/helpers/delay";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StatusType } from "/app/src/contexts/types";

/**
 *
 * @returns JSX.Element
 *
 */
export default function Restart() {
  const { message } = useStatusContext();
  const [percent, setPercent] = useState(0);
  const [connected, setConnected] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Interval for progress bar
   * @returns void
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (connected) {
        setPercent(100);
        delay(1000).then(() => {
          queryClient.setQueryData(["status"], (oldData: StatusType) => {
            return {
              ...oldData,
              message: "active",
            };
          });
        });
      }
      setPercent((prevPercent) => {
        if (prevPercent === 95 && !connected) {
          return prevPercent;
        }
        if (prevPercent < 100) {
          return prevPercent + 1;
        }
        return prevPercent;
      });
    }, 100);
    return () => clearInterval(interval);
  }, [connected, queryClient]);

  /**
   * Check if server is connected
   * @returns void
   */
  useEffect(() => {
    delay(1000).then(
      () => {
        statusService.getStatus().then(() => {
          setConnected(true);
        });
      },
      () => {
        // empty because it handles unhandled promise
      },
    );
  }, []);

  /**
   * Checks if message is active
   */
  useEffect(() => {
    if (message === "active") {
      navigate("/status");
    }
  }, [message, navigate]);

  return (
    <Row className="centered-container" justify="center" align="middle">
      <Col>
        <Progress
          className="progress-bar"
          percent={percent}
          status={connected ? "success" : "active"}
          showInfo={false}
        />
        <p className="restart-title">
          {t("translation:restart_itempath_title")}
        </p>
        <p className="restart-message">
          {t("translation:restart_itempath_message")}
        </p>
      </Col>
    </Row>
  );
}
