import { Permission } from "../models";
import { PermissionState } from "../contexts/types";
import { PermissionCode, PermissionLevel } from "../types";

/**
 *
 * @param permissions list of Permissions that role has
 * @returns object with keys of permission codes and values of objects with keys of
 * permission levels and values of true or false (e.g. {"REPO": {"VIEW": true}})
 */
export function permissionsToDict(permissions: Permission[]): PermissionState {
  //loop through permissions and get array of codes
  const codes = permissions.map((permission) => permission.code);

  const num_to_level = {
    "000": "ALL",
    "001": "VIEW",
    "002": "CREATE",
    "003": "EDIT",
    "004": "DELETE",
    "005": "TEST",
    "006": "MISC",
  };

  const code_dict: PermissionState = {
    permissions: {
      ALLP: {},
      REPO: {},
      WORK: {},
      FILE: {},
      SETT: {},
      ROLE: {},
      USER: {},
      VIEW: {},
      LOGS: {},
      CONN: {},
      PPGS: {},
      EXPO: {},
      EXPL: {},
      DASH: {},
    },
  };
  for (const code of codes) {
    const key = code.slice(0, 4);
    const num = code.slice(-3);
    //for each permission, convert the level number to a string and set it to true
    code_dict.permissions[key][num_to_level[num]] = true;
  }

  return code_dict;
}

/**
 * Helper function to check if a role has a permission
 * @param permissionState Current role's permissions
 * @param code Permission code to check
 * @param level Permission level to check
 * @returns boolean indicating whether the role has the permission
 */
export function checkForPermissionLevel(
  permissionState: PermissionState,
  code: PermissionCode,
  level: PermissionLevel,
): boolean {
  const { permissions } = permissionState;
  return level in permissions[code];
}
