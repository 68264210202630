import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { orderService, workOrderLineService } from "/app/src/services";
import Material from "./material";
import { convertDirectionType } from "/app/src/helpers/converters";
import { useTranslation } from "react-i18next";
import { Order, WorkOrderLine } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

type Line = {
  serialNumber?: number | string;
  locationId?: string;
  locationName?: string;
  materialName?: string;
};

export default function SingleOrder({
  orderId,
  currentOrder,
  index,
  cancelOrder,
  setButtonDisabled,
}: {
  orderId: string;
  currentOrder: number;
  index: number;
  cancelOrder: () => void;
  setButtonDisabled: (disabled: boolean) => void;
}) {
  const { t } = useTranslation();
  const [orderLines, setOrderLines] = useState<{ [key: string]: Line[] }>({});

  //for tracking which material is being processed - for automatically opening correct collapse
  const [activeMaterial, setActiveMaterial] = useState(0);
  const [order, setOrder] = useState<Order>({});

  const nextMaterial = useCallback(() => {
    setActiveMaterial((a) => a + 1);
  }, []);

  useEffect(() => {
    //active material is 0-based index
    if (activeMaterial >= Object.keys(orderLines).length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [activeMaterial, orderLines, setButtonDisabled]);

  const changeMaterial = (index: number) => {
    setActiveMaterial(index);
  };
  const groupOrderlines = (lines: Line[]) => {
    //dictionary where the keys are the material names, and the values
    //are an array of the serial numbers
    const groupedByMaterial: { [key: string]: Line[] } = {};
    lines.forEach((line) => {
      //check if the key exists, if not add it
      if (line?.materialName && !(line?.materialName in groupedByMaterial)) {
        groupedByMaterial[line.materialName] = [
          {
            serialNumber: parseInt(line.serialNumber as string),
            locationId: line.locationId,
            locationName: line.locationName,
          },
        ];
      } else {
        groupedByMaterial[line.materialName as string].push({
          serialNumber: parseInt(line.serialNumber as string),
          locationId: line.locationId,
          locationName: line.locationName,
        });
      }
    });
    //now that they are grouped by material, we need to group by locationId
    return groupedByMaterial;
  };

  useEffect(() => {
    orderService
      .getSingle(orderId)
      .then((response) => {
        //change the direction type from number to string
        response.order.directionType = convertDirectionType(
          response.order.directionType,
        );
        const tempOrder = response.order;
        workOrderLineService
          .getAll(buildParams({ orderId: orderId, limit: 500 }))
          .then((response) => {
            response.work_order_lines.forEach(
              (workOrderLine: WorkOrderLine) => {
                for (
                  let i = 0, length = tempOrder.order_lines.length;
                  i < length;
                  i++
                ) {
                  if (
                    tempOrder.order_lines[i]["id"] === workOrderLine.orderLineId
                  ) {
                    tempOrder.order_lines[i]["locationId"] =
                      workOrderLine.locationId;
                    tempOrder.order_lines[i]["locationName"] =
                      workOrderLine.locationName;
                  }
                }
              },
            );
            setOrder(tempOrder);
            setOrderLines(groupOrderlines(tempOrder.order_lines));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [orderId]);

  if (currentOrder === index) {
    return (
      <div>
        <Row className="order" align="middle" gutter={[0, 20]}>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 18, order: 1 }}
            lg={{ span: 20, order: 1 }}
          >
            <div className="title">
              <Row>
                <Col
                  xs={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 1 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  {t("translation:order_name")}
                  <h3 className="wrapText">{order.name}</h3>
                </Col>
                <Col
                  xs={{ span: 12, order: 3 }}
                  sm={{ span: 12, order: 3 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  {t("translation:direction")}
                  <h3>{order.directionType}</h3>
                </Col>
                <Col
                  xs={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 2 }}
                  md={{ span: 5 }}
                  lg={{ span: 5 }}
                >
                  {t("translation:type")}
                  <h3 className="wrapText">{order.Info5}</h3>
                </Col>
                <Col
                  xs={{ span: 12, order: 4 }}
                  sm={{ span: 12, order: 4 }}
                  md={{ span: 5 }}
                  lg={{ span: 5 }}
                >
                  {t("translation:part")}
                  <h3 className="wrapText">{order.Info1}</h3>
                </Col>
                <Col
                  xs={{ span: 14, order: 5 }}
                  sm={{ span: 14, order: 5 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  Total
                  {order.order_lines ? (
                    <h3 className="wrapText">{order.order_lines.length}</h3>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 4, offset: 2, order: 2 }}
            lg={{ span: 2, offset: 2, order: 2 }}
          >
            <Button
              block
              size="large"
              type="primary"
              danger
              onClick={cancelOrder}
              disabled={activeMaterial >= Object.keys(orderLines).length}
            >
              {t("translation:cancel")}
            </Button>
          </Col>
        </Row>
        <Row className="material">
          {Object.entries(orderLines).map(([key, value], index) => (
            <Material
              key={key}
              changeMaterial={changeMaterial}
              material={key}
              index={index}
              serialNumbers={value}
              activeMaterial={activeMaterial}
              nextMaterial={nextMaterial}
            />
          ))}
        </Row>
      </div>
    );
  } else {
    return null;
  }
}
