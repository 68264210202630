import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { appService } from "/app/src/services";
import { App as AppType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

export default function Apps() {
  const [apps, setApps] = useState<AppType[]>([]);

  useEffect(() => {
    appService
      .getAll(buildParams({ name: "[or]Philips Hue;Variance;Epicor" }))
      .then((response) => setApps(response.apps))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Row gutter={[24, 24]}>
      {apps.map((app) => {
        const name = app.name as string;
        const appSelector = `${name.replace(" ", "_").toLowerCase()}_app`;

        return (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            key={app.id}
          >
            <div className="appBox" id={appSelector}>
              <div className="icon" />
              <div className="info">
                <h3>
                  <Link to={`/settings/apps/${app.id}`}>{app.name}</Link>
                </h3>
                {app.description}
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
