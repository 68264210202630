import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Integration } from "../models";
import { Error } from "../types";

/**
 * Get all integrations
 * @param params URLSearchParams
 * @returns Promise<{ integrations: Integration[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ integrations: Integration[] }> {
  return genericGetAll("integrations", params);
}

/**
 * Get a single integration
 * @param id number | string
 * @returns Promise<{ integration: Integration }>
 */
function getSingle(id: number | string) {
  return genericGetSingle("integrations", id);
}

/**
 * Update a single integration
 * @param id: number | string
 * @param integration { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ integration: Integration } | Error>
 */
function updateSingle(
  id: number | string,
  integration: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ integration: Integration } | Error> {
  return genericUpdateSingle("integrations", id, integration);
}

/**
 * Create a single integration
 * @param integration { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ integration: Integration }>
 */
function createSingle(integration: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ integration: Integration }> {
  return genericCreateSingle("integrations", integration);
}

/**
 * Delete a single integration
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("integrations", id);
}

/**
 * Test a single integration
 * @param id number | string
 * @param params { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ data: string; received: string; code: number }>
 */
function testIntegration(
  id: number | string,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{
  data?: string;
  received?: string;
  code?: number;
  error?: string;
}> {
  return makeAPIRequest({
    url: `/integrations/${id}/test`,
    body: params,
    method: "post",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of integrations
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("integrations", params);
}

export const integrationService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  testIntegration,
  getCount,
};
