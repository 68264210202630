import {
  makeAPIRequest,
  genericGetSingle,
  genericGetAll,
} from "../helpers/apiCall";
import { Carrier } from "../models";

/**
 * Get all carriers
 * @param params URLSearchParams
 * @returns Promise<{ carriers: Carrier[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ carriers: Carrier[] }> {
  return genericGetAll("carriers", params);
}

/**
 * Get all carriers from a storage unit
 * @param storageUnitId
 * @param params
 * @returns Promise<{ carriers: Carrier[] }>
 */
function getAllFromStorageUnit(
  storageUnitId: string,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{ carriers: Carrier[] }> {
  return makeAPIRequest({
    url: `/storage_units/${storageUnitId}/carriers`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Get a single carrier
 * @param id
 * @returns Promise<{ carrier: Carrier }>
 */
function getSingle(id: string): Promise<{ carrier: Carrier }> {
  return genericGetSingle("carriers", id);
}

/**
 * Gets the count of carriers
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("carriers", params);
}

export const carrierService = {
  getAll,
  getAllFromStorageUnit,
  getSingle,
  getCount,
};
