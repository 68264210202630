import React, { useEffect } from "react";
import { Shelf as ShelfType, Location as LocationType } from "/app/src/models";

type Style = {
  left?: string;
  bottom?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
};

export default function Location({
  location,
  shelf,
}: {
  location: LocationType;
  shelf: ShelfType;
}) {
  const [style, setStyle] = React.useState<Style>({});

  useEffect(() => {
    if (
      location.xCoordinate &&
      location.yCoordinate &&
      location.binWidth &&
      location.binDepth &&
      location.fillLevel &&
      shelf.width &&
      shelf.depth
    ) {
      const left = (location.xCoordinate / shelf.width) * 100;
      const bottom = (location.yCoordinate / shelf.depth) * 100;
      const width = (location.binWidth / shelf.width) * 100;
      const height = (location.binDepth / shelf.depth) * 100;
      let color = "0,128,255";
      const opacity = location.fillLevel / 100;

      if (location.fillLevel === 100) {
        color = "255,0,0";
      }
      setStyle({
        left: `${left}%`,
        bottom: `${bottom}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundColor: `rgb(${color}, ${opacity})`,
      });
    }
  }, [location, shelf]);

  return <div className="location" style={style} />;
}
