import React from "react";
import ColumnArea from "./columnArea";
import NewReportColumnForm from "./newReportColumnForm";
import { ReportColumn, Report as ReportType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportColumnTypeService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";

export default function ReportColumns({
  report,
  columns,
}: {
  report: ReportType;
  columns: ReportColumn[];
}) {
  const reportColumnTypesQuery = useQuery({
    queryKey: ["reportColumnTypes", report.table],
    queryFn: () => {
      return reportColumnTypeService.getAll(
        buildParams({ baseTable: report.table, orderby: "name" })
      );
    },
    placeholderData: { report_column_types: [] },
    enabled: Boolean(report.table),
    select: (data) => data.report_column_types,
    staleTime: 60 * 1000,
  });
  return (
    <div className="reportColumns">
      <ColumnArea columns={columns} reportId={report.id} />
      <div className="newReportColumn">
        <NewReportColumnForm
          report={report}
          reportColumnTypes={reportColumnTypesQuery.data}
        />
      </div>
    </div>
  );
}
