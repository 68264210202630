import React from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import getInitialValues from "./formHelper";
import { handlePromiseError } from "/app/src/helpers/api";
import { connectionPathSchema } from "/app/src/schemas/apps/dataPush/connectionSchema";
interface FormValues {
  value: string | undefined;
}
/**
 * Form for creating and updating the URL path setting
 * @param integration Integration
 */
export default function Path({ integration }: { integration: Integration }) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const pathSettingQuery = useQuery({
    queryKey: ["setting", "path", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "path" }),
      ),
  });

  const { mutateAsync: pathMutation } = useMutation({
    mutationFn: (values: FormValues) => {
      if (pathSettingQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "path",
            value: values.value,
            type: "path",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(pathSettingQuery.data?.settings[0]?.id, {
            value: values.value,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["setting", "path", integration.id], {
        settings: [data.setting],
      });
    },
  });

  const pathHandler = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    await pathMutation(values).then(() => {
      actions.resetForm();
    });
  };

  /**
   * Form for the connection url path setting
   */
  const pathForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={20}>
          <Form.Item
            name="value"
            label={t("translation:path")}
            hasFeedback={false}
          >
            <Input
              name="value"
              placeholder={t("translation:enter_path")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <Formik
      component={pathForm}
      initialValues={{
        value: getInitialValues(pathSettingQuery.data?.settings[0]?.value),
      }}
      validationSchema={connectionPathSchema}
      enableReinitialize
      onSubmit={pathHandler}
    />
  );
}
