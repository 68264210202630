import React from "react";

import { Formik, FormikProps } from "formik";
import { Form, Input, Select } from "formik-antd";
import { Row, Col } from "antd";
import { resetPasswordSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";

interface FormValues {
  issueTo: string;
  qualification: string;
  facility: string;
  notes: string;
}

export default function DynamicFieldsForm({
  formRef,
}: {
  formRef: React.RefObject<FormikProps<FormValues>>;
}) {
  const { Option } = Select;
  const { t } = useTranslation();

  const DynamicFields: (props: FormikProps<FormValues>) => JSX.Element = ({
    errors,
    touched,
    isValid,
    dirty,
  }) => (
    <Form layout="vertical" id="dynamicFields">
      <Row gutter={20}>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
        >
          <Form.Item name="issueTo" label={t("translation:issue_to")}>
            <Input suffix name="issueTo" size="large" />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            name="qualification"
            label={t("translation:qualification")}
          >
            <Select name="qualification" size="large">
              <Option value="INV">{t("translation:inv")}</Option>
              <Option value="INV2">{t("translation:inv")}2</Option>
              <Option value="INV3">{t("translation:inv")}3</Option>
              <Option value="INV4">{t("translation:inv")}4</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item name="facility" label={t("translation:facility")}>
            <Input name="facility" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="notes" label={t("translation:notes")}>
            <Input.TextArea name="notes" size="large" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Row>
      <Col span={24}>
        <div className="order" style={{ borderBottom: "0", borderTop: "0" }}>
          <Formik
            onSubmit={(e) => {
              console.log(e);
            }}
            innerRef={formRef}
            component={DynamicFields}
            validationSchema={resetPasswordSchema}
            initialValues={{
              issueTo: "",
              qualification: "",
              facility: "",
              notes: "",
            }}
          />
        </div>
      </Col>
    </Row>
  );
}
