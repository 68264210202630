import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import ConnectionsStatusContext from "/app/src/contexts/ConnectionsStatusContext";
import DisabledCheck from "../generic/components/disabled";
import { useTranslation } from "react-i18next";
import ReportsAccessWrapper from "../report/reportAccessWrapper";
import PermissionWrapper from "../generic/components/permissions";
import ReportList from "./reportList";

export default function Reports() {
  const { t } = useTranslation();
  const { connectionState } = useContext(ConnectionsStatusContext);
  return (
    <Routes>
      <Route
        path=":reportId/*"
        element={
          <DisabledCheck
            name="Report"
            connections={[connectionState["PowerPick SQL"]]}
            message={`${t(
              "translation:powerpick_database_connection_failed",
            )} ${t("translation:viewing_report_details_disabled")}`}
          >
            <ReportsAccessWrapper />
          </DisabledCheck>
        }
      />
      <Route
        path="/"
        element={
          <PermissionWrapper permissionModel="REPO">
            <ReportList />
          </PermissionWrapper>
        }
      />
    </Routes>
  );
}
