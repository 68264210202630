import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Process } from "../models";
import { Error } from "../types";

/**
 * Get all processes
 * @param params URLSearchParams
 * @returns Promise<{ processes: Process[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ processes: Process[] }> {
  return genericGetAll("processes", params);
}

// For getting order details
/**
 * Get a single process
 * @param id number | string
 * @returns Promise<{ process: Process }>
 */
function getSingle(id: number | string) {
  return genericGetSingle("processes", id);
}

/**
 * Create a single process
 * @param item { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ process: Process }>
 */
function createSingle(item: {
  [key: string]: string | number | boolean | Date | undefined | string[];
}): Promise<{ process: Process }> {
  return genericCreateSingle("processes", item);
}

/**
 *
 * @param id number | string
 * @param item { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ process: Process } | Error>
 */
function updateSingle(
  id: number | string,
  item: {
    [key: string]:
      | string
      | number
      | boolean
      | Date
      | undefined
      | { quantity: number; serialNumber: number }[];
  },
): Promise<{ process: Process } | Error> {
  return genericUpdateSingle("processes", id, item);
}

/**
 * Delete a single process
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(id: number | string): Promise<{ deleted: boolean }> {
  return genericDeleteSingle("processes", id);
}

/**
 * Gets the count of processes
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("processes", params);
}

export const processService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
