import React from "react";
import { WidgetSnapshotDataState } from "./types";

const WidgetSnapshotDataContext = React.createContext<{
  data: WidgetSnapshotDataState;
}>({
  data: { snapshotsData: [], getData: () => {}, setSnapshotsData: () => {} },
});

export const WidgetSnapshotDataProvider = WidgetSnapshotDataContext.Provider;
export const WidgetSnapshotDataConsumer = WidgetSnapshotDataContext.Consumer;

export default WidgetSnapshotDataContext;
