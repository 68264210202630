import React, { useEffect, useState, useContext } from "react";
import NewDataset from "./newDataset";
import Dataset from "./dataset";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";

import { widgetDatasetService } from "/app/src/services";
import {
  deleteObjectInStateArray,
  addObjectInStateArray,
  updateObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import { Widget, WidgetDataset } from "/app/src/models";
import { FormattedWidgetData } from "/app/src/types";
export default function Datasets({ widget }: { widget: Widget }) {
  const [activeForm, setActiveForm] = useState(false);
  const [datasets, setDatasets] = useState<WidgetDataset[]>([]);
  const { data } = useContext(WidgetSnapshotDataContext);

  useEffect(() => {
    if (widget?.id) {
      widgetDatasetService.getAllFromWidget(widget.id).then((response) => {
        setDatasets(response.widget_datasets);
      });
    }
  }, [widget]);

  const updateDataset = (datasetId: number, dataset: WidgetDataset) => {
    data.setSnapshotsData((prevState: FormattedWidgetData[]) =>
      updateDatasetState(prevState, datasetId, dataset)
    );

    return updateObjectInStateArray(
      datasetId,
      dataset,
      datasets,
      setDatasets,
      widgetDatasetService.updateSingle
    );
  };
  const deleteDataset = (dataset: WidgetDataset) => {
    data.setSnapshotsData((currData: FormattedWidgetData[]) => {
      return currData.filter((o) => o.id !== dataset.id);
    });
    return deleteObjectInStateArray(
      dataset,
      datasets,
      setDatasets,
      widgetDatasetService.deleteSingle
    );
  };

  const addDataset = (dataset: WidgetDataset) => {
    return addObjectInStateArray(
      dataset,
      datasets,
      setDatasets,
      widgetDatasetService.createSingle
    );
  };
  const toggleForm = () => {
    setActiveForm(!activeForm);
  };

  return (
    <div className="editDatasets">
      <div className="datasetList">
        {datasets.map((d) => (
          <Dataset
            key={d.id}
            dataset={d}
            updateDataset={updateDataset}
            deleteDataset={deleteDataset}
          />
        ))}
      </div>
      <NewDataset
        widget={widget}
        activeForm={activeForm}
        toggleForm={toggleForm}
        createDataset={addDataset}
        getData={data.getData}
      />
    </div>
  );
}

//update a the dataset found in the state array of datasets
function updateDatasetState(
  currData: FormattedWidgetData[],
  dataSetId: number,
  data: WidgetDataset
) {
  const newState = currData.map((o) => {
    if (o.id === dataSetId) {
      //only change the values matching the keys found in 'data'
      return { ...o, ...data };
    }
    return o;
  });
  return newState;
}
