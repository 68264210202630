import { CartesianMarkerProps, DatumValue } from "@nivo/core";
import { CurrentData, FormattedWidgetData } from "/app/src/types";

/**
 * Calculates the highest Y value for the widget bar chart
 * @param data the widget data
 * @param trendLines trendlines for the widget bar chart
 * @returns the highest Y value
 */
export function calculateHighestYValue(
  datasets: FormattedWidgetData[],
  trendLines: CartesianMarkerProps<DatumValue>[]
) {
  let max = 0;
  datasets.forEach((snapshotData) => {
    const snapData = snapshotData.data as CurrentData[];
    if (snapshotData.subGroupColumnName) {
      snapData.forEach((d) => {
        const values = d.value as { [key: string]: number };
        Object.keys(values).forEach((key) => {
          if (values[key] > max) {
            max = values[key];
          }
        });
      });
      return;
    } else {
      snapData.forEach((d) => {
        if (d.value > max) {
          max = d.value as number;
        }
      });
    }
  });
  trendLines.forEach((trendLine) => {
    if (trendLine.value > max) {
      max = trendLine.value as number;
    }
  });
  return max;
}
