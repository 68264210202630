import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Divider, Col, Row } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Select } from "formik-antd";
import { useQuery } from "@tanstack/react-query";

import { materialPropertyService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { MaterialProperty as MaterialPropertyType } from "/app/src/models";

interface FormValues {
  serialNumberPickType: number | undefined;
  serialNumberPutType: number | undefined;
  isSerialNumberMixed: number | undefined;
  lotPickType: number | undefined;
  lotPutType: number | undefined;
  isLotMixed: number | undefined;
  countingCycle: number | undefined;
  expiryDatePickType: number | undefined;
  expiryDatePutType: number | undefined;
  isExpiryDateMixed: number | undefined;
  productionDatePickType: number | undefined;
  productionDatePutType: number | undefined;
  isProductionDateMixed: number | undefined;
  releaseDatePickType: number | undefined;
  releaseDatePutType: number | undefined;
  isReleaseDateMixed: number | undefined;
}

export default function MaterialProperty() {
  const { t } = useTranslation();
  const { materialPropertyId } = useParams();

  const getOptions = (text: string) => {
    return [
      { value: 0, description: `${text} not allowed.` },
      { value: 1, description: `${text} required.` },
      { value: 2, description: "Required before or during processing" },
      { value: 3, description: "Only allowed during processing." },
    ];
  };

  //Get the Single Material Property from URL
  const { data: materialProperty } = useQuery({
    queryKey: ["materialProperty", materialPropertyId],
    queryFn: () => {
      return materialPropertyService.getSingle(materialPropertyId);
    },
    initialData: { material_property: {} },
    select: (data: { material_property: MaterialPropertyType }) => {
      return data.material_property;
    },
  });

  const materialPropForm: (
    props: FormikProps<FormValues>,
  ) => JSX.Element = () => (
    <Form layout="vertical">
      <div className="box">
        <h1>{materialProperty.name}</h1>
        <p>{materialProperty.description}</p>
        <Divider orientation="left" plain>
          {t("translation:serial_numbers")} {t("translation:and")}{" "}
          {t("translation:lot_numbers")}
        </Divider>
        <Row justify="start" gutter={16}>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:serial_numbers")}: ${t(
                "translation:picks",
              )}`}
              name="serialNumberPickType"
            >
              <Select name="serialNumberPickType" showArrow={true} disabled>
                {getOptions("Serial Numbers").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:serial_numbers")}: ${t(
                "translation:puts",
              )}`}
              name="serialNumberPutType"
            >
              <Select name="serialNumberPutType" showArrow={true} disabled>
                {getOptions("Serial Numbers").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={`${t("translation:serial_number")} ${t(
                "translation:mixed",
              )}`}
              name="isSerialNumberMixed"
            >
              <Select name="isSerialNumberMixed" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item
              label={`${t("translation:lot_numbers")}: ${t(
                "translation:picks",
              )}`}
              name="lotPickType"
            >
              <Select name="lotPickType" showArrow={true} disabled>
                {getOptions("Lot Numbers").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:lot_numbers")}: ${t(
                "translation:puts",
              )}`}
              name="lotPutType"
            >
              <Select name="lotPutType" showArrow={true} disabled>
                {getOptions("Lot Numbers").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("translation:lot_number") + t("translation:mixed")}
              name="isLotMixed"
            >
              <Select name="isLotMixed" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" plain>
          {t("translation:cycle_counting")}
        </Divider>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              label={`${t("translation:cycle_counting")} ${t(
                "translation:enabled",
              )}`}
              name="countingCycle"
            >
              <Select name="countingCycle" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" plain>
          {t("translation:date_properties")}
        </Divider>
        <Row justify="start" gutter={16}>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:expiry_dates")}: ${t(
                "translation:picks",
              )}`}
              name="expiryDatePickType"
            >
              <Select name="expiryDatePickType" showArrow={true} disabled>
                {getOptions("Expiry Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:expiry_dates")}: ${t(
                "translation:puts",
              )}`}
              name="expiryDatePutType"
            >
              <Select name="expiryDatePutType" showArrow={true} disabled>
                {getOptions("Expiry Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={`${t("translation:expiry_date")} ${t(
                "translation:mixed",
              )}`}
              name="isExpiryDateMixed"
            >
              <Select name="isExpiryDateMixed" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:production_dates")}: ${t(
                "translation:picks",
              )}`}
              name="productionDatePickType"
            >
              <Select name="productionDatePickType" showArrow={true} disabled>
                {getOptions("Production Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:production_dates")}: ${t(
                "translation:puts",
              )}`}
              name="productionDatePutType"
            >
              <Select name="productionDatePutType" showArrow={true} disabled>
                {getOptions("Production Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={`${t("translation:production_date")} ${t(
                "translation:mixed",
              )}`}
              name="isProductionDateMixed"
            >
              <Select name="isProductionDateMixed" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:release_dates")}: ${t(
                "translation:picks",
              )}`}
              name="releaseDatePickType"
            >
              <Select name="releaseDatePickType" showArrow={true} disabled>
                {getOptions("Release Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={`${t("translation:release_dates")}: ${t(
                "translation:puts",
              )}`}
              name="releaseDatePutType"
            >
              <Select name="releaseDatePutType" showArrow={true} disabled>
                {getOptions("Release Dates").map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={`${t("translation:release_date")} ${t(
                "translation:mixed",
              )}`}
              name="isReleaseDateMixed"
            >
              <Select name="isReleaseDateMixed" showArrow={true} disabled>
                <Select.Option value={0}>{t("translation:no")}</Select.Option>
                <Select.Option value={1}>{t("translation:yes")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
  return (
    <div className="explorer">
      {materialProperty ? (
        <>
          <Helmet>
            <title>{materialProperty.name}</title>
          </Helmet>
          <div className="materialProperty">
            <Formik
              component={materialPropForm}
              enableReinitialize
              initialValues={{
                serialNumberPickType: materialProperty.serialNumberPickType,
                serialNumberPutType: materialProperty.serialNumberPutType,
                isSerialNumberMixed: materialProperty.isSerialNumberMixed,
                lotPickType: materialProperty.lotPickType,
                lotPutType: materialProperty.lotPutType,
                isLotMixed: materialProperty.isLotMixed,
                countingCycle: materialProperty.countingCycle,
                expiryDatePickType: materialProperty.expiryDatePickType,
                expiryDatePutType: materialProperty.expiryDatePutType,
                isExpiryDateMixed: materialProperty.isExpiryDateMixed,
                productionDatePickType: materialProperty.productionDatePickType,
                productionDatePutType: materialProperty.productionDatePutType,
                isProductionDateMixed: materialProperty.isProductionDateMixed,
                releaseDatePickType: materialProperty.releaseDatePickType,
                releaseDatePutType: materialProperty.releaseDatePutType,
                isReleaseDateMixed: materialProperty.isReleaseDateMixed,
              }}
              onSubmit={(values, actions) => {
                actions.resetForm();
              }}
            />
          </div>
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
