import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { integrationService } from "/app/src/services";
import { Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import Table from "/app/src/components/generic/tables/table";
import Filters from "./filters";
import { useTranslation } from "react-i18next";
import DeleteButtonPopup from "/app/src/components/generic/components/buttons/DeleteButtonPopup";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { Integration } from "/app/src/models";

import { App } from "/app/src/models";
import { Column, Sort, Theme as ThemeType } from "/app/src/types";

// Custom component to render Themes
const Theme = ({ value }: { value: any }) => {
  if (value) {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return <Tag className="theme">{value}</Tag>;
  } else {
    return null;
  }
};

export default function IntegrationsList({
  app,
  themes,
  types = [],
}: {
  app: App;
  themes?: ThemeType[];
  types?: any;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState<Sort>([]);
  const [searchString, setSearchString] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("all");
  const [selectedType, setSelectedType] = useState("all");

  const { data: integrationsCount, refetch: refetchCount } = useQuery({
    queryKey: ["integrations", searchString, app.id],
    queryFn: () => {
      return integrationService.getCount(buildParams({ appId: app.id }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const {
    data: integrations,
    isFetching,
    refetch: refetchIntegrations,
  } = useQuery({
    queryKey: [
      "integrations",
      page,
      pageSize,
      searchString,
      selectedTheme,
      selectedType,
      app.id,
    ],
    queryFn: () => {
      return integrationService.getAll(
        buildParams({
          limit: pageSize,
          page,
          theme: selectedTheme,
          number: selectedType,
          search: searchString,
          appId: app.id,
        }),
      );
    },
    initialData: { integrations: [] },
    select: (data: { integrations: Integration[] }) => {
      return data.integrations;
    },
  });

  const refreshTable = () => {
    refetchCount();
    refetchIntegrations();
  };

  const [columns, setColumns] = useState<Column[]>([
    {
      Header: t("translation:name"),
      accessor: "name",
    },
    {
      Header: t("translation:type"),
      accessor: "number",
    },
    {
      Header: "",
      id: "delete",
      accessor: "id",
      clickable: false,
      Cell: ({ cell: { value } }) => (
        <DeleteButtonPopup
          confirmDeleteText={t("translation:confirm_delete_integration")}
          deleteObjectFn={() => integrationService.deleteSingle(value)}
          refresh={refreshTable}
        />
      ),
    },
  ]);

  const goToIntegration = (integrationId: number | string) => {
    navigate(`/apps/${app.id}/integrations/${integrationId}`);
  };

  useEffect(() => {
    if (themes && columns.length < 3) {
      setColumns((columns) => [
        ...columns,
        {
          Header: t("translation:theme"),
          accessor: "baseTable",
          Cell: ({ cell: { value } }) => <Theme value={value} />,
        },
      ]);
    }
  }, [themes, columns, t]);

  return (
    <div>
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>
      <Row gutter={20}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          lg={{ span: 6, order: 1 }}
        >
          <Filters
            themes={themes}
            setSearchString={setSearchString}
            setSelectedTheme={setSelectedTheme}
            setSelectedType={setSelectedType}
            types={types}
          />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 2 }}
        >
          <Table
            loading={isFetching}
            rows={integrations}
            tableColumns={columns}
            length={integrationsCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize: pageSize,
              setPage: setPage,
              setPageSize: setPageSize,
            }}
            rowClicked={goToIntegration}
            emptyText={t("translation:no_integrations_found")}
          />
        </Col>
      </Row>
    </div>
  );
}
