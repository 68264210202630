import {
  genericGetSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { App } from "../models";
import { Error } from "../types";

/**
 * Get all apps
 * @param params URLSearchParams
 * @returns Promise<{ apps: App[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ apps: App[] }> {
  return genericGetAll("apps", params);
}

/**
 * Get a single app
 * @param id number | string
 * @returns Promise<{ app: App }>
 */
function getSingle(id: number | string): Promise<{ app: App }> {
  return genericGetSingle("apps", id);
}

/**
 * Update a single app
 * @param id number | string
 * @param app { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ app: App } | Error>
 */
function updateSingle(
  id: number | string,
  app: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ app: App } | Error> {
  return genericUpdateSingle("apps", id, app);
}

/**
 * Gets the count of apps
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("apps", params);
}

export const appService = {
  getAll,
  getSingle,
  updateSingle,
  getCount,
};
