import React from "react";
import { Routes, Route } from "react-router-dom";

import IntegrationsList from "/app/src/components/generic/components/integration/integrationsView";
import Controls from "/app/src/components/generic/components/integration/_controls";
import Integration from "./integration";
import { App as AppType } from "/app/src/models";
import { useToggle } from "/app/src/hooks";
import NewIntegrationForm from "./newIntegrationForm";

export default function CycleCountsApp({ app }: { app: AppType }) {
  const [toggled, setToggled] = useToggle();

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Controls
            app={app}
            name="cycle_counts"
            toggled={toggled}
            setToggled={setToggled}
          >
            <NewIntegrationForm app={app} setToggled={setToggled} />
          </Controls>
        }
      >
        <Route
          path="integrations/:integrationId/*"
          element={<Integration app={app} />}
        />
        <Route path="/" element={<IntegrationsList app={app} />} />
      </Route>
    </Routes>
  );
}
