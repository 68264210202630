import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import CurrentOrder from "./orderHeader";
import OrderLines from "./orderLines";

import { orderService } from "/app/src/services";
import { App, Order, OrderLine } from "/app/src/models";

export default function OrderBuilder({ app }: { app: App }) {
  const [order, setOrder] = useState<Order>({});
  const [orderLines, setOrderLines] = useState<OrderLine[]>([]);

  const addLine = (newLine: OrderLine) => {
    setOrderLines((lines) => [...lines, newLine]);
  };

  const updateLineQuantity = (lineId: string, newQuantity: number) => {
    setOrderLines(
      orderLines.map((orderLine) =>
        orderLine.id === lineId
          ? { ...orderLine, quantity: newQuantity }
          : orderLine
      )
    );
  };

  const removeLine = (lineId: string) => {
    setOrderLines((lines) => lines.filter((c) => c.id !== lineId));
  };

  const submitOrder = (
    order: Order,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    console.log(order);

    return orderService
      .createSingle(formatForm(order, orderLines))
      .then(() => setOrderLines([]))
      .catch((error) => {
        setFieldError("name", error.message);
      });
  };

  return (
    <div className="app " id="order_builder">
      <Helmet>
        <title>Order Builder - ItemPath</title>
      </Helmet>
      <Row gutter={20}>
        <Col sm={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
          <OrderLines
            lines={orderLines}
            addLine={addLine}
            updateLineQuantity={updateLineQuantity}
            removeLine={removeLine}
          />
        </Col>
        <Col sm={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
          <CurrentOrder
            order={order}
            submit={submitOrder}
            setOrder={setOrder}
          />
        </Col>
      </Row>
    </div>
  );
}

function formatForm(order: Order, lines: OrderLine[]): Order {
  //
  const formatted = {
    name: order.name,
    directionType: order.type,
    priority: order.priority,
    deadline: order.deadline,
    lines: lines,
  };
  return formatted;
}
