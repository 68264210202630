import React, { useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import {
  useAuthState,
  useAuthDispatch,
  ssoLogin,
} from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useMsal } from "@azure/msal-react";
import msIcon from "/app/src/images/msIcon.svg";
import logo from "/app/src/components/header/logo.svg";

interface LocationType {
  state: {
    from?: string;
  };
}

/**
 * Component for logging in with Azure Single Sign On
 */
export default function SingleSignOn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, license, loading } = useStatusContext();
  const { user } = useAuthState(); //read the values of loading and errorMessage from context
  const { state } = useLocation() as LocationType;
  const dispatch = useAuthDispatch(); //get the dispatch method from the useDispatch custom hook
  const { instance } = useMsal();
  // redirect to home if already logged in
  useEffect(() => {
    if (user?.id && !loading && message !== "loading") {
      if (message === "locked") {
        navigate("/locked");
      } else if (license && license !== "invalid" && license !== "") {
        //If user redirected to login because not signed in,
        //send them back to where redirected from. Otherwise
        //go to the dashboard
        navigate(state?.from ?? "/dashboard");
      }
    }
  }, [user, navigate, state, message, license, loading]);

  const loginHandle = useCallback(() => {
    instance
      .acquireTokenPopup({
        scopes: ["User.Read"],
      })
      .then((response) => {
        instance.setActiveAccount(response.account);
        ssoLogin(dispatch, response.idToken);
      });
  }, [dispatch, instance]);

  return (
    <div>
      <Helmet>
        <title>{t("translation:login")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="ssoLogin">
            <div className="logo">
              <img src={logo} alt="ItemPath" height="38" />
            </div>
            <button className="msButton" onClick={loginHandle}>
              <object type="image/svg+xml" data={msIcon} aria-label="Sign In" />
              <p>Sign in with Microsoft</p>
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
