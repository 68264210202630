import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(workflowId: number | string, params: URLSearchParams = null) {
  return makeAPIRequest({
    url: `/workflows/${workflowId}/workflow_actions`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getSingle(id: number | string) {
  return genericGetSingle("workflow_actions", id);
}

function createSingle(workflowAction: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("workflow_actions", workflowAction);
}

function updateSingle(
  id: number | string,
  workflowAction: {
    [key: string]: string | number | boolean | Date | undefined;
  }
) {
  return genericUpdateSingle("workflow_actions", id, workflowAction);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("workflow_actions", id);
}

/**
 * Gets the count of workflow_actions
 * @param workflowId Workflow ID
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(
  workflowId: number | string,
  params: URLSearchParams = null
): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return getAll(workflowId, params);
}

export const workflowActionService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
