import { useState, useEffect, useContext } from "react";
import { appService } from "/app/src/services";
import ConnectionsStatusContext from "/app/src/contexts/ConnectionsStatusContext";

import OrderBuilder from "../orderBuilder";
import OrderProcessor from "../orderProcessor";
import Epicor from "../epicor";
import Hue from "../hue";
import CycleCounts from "../cycleCounts";
import Variance from "../variance";
import Slack from "../slack";
import Displays from "../displays";
import DisabledCheck from "/app/src/components/generic/components/disabled";
import DataPush from "../dataPush";
import { App } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function SingleApp() {
  const { appId } = useNumberId();
  const [app, setApp] = useState<App>({});
  const { connectionState } = useContext(ConnectionsStatusContext);

  // Get API data
  useEffect(() => {
    if (appId) {
      appService.getSingle(appId).then((response) => setApp(response.app));
    }
  }, [appId]);
  return (
    <>
      {(function () {
        switch (app.name) {
          case "Order Builder":
            return (
              <DisabledCheck
                name="Order Builder"
                message="Connection to Power Pick failed. Order Builder Disabled."
                connections={[
                  connectionState["PowerPick Web Services"],
                  connectionState["PowerPick SQL"],
                ]}
              >
                <OrderBuilder app={app} />
              </DisabledCheck>
            );
          case "Order Processor":
            return (
              <DisabledCheck
                name="Order Processor"
                message="Connection to Power Pick failed. Order Processor Disabled."
                connections={[
                  connectionState["PowerPick Web Services"],
                  connectionState["PowerPick SQL"],
                ]}
              >
                <OrderProcessor app={app} />
              </DisabledCheck>
            );
          case "Epicor":
            return <Epicor app={app} />;
          case "Philips Hue":
            return <Hue app={app} />;
          case "Cycle Counts":
            return <CycleCounts app={app} />;
          case "Variance":
            return <Variance app={app} />;
          case "Slack":
            return <Slack app={app} />;
          case "Displays":
            return <Displays app={app} />;
          case "Data Push":
            return <DataPush app={app} />;
          default:
            return app.name;
        }
      })()}
    </>
  );
}
