import React from "react";
import { Helmet } from "react-helmet";

import "antd/dist/antd.min.css";

import { StatusProvider } from "./contexts/StatusContext";
import { ConnectionsStatusProvider } from "./contexts/ConnectionsStatusContext";
import { TimeZoneProvider } from "./contexts/TimeZoneContext";
import { PermissionProvider } from "./contexts/PermissionContext";
import "./stylesheets/index.scss";
import Header from "./components/header";
import SystemTypeIndicator from "./components/header/systemTypeIndicator";
import StatusMessage from "./components/header/statusMessage";
import Footer from "./components/footer";
import ConnectionTest from "./components/connectionTest";
import Routes from "./routes";
import AppBodyHook from "./appBodyHook";
import ConnectionHeaderStatus from "./components/header/connectionHeaderStatus";
/**
 * App Body conatins status, connection status, and timezone providers, connectionTest,
 * StatusMessage, SystemTypeIndicator, Header, Routes, and Footer
 * @returns The main app body
 */
export default function AppBody() {
  const { statusQuery, powerpickConnectionQuery, timeZone, permissionsQuery } =
    AppBodyHook();
  return (
    <StatusProvider value={{ ...statusQuery.data }}>
      <ConnectionsStatusProvider value={powerpickConnectionQuery.data}>
        <TimeZoneProvider value={{ timeZone }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ItemPath</title>
          </Helmet>
          <ConnectionTest
            status={statusQuery.isError || !statusQuery.data.connected}
          />
          <ConnectionHeaderStatus />
          <StatusMessage />
          <SystemTypeIndicator />
          <div className="itemPath">
            <Header />
            <PermissionProvider
              value={{
                isLoading: permissionsQuery.isFetching,
                ...permissionsQuery.data,
              }}
            >
              <Routes />
            </PermissionProvider>
            <Footer />
          </div>
        </TimeZoneProvider>
      </ConnectionsStatusProvider>
    </StatusProvider>
  );
}
