import { genericGetSingle, genericGetAll } from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("workflow_histories", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("workflow_histories", id);
}

/**
 * Gets the count of workflow histories
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflow_histories", params);
}

export const workflowHistoryService = {
  getAll,
  getSingle,
  getCount,
};
