import { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Switch } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { varianceSchema } from "/app/src/schemas/apps/variance/varianceSchema";

import {
  integrationService,
  settingService,
  connectionService,
} from "/app/src/services";
import {
  Connection,
  Integration,
  Setting as SettingType,
} from "/app/src/models";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string | undefined;
  baseTable: string | undefined;
  connectionId: number | undefined;
}

export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const themes = [
    { name: "Material", value: "Material" },
    { name: "Location Content Breakdown", value: "LocationContentBreakdown" },
  ];

  function formatForm(values: FormValues) {
    return {
      name: values.name,
      connectionId: values.connectionId,
      baseTable: values.baseTable,
    };
  }

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .updateSingle(integration.id, integration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  const updateIntegrationHandler = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (integration?.id) {
      await updateIntegration({
        id: integration.id,
        ...formatForm(values),
      }).finally(() => {
        actions.resetForm();
      });
    }
  };

  const toggleIntegrationStatus = useCallback(
    (value: boolean) => {
      //value is a bool - conver to number
      if (integration.id) {
        updateIntegration({ id: integration.id, status: Number(value) });
      }
    },
    [integration.id, updateIntegration],
  );

  const { data: path } = useQuery({
    queryKey: ["path", integration.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ integrationId: integration.id, type: "path" }))
        .then((response) => {
          return { setting: response.settings[0] };
        });
    },
    initialData: { setting: {} },
    select: (data: { setting: SettingType }) => {
      return data.setting;
    },
  });

  const { data: connections } = useQuery({
    queryKey: ["connections"],
    queryFn: () => {
      return connectionService.getAll();
    },
    initialData: { connections: [] },
    select: (data: { connections: Connection[] }) => {
      return data.connections;
    },
  });

  const { mutateAsync: updatePath } = useMutation({
    mutationFn: (path: SettingType) => {
      return settingService
        .updateSingle(path.id, {
          value: path.value,
        })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["path", integration.id], response);
    },
  });

  const updatePathHandler = async (
    values: { value: string },
    actions: FormikHelpers<{ value: string }>,
  ) => {
    if (path?.id) {
      await updatePath({ id: path.id, value: values.value }).then(() => {
        actions.resetForm();
      });
    }
  };

  const { mutateAsync: createPath } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService
        .createSingle({
          number: 3,
          name: "Path",
          value: values.value,
          type: "path",
          integrationId: integration.id,
        })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["path", integration.id],
        (oldData: { settings: SettingType[] }) => {
          return { settings: [...oldData.settings, response.setting] };
        },
      );
    },
  });

  const createPathHandler = async (
    values: { value: string },
    actions: FormikHelpers<{ value: string }>,
  ) => {
    await createPath(values).then(() => {
      actions.resetForm();
    });
  };

  const updateIntegrationForm: (
    props: FormikProps<FormValues>,
  ) => JSX.Element = ({ dirty, isSubmitting, isValid }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={t("translation:integration_name")}
            hasFeedback={false}
          >
            <Input
              name="name"
              placeholder={t("translation:enter_name")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="baseTable" label={t("translation:theme")}>
            <Select name="baseTable" size="large">
              {themes.map((theme) => (
                <Select.Option key={theme.name} value={theme.value}>
                  {theme.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="connectionId" label={t("translation:connection")}>
            <Select
              name="connectionId"
              size="large"
              placeholder={t("translation:select_connection")}
            >
              {connections.map((c) => (
                <Select.Option value={c.id} key={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting || !isValid}
            style={{ marginTop: "30px" }}
          >
            {t("translation:update")} {t("translation:integration")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  const createPathForm: (
    props: FormikProps<{ value: string | undefined }>,
  ) => JSX.Element = ({ dirty, isSubmitting }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <Form.Item name="value" label={t("translation:path")}>
            <Input
              name="value"
              placeholder={t("translation:enter_path")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initPathValues = {
    value: "" as string | undefined,
  };
  return (
    <>
      <div className="box">
        <Row>
          <Col span={23}>
            <h1>{t("translation:settings")}</h1>
          </Col>
          <Col span={1}>
            <h4>{t("translation:active")}:</h4>
            <Switch
              className="toggleSwitch"
              onChange={toggleIntegrationStatus}
              checked={integration?.status === 1}
            />
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
            baseTable: integration.baseTable,
            connectionId: integration.connectionId,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "connectionId", type: "number", required: true },
          ])}
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </div>
      <div className="box">
        <h1>{"URL Path"}</h1>
        {path ? (
          <Formik
            component={createPathForm}
            initialValues={{
              value: path?.value,
            }}
            validationSchema={varianceSchema}
            enableReinitialize
            onSubmit={updatePathHandler}
          />
        ) : (
          <Formik
            component={createPathForm}
            initialValues={initPathValues}
            enableReinitialize
            onSubmit={createPathHandler}
          />
        )}
      </div>
    </>
  );
}
