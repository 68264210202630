export default function Status(status: number | undefined) {
  switch (status) {
    case 0:
      return "pending";
    case 1:
      return "user";
    case 2:
      return "deactivated";
    case 3:
      return "application";
    default:
      return "";
  }
}
