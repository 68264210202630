import React from "react";
import NewOrderLine from "./newOrderLine";
import OrderLine from "./orderLine";
import { useTranslation } from "react-i18next";
import { OrderLine as Line } from "/app/src/models";

export default function CurrentOrder({
  lines,
  addLine,
  removeLine,
  updateLineQuantity,
}: {
  lines: Line[];
  addLine: (line: Line) => void;
  removeLine: (lineId: string) => void;
  updateLineQuantity: (lineId: string, newQuantity: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="currentOrder box">
      <span>{t("translation:order_lines")}</span>
      {lines.map((line) => (
        <OrderLine
          key={line.id}
          line={line}
          removeLine={removeLine}
          updateLineQuantity={updateLineQuantity}
        />
      ))}
      <NewOrderLine addLine={addLine} />
    </div>
  );
}
