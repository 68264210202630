import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Mapping } from "../models";
import { Error } from "../types";

/**
 * Get all mappings
 * @param params URLSearchParams
 * @returns Promise<{ mappings: Mapping[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ mappings: Mapping[] }> {
  return genericGetAll("mappings", params);
}

/**
 * Get a single mapping
 * @param id number | string
 * @returns Promise<{ mapping: Mapping }>
 */
function getSingle(id: number | string): Promise<{ mapping: Mapping }> {
  return genericGetSingle("mappings", id);
}

/**
 * Get a single mapping
 * @param id number | string
 * @param mapping { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ mapping: Mapping } | Error>
 */
function updateSingle(
  id: number | string,
  mapping: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ mapping: Mapping } | Error> {
  return genericUpdateSingle("mappings", id, mapping);
}

/**
 * Create a single mapping
 * @param mapping { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ mapping: Mapping } | Error>
 */
function createSingle(mapping: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ mapping: Mapping } | Error> {
  return genericCreateSingle("mappings", mapping);
}

/**
 * Delete a single mapping
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("mappings", id);
}

/**
 * Gets the count of mappings
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("mappings", params);
}

export const mappingService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
