import React, { useState, useEffect } from "react";
import { useDebounce } from "/app/src/hooks";
import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, InputNumber } from "formik-antd";
import { materialService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Material, OrderLine } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { newOrderLineSchema } from "/app/src/schemas/apps/orderBuilder/newOrderLineSchema";

export default function NewOrderLine({
  addLine,
}: {
  addLine: (line: OrderLine) => void;
}) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  // API search results
  const [results, setResults] = useState<Material[]>([]);
  // Searching status (whether there is pending API request)
  // const [isSearching, setIsSearching] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        materialService
          .getAll(buildParams({ search: debouncedSearchTerm }))
          .then((response) => {
            setResults(response.materials);
          });
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm], // Only call effect if debounced search term changes
  );

  return (
    <div className="newOrderLine">
      <Formik
        enableReinitialize
        initialValues={{
          quantity: 1,
          materialId: "",
        }}
        validationSchema={newOrderLineSchema}
        onSubmit={(values, actions) => {
          addLine({
            materialId: values.materialId,
            quantity: values.quantity,
          });
          actions.resetForm();
        }}
      >
        <Form layout="horizontal">
          <Row justify="start" gutter={16}>
            <Col span={16}>
              <Form.Item name="materialId">
                <Select
                  name="materialId"
                  size="large"
                  showSearch
                  placeholder={t("translation:find_material")}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  onSearch={(value) => {
                    setSearchTerm(value);
                  }}
                >
                  {results.map((result) => (
                    <Select.Option key={result.id} value={result.id}>
                      {result.name} - {result.Info1}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="quantity" hasFeedback={false}>
                <InputNumber name="quantity" placeholder="" size="large" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton type="primary" size="large" block>
                {t("translation:add")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      </Formik>
    </div>
  );
}
