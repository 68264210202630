import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Material } from "../models";

/**
 * Get all materials
 * @param params URLSearchParams
 * @returns Promise<{ materials: Material[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ materials: Material[] }> {
  return genericGetAll("materials", params);
}

/**
 * Get a single material
 * @param id string
 * @returns Promise<{ material: Material }>
 */
function getSingle(id: string): Promise<{ material: Material }> {
  return genericGetSingle("materials", id);
}

/**
 * Gets the count of materials
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("materials", params);
}

export const materialService = {
  getAll,
  getSingle,
  getCount,
};
