import { Helmet } from "react-helmet";
import StatusPage from "./status";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Restart from "./restart";

/**
 * Component for displaying status page
 * @returns JSX.Element
 *
 */
export default function Status() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("translation:status")} - ItemPath</title>
      </Helmet>
      <Routes>
        <Route element={<StatusPage />} path="/" />
        <Route element={<Restart />} path="restart" />
      </Routes>
    </>
  );
}
