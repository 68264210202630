import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "/app/src/contexts/authentication";
import { useDashboardPermission } from "/app/src/hooks/useDashboardPermission";

// Redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({
  children = null,
}: {
  children?: JSX.Element;
}) {
  const { isAuthenticated } = useAuthState();
  const location = useLocation();
  const { dashboardLocked } = useDashboardPermission();
  if (isAuthenticated) {
    if (dashboardLocked && location.pathname !== "/dashboard") {
      return (
        <Navigate
          to={{
            pathname: "/dashboard",
          }}
          state={{ from: location.pathname }}
        />
      );
    } else {
      return children;
    }
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
        state={{ from: location.pathname }}
      />
    );
  }
}
