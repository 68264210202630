import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Descriptions } from "antd";
import DynamicFields from "/app/src/components/explorer/generic/dynamicFields";

import { orderLineService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { OrderLine as OrderLineType } from "/app/src/models";

export default function OrderLine() {
  const { t } = useTranslation();
  const { orderLineId } = useParams();

  // Get the Single OrderLine from URL
  const { data: orderLine } = useQuery({
    queryKey: ["orderLine", orderLineId],
    queryFn: () => {
      return orderLineService.getSingle(orderLineId);
    },
    initialData: { order_line: {} },
    select: (data: { order_line: OrderLineType }) => {
      return data.order_line;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{`Order Line ${orderLine?.number} - ItemPath`}</title>
      </Helmet>
      <div className="box">
        <h1>{orderLine?.number}</h1>
        <Descriptions title={t("translation:information")} bordered>
          <Descriptions.Item label={t("translation:creation_date")}>
            {orderLine?.modifiedDate}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:status")}>
            {orderLine?.status}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:direction_type")}>
            {orderLine?.directionType}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:quantity")}>
            {orderLine?.quantity}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:quantity_deviated")}>
            {orderLine?.quantityDeviated}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:order_id")}>
            {orderLine?.orderId}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:material_name")}>
            {orderLine?.materialName}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title={t("translation:information_fields")} bordered>
          <Descriptions.Item label={t("translation:info1")}>
            {orderLine?.Info1}
          </Descriptions.Item>

          <Descriptions.Item label={t("translation:info2")}>
            {orderLine?.Info2}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info3")}>
            {orderLine?.Info3}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info4")}>
            {orderLine?.Info4}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info5")}>
            {orderLine?.Info5}
          </Descriptions.Item>
        </Descriptions>

        <DynamicFields object={orderLine} baseTable="Masterorderline" />
      </div>
    </div>
  );
}
