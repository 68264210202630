import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Modal, Divider } from "antd";
import ConvertToLocalTimezone from "/app/src/components/generic/formatting/dateTime";
import { fileService } from "/app/src/services";
import { File as FileType } from "/app/src/models";

const { confirm } = Modal;

type RefType = {
  locationFound: boolean;
  setLocationFound: (value: boolean) => void;
};

export default function File({
  file,
  removeFile,
  updateFile,
}: {
  file: FileType;
  removeFile: (file: FileType) => Promise<any> | undefined;
  updateFile: (file: FileType) => void;
}) {
  const { t } = useTranslation();
  const [locationFound, setLocationFound] = useState(false);

  //refs needed when using setInterval
  const refVisible = useRef<RefType | null>(null);
  refVisible.current = { locationFound, setLocationFound };

  useEffect(() => {
    setLocationFound(Boolean(file.location));
  }, [file]);

  useEffect(() => {
    const id = setInterval(() => {
      if (refVisible?.current && !refVisible.current.locationFound) {
        if (!file.id) return;
        fileService.getSingle(file.id).then((response) => {
          if (response.file.location) {
            updateFile(response.file);
          }
        });
      }
    }, 10000); //10 seconds
    return () => {
      clearInterval(id);
    };
  }, [updateFile, file.id]);
  function HandleDelete(okText: string, cancelText: string, title: string) {
    confirm({
      icon: "",
      okText: okText,
      okButtonProps: { danger: true },
      cancelText: cancelText,
      title: title,
      onOk() {
        removeFile(file);
      },
      onCancel() {},
    });
  }
  function HandleDownloadClick(
    okText: string,
    cancelText: string,
    title: string,
  ) {
    confirm({
      icon: "",
      okText: okText,
      okButtonProps: { type: "primary" },
      cancelText: cancelText,
      title: title,
      onOk() {
        //retrieve file and download popup
        // xlsx files have an octet stream buffer response type
        // axios needs to know the expected response
        // type is different
        let buffer = false;
        if (file.fileType === ".xlsx") {
          buffer = true;
        }
        if (!file.id) return;
        fileService.downloadFile(file.id, buffer).then((response) => {
          //code based off of https://gist.github.com/bierik/0baa0de30cc4ee6d3fbf8485c4d12bb8
          const contentDisposition = response.headers[
            "content-disposition"
          ] as string;
          const contentType = response.headers["content-type"];
          const filename = contentDisposition
            .split("filename=")[1]
            .split(";")[0];
          const file = new Blob([response.data], { type: contentType });
          if ("msSaveOrOpenBlob" in window.navigator) {
            (window.navigator as any).msSaveOrOpenBlob(file, filename);
          }
          // For Firefox and Chrome
          else {
            // Bind blob on disk to ObjectURL
            const data = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.setAttribute("style", "display: none");
            aTag.href = data;
            aTag.download = filename;
            document.body.appendChild(aTag);
            aTag.click();
            // For Firefox
            setTimeout(() => {
              document.body.removeChild(aTag);
              // Release resource on disk after triggering the download
              window.URL.revokeObjectURL(data);
            }, 100);
          }
        });
      },
      onCancel() {},
    });
  }

  return (
    <>
      <Row justify="start" gutter={16}>
        <Col span={16}>
          <h3>
            <ConvertToLocalTimezone
              {...(file.creationDate && { date: file.creationDate })}
            />
          </h3>
          <p>{file.name}</p>
        </Col>
        {file.location && (
          <Col span={4}>
            <Button
              onClick={() =>
                HandleDownloadClick(
                  t("translation:download"),
                  t("translation:cancel"),
                  t("translation:confirm_file_download"),
                )
              }
              size="large"
              block
            >
              {t("translation:download")} {t("translation:file")}
            </Button>
          </Col>
        )}
        {file.location && (
          <Col span={4}>
            <Button
              onClick={() =>
                HandleDelete(
                  t("translation:delete"),
                  t("translation:cancel"),
                  t("translation:confirm_file_delete"),
                )
              } //change the text here
              size="large"
              block
              danger
            >
              {t("translation:delete")} {t("translation:file")}
            </Button>
          </Col>
        )}
        {!file.location && (
          <Col span={4} offset={4}>
            <h4>{t("translation:generating_wait")}</h4>
          </Col>
        )}
      </Row>
      <Divider />
    </>
  );
}
