import React from "react";

const WidgetSnapshotErrorContext = React.createContext<{
  errors: number[];
}>({
  errors: [],
});

export const WidgetSnapshotErrorProvider = WidgetSnapshotErrorContext.Provider;
export const WidgetSnapshotErrorConsumer = WidgetSnapshotErrorContext.Consumer;

export default WidgetSnapshotErrorContext;
