import { makeAPIRequest } from "../helpers/apiCall";

function getAll(id: number | string, params: URLSearchParams = null) {
  return makeAPIRequest({
    url: `/snapshots/${id}/data`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
export const snapshotDataService = {
  getAll,
};
