import React, { useEffect, useState } from "react";

import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { TwitterPicker, ColorResult } from "react-color";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { Setting } from "/app/src/models";
import { settingService } from "/app/src/services";
import { Button, Col, Row } from "antd";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  value?: string;
  name?: string;
  number?: number;
}

/**
 * The Trendline component is used to edit or a delete a trendline
 * @param trendline the trendline to edit
 * @param updateTrendlines the function to update the trendlines
 * @param deleteTrendline the function to delete the trendline
 * @returns the edit trendline form
 */
export default function TrendLine({
  trendLine,
  updateTrendlines,
  deleteTrendline,
}: {
  trendLine: Setting;
  updateTrendlines: (trendLine: Setting) => void;
  deleteTrendline: (trendLine: Setting) => void;
}) {
  const [colourOpen, setColourOpen] = useState(false);

  /**
   * Toggle the colour picker open or closed
   */
  const toggleColour = () => {
    setColourOpen(!colourOpen);
  };
  const [value, setValue] = useState(JSON.parse(trendLine.value));

  useEffect(() => {
    setValue(JSON.parse(trendLine.value));
  }, [trendLine.value]);

  /**
   * Update trendline form
   * @param props the formik props
   * @returns the form
   */
  const trendLineForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    isSubmitting,
    values,
    setFieldValue,
  }) => (
    <Form>
      <Row justify="start" gutter={4}>
        <Col span={12}>
          <Form.Item name="name" hasFeedback={false}>
            <Input name="name" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="number" hasFeedback={false}>
            <Input name="number" />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="colorPicker">
            <button
              type="button"
              className="colorButton"
              onClick={() => toggleColour()}
              style={{ background: values.value }}
            />

            {colourOpen && (
              <div className="popover">
                <TwitterPicker
                  triangle="top-right"
                  color={values.value}
                  onChangeComplete={(color: ColorResult) => {
                    setFieldValue("value", color.hex);
                  }}
                />
              </div>
            )}
          </div>
        </Col>
        <Col span={4} offset={2}>
          <div style={{ float: "right" }}>
            <SubmitButton
              type="primary"
              icon={<CheckOutlined />}
              disabled={!dirty || !isValid || isSubmitting}
            />
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                deleteTrendline(trendLine);
              }}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="trendline">
      <Formik
        initialValues={{
          value: value.color,
          number: value.value,
          name: trendLine.name,
        }}
        enableReinitialize
        component={trendLineForm}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "number", type: "number", required: true },
        ])}
        onSubmit={(values, actions) => {
          setColourOpen(false);
          settingService
            .updateSingle(trendLine.id, formatForm(values))
            .then((response) => {
              updateTrendlines(response.setting);
              actions.setSubmitting(false);
              actions.resetForm();
            });
        }}
      />
    </div>
  );
}

/**
 * Takes the form values and formats them for the API.
 * The value and number are combined into a JSON string.
 * @param values the form values
 * @returns the formatted form values
 */
function formatForm(values: FormValues) {
  const value = { color: values.value, value: values.number };
  return {
    name: values.name,
    value: JSON.stringify(value),
  };
}
