import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

/**
 * Component that displays a message when a user does not have access to a page
 */
export default function LockedAccess() {
  const { t } = useTranslation();
  return (
    <div className="report">
      <Helmet>
        <title>{t("translation:permission_denied")}</title>
      </Helmet>
      {t("translation:no_permission")}
    </div>
  );
}
