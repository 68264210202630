import React from "react";
import { Routes, Route } from "react-router-dom";
import OrdersList from "../generic/ordersList";
import ProcessingOrders from "./processingOrders";
import { App } from "/app/src/models";
export default function BatchSerialNumbers({
  integrationId,
  app,
}: {
  integrationId: number;
  app: App;
}) {
  return (
    <Routes>
      <Route
        path="processes/:processId"
        element={<ProcessingOrders app={app} />}
      />
      <Route
        path="/"
        element={<OrdersList integrationId={integrationId} app={app} />}
      />
    </Routes>
  );
}
