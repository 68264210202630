/**
 * Helper function to convert a csv string to a list of strings
 * @param csv the csv string to convert to a list
 * @returns list of strings
 */
export default function csvToList(csv: string) {
  const lines = csv.split(",");
  for (let i = 0; i < lines.length; i++) {
    lines[i] = lines[i].trim();
  }
  return lines;
}
