import { appService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import { App } from "/app/src/models";

export default function AppText({ appId }: { appId: number | undefined }) {
  const { data: app } = useQuery({
    queryKey: ["app", appId],
    queryFn: () => {
      return appService.getSingle(appId);
    },
    enabled: Boolean(appId),
    initialData: { app: {} },
    select: (data: { app: App }) => {
      return data.app;
    },
  });

  return <p>{app?.name}</p>;
}
