import React, { useState, useEffect } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { reportColumnService } from "/app/src/services";
import { ReportColumn } from "./reportColumn";
import { ReportColumn as ReportColumnType } from "/app/src/models";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function ColumnArea({
  columns,
  reportId,
}: {
  columns: ReportColumnType[];
  reportId: number;
}) {
  const compactType = "vertical";
  const [isMounted, setIsMounted] = useState(false);
  const [layouts, setLayouts] = useState<Layouts>();
  //the report column should take up the whole width, so
  //set react grid layout to only have 1 column, no matter the size
  const cols = { lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 };
  const { canEdit, canDelete } = useAccessContext();
  //only load the grid once the component has mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const queryClient = useQueryClient();

  const { mutateAsync: updateColumn } = useMutation({
    mutationFn: (updatedCol: ReportColumnType) => {
      return reportColumnService
        .updateSingle(updatedCol.id, updatedCol)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["reportColumns", reportId],
        (oldData: { report_columns: ReportColumnType[] }) => {
          return {
            report_columns: oldData.report_columns.map((col) => {
              if (col.id === response.report_column.id) {
                return response.report_column;
              }
              return col;
            }),
          };
        },
      );
    },
  });

  //when the layout changes(a column moves), the columns position column needs
  //to be updated
  const onLayoutChange = (layout: Layout[], layouts: Layouts) => {
    if (layout) {
      setLayouts(layouts);
      //convert the object into array
      const objectArray = Object.entries(layout);
      objectArray.forEach(([key, value]) => {
        const position = value.y;

        //update position for column object in front end
        for (let i = 0; i < columns.length; i++) {
          const col = columns[i];
          let pos = -1;
          if (col?.position) {
            pos = parseInt(col.position);
          }
          const id = parseInt(value.i);

          if (col.id === id && pos !== position) {
            // only update backend if value is different - prevent unnecessary api calls
            updateColumn({
              id: Number(value.i),
              position: position.toString(),
            });
            columns[i].position = position.toString();
          }
        }
      });
    }
  };

  //Go through each column and calculate its position on the grid.
  const generateDOM = () => {
    if (columns) {
      return columns.map((column) => {
        //new columns will go here - to the bottom of the list
        const grid = {
          w: 1,
          h: 1,
          x: 0,
          y: columns.length - 1,
          isResizable: false,
        };
        //the position of the column is stored in the db as the y position. This string
        //needs to be converted into an object with those 4 properties
        if (column?.position) {
          grid["y"] = parseInt(column.position);
        }

        return (
          <div key={column.id} className="reportColumn" data-grid={grid}>
            <ReportColumn
              key={column.id}
              column={column}
              canEdit={canEdit}
              canDelete={canDelete}
              updateColumn={updateColumn}
            />
          </div>
        );
      });
    }
  };

  return (
    <div className="layout">
      <ResponsiveReactGridLayout
        className="layout"
        rowHeight={60}
        onLayoutChange={onLayoutChange}
        cols={cols}
        layouts={layouts}
        measureBeforeMount={false}
        useCSSTransforms={isMounted}
        compactType={compactType}
        preventCollision={!compactType}
        isResizable={false}
        isDraggable={canEdit}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </div>
  );
}
