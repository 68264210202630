import { map } from "lodash";

export function buildParams(data: any) {
  /* 
  This builds a query string from an object, inlcuding parsing arrays.
  
  https://stackoverflow.com/a/62582259
  */

  const params = new URLSearchParams();

  map(data, (value, key) => {
    if (Array.isArray(data[key])) {
      map(value, (item) => params.append(key, item));
    } else {
      params.append(key, value);
    }
  });

  return params;
}
