import editIcon from "/app/src/components/generic/title/editIcon.svg";

const EditButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button className="editButton" onClick={onClick} disabled={disabled}>
      <img src={editIcon} alt="Edit" />
    </button>
  );
};

export default EditButton;
