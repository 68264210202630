import { object, string } from "yup";
import i18next from "/app/src/locales/config";

const urlValidation: RegExp =
  /^(?!.*\/$)(?:https?:\/\/)?(?:www\.)?[\w-]+\.[\w.-]+(?:\/[\w-]*)*$/;

export const varianceSchema = object().shape({
  value: string()
    .url(i18next.t("translation:invalid_url"))
    .matches(urlValidation, i18next.t("translation:url_slash"))
    .trim()
    .required(i18next.t("translation:required")),
});
