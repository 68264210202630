import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function ErrorTip({ tip = "" }) {
  return (
    <Tooltip title={tip}>
      <ExclamationCircleOutlined
        style={{ paddingLeft: "5px", color: "red", paddingTop: "25px" }}
      />
    </Tooltip>
  );
}
