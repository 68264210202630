import React from "react";

import UserList from "./userList";
import Forgot from "./forgot";
import User from "../user";
import Reset from "./reset";
import NewUser from "./newUserForm";
import Role from "../role";
import NewRoleForm from "../roles/newRoleForm";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../links/privateRoute";
import { useAuthState } from "/app/src/contexts/authentication";
export default function Users() {
  const { authType } = useAuthState();
  return (
    <Routes>
      <Route path="forgot/" element={<Forgot />} />
      <Route path="reset/:code/" element={<Reset />} />
      <Route
        path="roles/new"
        element={
          <PrivateRoute>
            <NewRoleForm />
          </PrivateRoute>
        }
      />
      <Route
        path="roles/:roleId"
        element={
          <PrivateRoute>
            <Role />
          </PrivateRoute>
        }
      />
      {authType === "basic" && (
        <Route
          path="new"
          element={
            <PrivateRoute>
              <NewUser />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path=":userId"
        element={
          <PrivateRoute>
            <User />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <UserList />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
