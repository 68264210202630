import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Descriptions } from "antd";
import DynamicFields from "/app/src/components/explorer/generic/dynamicFields";

import { orderService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Order as OrderType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
export default function Order() {
  const { t } = useTranslation();
  const { orderId } = useParams();

  //Get the Single Order from URL
  const { data: order } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => {
      return orderService.getSingle(orderId);
    },
    initialData: { order: {} },
    select: (data: { order: OrderType }) => {
      return data.order;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{order?.name}</title>
      </Helmet>
      <div className="box">
        <h1>{order?.name}</h1>
        <Descriptions title={t("translation:information")} bordered>
          <Descriptions.Item label={t("translation:creation_date")}>
            {order?.creationDate}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:status")}>
            {order?.status}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:owner")}>
            {order?.owner}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:priority")}>
            {order?.priority}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:lines_number")}>
            {order?.order_lines?.length}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title={t("translation:information_fields")} bordered>
          <Descriptions.Item label={t("translation:info1")}>
            {order?.Info1}
          </Descriptions.Item>

          <Descriptions.Item label={t("translation:info2")}>
            {order?.Info2}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info3")}>
            {order?.Info3}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info4")}>
            {order?.Info4}
          </Descriptions.Item>
          <Descriptions.Item label={t("translation:info5")}>
            {order?.Info5}
          </Descriptions.Item>
        </Descriptions>

        <DynamicFields object={order} baseTable="Masterorder" />
      </div>
    </div>
  );
}
