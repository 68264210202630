import {
  genericGetSingle,
  genericGetAll,
  genericDeleteSingle,
  genericUpdateSingle,
  genericCreateSingle,
} from "../helpers/apiCall";
import { Access } from "../models";
import { Error } from "../types";

/**
 * Get all accesses
 * @param params URLSearchParams
 * @returns Promise<{ accesses: Access[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ accesses: Access[] }> {
  return genericGetAll("accesses", params);
}

/**
 * Create a new access
 * @param data Access
 * @returns Promise<{ access: Access }>
 */
function createSingle(data: Access): Promise<{ access: Access } | Error> {
  return genericCreateSingle("accesses", data);
}

/**
 * Get a single access
 * @param id number | string
 * @returns Promise<{ access: Access }>
 */
function getSingle(id: number | string): Promise<{ access: Access }> {
  return genericGetSingle("accesses", id);
}

/**
 * Update a single access
 * @param id number | string
 * @param data Access
 * @returns Promise<{ access: Access }>
 */
function updateSingle(
  id: number | string,
  data: Access,
): Promise<{ access: Access } | Error> {
  return genericUpdateSingle("accesses", id, data);
}

/**
 * Delete a single access
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("accesses", id);
}

/**
 * Gets the count of accesses
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("accesses", params);
}

export const accessService = {
  getAll,
  getSingle,
  deleteSingle,
  updateSingle,
  createSingle,
  getCount,
};
