import React from "react";
import { Link } from "react-router-dom";

import { Col } from "antd";
import { Integration as IntegrationType } from "/app/src/models";

export default function Integration({
  appId,
  integration,
}: {
  appId: number;
  integration: IntegrationType;
}) {
  let integrationSelector = integration.name as string;
  integrationSelector = integrationSelector.replace(" ", "_").toLowerCase();

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
    >
      <div className="appBox" id={integrationSelector}>
        <div className="icon" />
        <div className="info">
          <h3>
            <Link to={`/apps/${appId}/integrations/${integration.id}`}>
              {integration.name}
            </Link>
          </h3>
        </div>
      </div>
    </Col>
  );
}
