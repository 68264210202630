import React from "react";
import { Routes, Route } from "react-router-dom";
import DisplaysList from "./displaysList";
import GenericDisplay from "./customDisplays/genericDisplay";
import { App } from "/app/src/models";

export default function Displays({ app }: { app: App }) {
  return (
    <Routes>
      <Route
        path="displays/:integrationId"
        element={<GenericDisplay app={app} />}
      />
      <Route path="/" element={<DisplaysList app={app} />} />
    </Routes>
  );
}
