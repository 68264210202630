import { useState, useCallback } from "react";

//src code: https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
export function useLoading(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const setLoading = useCallback((func: () => Promise<any>) => {
    setValue(true);
    func().then(() => {
      setValue(false);
    });
  }, []);

  return [value, setLoading] as const; // https://bobbyhadz.com/blog/typescript-not-all-constituents-of-type-are-callable
}
