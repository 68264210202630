import { useFormikContext } from "formik";
import { useEffect } from "react";

/**
 * Function that sets the status in formik context to locked if disabled is true
 * @param disabled: boolean
 */
export default function FormikDisabler({ disabled }: { disabled?: boolean }) {
  const { setStatus } = useFormikContext();
  useEffect(() => {
    if (disabled) {
      setStatus("locked");
    } else {
      setStatus(undefined);
    }
  }, [disabled, setStatus]);
  return null;
}
