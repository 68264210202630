import React, { useState } from "react";

import { logService } from "/app/src/services";
import Table from "/app/src/components/generic/tables/table";
import { Sort } from "/app/src/types";
import { buildParams } from "/app/src/helpers/params";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Log } from "/app/src/models";

/**
 * Shows logs for a task
 * @param param0 object containing taskId, mappings, notFoundText and notSelectedText
 */
export default function LogsTable({
  taskId,
  mappings,
  notFoundText,
  notSelectedText,
}: {
  taskId?: number;
  mappings: { "1": string; "2": string };
  notFoundText: string;
  notSelectedText: string;
}) {
  const { t } = useTranslation();
  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:type"),
        accessor: "status",
        Cell: ({ cell: { value } }) => <p>{status(value, mappings)}</p>,
      },
      {
        Header: t("translation:description"),
        accessor: "description",
      },
      {
        Header: t("translation:errors"),
        accessor: "error",
      },
    ];
  }, [mappings, t]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState<Sort>([]);

  const { data: logs, isFetching } = useQuery({
    queryKey: ["logs", taskId, page, pageSize, sort],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          limit: pageSize,
          page,
          taskId,
        }),
      );
    },
    initialData: { logs: [] },
    select: (data: { logs: Log[] }) => {
      return data.logs;
    },
  });

  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", taskId],
    queryFn: () => {
      return logService.getCount(
        buildParams({
          taskId,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={notSelectedText}
        rowClicked={() => {}}
      />
    </div>
  );
}
function status(
  value: "1" | "2",
  mappings: { "1"?: string; "2"?: string } = {},
) {
  if (value in mappings) {
    return mappings[value];
  }
  return "";
}
