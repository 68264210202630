import React from "react";
import { PermissionState } from "./types";

const default_state: PermissionState = {
  isLoading: true,
  permissions: {
    ALLP: {},
    REPO: {},
    WORK: {},
    FILE: {},
    SETT: {},
    ROLE: {},
    USER: {},
    VIEW: {},
    LOGS: {},
    CONN: {},
    PPGS: {},
    EXPO: {},
    EXPL: {},
    DASH: {},
  },
};

const PermissionContext = React.createContext<PermissionState>(default_state);

export const PermissionProvider = PermissionContext.Provider;
export const PermissionConsumer = PermissionContext.Consumer;

export default PermissionContext;
