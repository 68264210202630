import { makeAPIRequest } from "../helpers/apiCall";
import { Permission } from "../models";

/**
 * Get all permissions for a role
 * @param roleId number
 * @param params URLSearchParams
 * @returns Promise<{ permissions: Permission[] }>
 */
function getRolePermissions(
  roleId: number,
  params: URLSearchParams = null,
): Promise<{ permissions: Permission[] }> {
  return makeAPIRequest({
    url: `/roles/${roleId}/permissions`,
    method: "get",
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Remove a permission from a role
 * @param roleId number
 * @param permissionId number
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function removeRolePermission(
  roleId: number,
  permissionId: number,
): Promise<{ deleted: boolean } | { error: string }> {
  return makeAPIRequest({
    url: `/roles/${roleId}/permissions/${permissionId}/delete`,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Add a permission to a role
 * @param roleId number
 * @param permissionId number
 * @returns Promise<{ permission: Permission }>
 */
function addRolePermission(
  roleId: number,
  permissionId: number,
): Promise<{ permission: Permission }> {
  return makeAPIRequest({
    url: `/roles/${roleId}/permissions/${permissionId}`,
    method: "post",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Get a single permission for a role
 * @param roleId number
 * @param permissionId number
 * @returns Promise<{ permission: Permission }>
 */
function getRolePermission(
  roleId: number,
  permissionId: number,
): Promise<{ permission: Permission }> {
  return makeAPIRequest({
    url: `/roles/${roleId}/permissions/${permissionId}`,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const rolePermissionService = {
  getRolePermissions,
  removeRolePermission,
  addRolePermission,
  getRolePermission,
};
