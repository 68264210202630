import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { StorageUnit as StorageUnitType } from "/app/src/models";
export default function StorageUnit({
  unit,
}: {
  unit: StorageUnitType & { count: number };
}) {
  const { t } = useTranslation();
  return (
    <div className="storageLoad">
      <Row align="middle" gutter={20} className="columnContainer">
        <Col span={12} className="name">
          <h4>{t("translation:storage_unit")}</h4>
          <h2>{unit.name}</h2>
        </Col>
        <Col span={12} className="name">
          <h2>
            {unit.count} {t("translation:order_lines")}
          </h2>
        </Col>
      </Row>
    </div>
  );
}
