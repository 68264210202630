import React, { useContext } from "react";
import { PageHeader, Radio, Select } from "antd";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";
import barIcon from "./barIcon.svg";
import pieIcon from "./pieIcon.svg";
import lineIcon from "./lineIcon.svg";
import numberIcon from "./numberIcon.svg";

import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { Widget } from "/app/src/models";
import Title from "./title";
import Datasets from "./datasets";

import TrendLines from "./trendlines";
import { useTranslation } from "react-i18next";

export default function EditWidget({
  widget,
  updateWidget,
  deleteWidget,
}: {
  widget: Widget;
  updateWidget: (widgetId: number, widget: Widget) => Promise<any>;
  deleteWidget: (widgetId: number) => Promise<any> | undefined;
}) {
  const { data } = useContext(WidgetSnapshotDataContext);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] =
    React.useState<string>("datasets");

  const handleDeleteClick = (): void => {
    if (widget?.id) {
      deleteWidget(widget.id);
    }
  };

  /**
   * Updates the selected option: Either "datasets" or "trendlines"
   * @param value the value of the selected option
   */
  const updateSelectedOption = (value: string) => {
    setSelectedOption(value);
  };
  return (
    <div className="editWidget">
      <PageHeader
        ghost={false}
        title={<Title widget={widget} updateWidget={updateWidget} />}
        extra={<DeleteButton onClick={handleDeleteClick} />}
      />
      <Radio.Group
        defaultValue={widget.isHistorical}
        name="isHistorical"
        onChange={(e) => {
          //When switching from "Now" to "Over Time", the chart type must be changed
          //if the current chart type is not a valid "Over Time" chart type
          const params: { isHistorical: boolean; chartType?: string } = {
            isHistorical: e.target.value,
          };
          if (e.target.value) {
            if (widget.chartType !== "line") {
              params["chartType"] = "line";
              widget.chartType = "line";
            }
          } else {
            if (widget.chartType === "line") {
              params["chartType"] = "bar";
              widget.chartType = "bar";
            }
          }
          if (widget.id) {
            updateWidget(widget.id, params).then((response) => {
              const updatedWidget = response.widget;
              for (let x = 0; x < updatedWidget.datasets.length; x++) {
                const dataset = updatedWidget.datasets[x];
                data.getData(dataset, updatedWidget);
              }
            });
          }
        }}
      >
        <Radio.Button value={false}>Now</Radio.Button>
        <Radio.Button value={true}>Over Time</Radio.Button>
      </Radio.Group>
      <div className="chartType">
        <Radio.Group
          defaultValue={widget.chartType}
          value={widget.chartType}
          name="chartType"
          onChange={(e) => {
            if (widget.id) {
              updateWidget(widget.id, { chartType: e.target.value });
            }
          }}
        >
          <Radio.Button value="line" disabled={!widget.isHistorical}>
            <img className="icon" src={lineIcon} alt="line" />
          </Radio.Button>
          <Radio.Button value="bar" disabled={widget.isHistorical}>
            <img className="icon" src={barIcon} alt="bar" />
          </Radio.Button>
          <Radio.Button value="pie" disabled={widget.isHistorical}>
            <img className="icon" src={pieIcon} alt="pie" />
          </Radio.Button>
          <Radio.Button value="number" disabled={widget.isHistorical}>
            <img className="icon" src={numberIcon} alt="number" />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ float: "right" }}>
        <Select
          size="large"
          onChange={updateSelectedOption}
          value={selectedOption}
          bordered={false}
        >
          <Select.Option value="datasets">
            {t("translation:snapshots")}
          </Select.Option>
          {widget.chartType === "bar" && (
            <Select.Option value="trendlines">
              {t("translation:trend_lines")}
            </Select.Option>
          )}
        </Select>
      </div>
      {selectedOption === "datasets" && <Datasets widget={widget} />}
      {selectedOption === "trendlines" && <TrendLines widget={widget} />}
    </div>
  );
}
