import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { CostCenter } from "../models";

/**
 * Get all cost centers
 * @param params URLSearchParams
 * @returns Promise<{ costCenters: CostCenter[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ cost_centers: CostCenter[] }> {
  return genericGetAll("cost_centers", params);
}

/**
 * Get a single cost center
 * @param id string
 * @returns Promise<{ cost_center: CostCenter }>
 */
function getSingle(id: string): Promise<{ cost_center: CostCenter }> {
  return genericGetSingle("cost_centers", id);
}

/**
 * Gets the count of cost centers
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("cost_centers", params);
}

export const costCenterService = {
  getAll,
  getSingle,
  getCount,
};
