import React from "react";
import { Row, Col } from "antd";
import { roleService } from "/app/src/services";
import { Helmet } from "react-helmet";
import RoleMenu from "./_menu";
import RoleForm from "./form";
import Permissions from "./permissions";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "../../hooks/useNumberId";

/**
 * Component to display a role. Includes the role menu, form, and permissions
 */
function SingleRole() {
  const { roleId } = useNumberId();

  const {
    data: singleRole,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["role", roleId],
    queryFn: () => {
      return roleService.getSingle(roleId);
    },
    initialData: { role: { name: "" } },
    select: (data: { role: { name: string } }) => {
      return data.role;
    },
  });

  return (
    <div className="user">
      <Helmet>
        <title>{singleRole?.name}</title>
      </Helmet>
      <h1>{singleRole?.name}</h1>

      {isLoading || isFetching ? (
        <h1>Loading...</h1>
      ) : (
        <Row gutter={20}>
          <Col span={6}>
            <RoleMenu role={singleRole} />
            <RoleForm role={singleRole} />
          </Col>
          <Col span={18}>
            <Permissions role={singleRole} />
          </Col>
        </Row>
      )}
    </div>
  );
}
export default SingleRole;
