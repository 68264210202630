import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("snapshots", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("snapshots", id);
}

function createSingle(snapshot: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("snapshots", snapshot);
}
function updateSingle(
  id: number | string,
  snapshot: { [key: string]: string | number | boolean | Date | undefined }
) {
  return genericUpdateSingle("snapshots", id, snapshot);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("snapshots", id);
}

/**
 * Gets the count of snapshots
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("snapshots", params);
}

export const snapshotService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
