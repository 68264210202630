import { string, array, object } from "yup";
import i18next from "/app/src/locales/config";

export const emailSchema = object().shape({
  message: string(),
  to: array()
    .min(1, i18next.t("translation:required"))
    .required(i18next.t("translation:email_is_required"))
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(string().email(({ value }) => `${value} is not a valid email`)),
  value: string().required(i18next.t("translation:email_subject_is_required")),
});
