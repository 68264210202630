import {
  makeAPIRequest,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("widget_datasets", params);
}

function getAllFromWidget(
  widgetId: number,
  params: { [key: string]: string | number | boolean | Date | undefined } = {}
) {
  return makeAPIRequest({
    url: `/widgets/${widgetId}/widget_datasets`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function createSingle(data: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("widget_datasets", data);
}

function updateSingle(id: number | string, widgetDataset = {}) {
  return genericUpdateSingle("widget_datasets", id, widgetDataset);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("widget_datasets", id);
}

/**
 * Gets the count of widget_datasets
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("widget_datasets", params);
}

export const widgetDatasetService = {
  getAll,
  getAllFromWidget,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
