import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { settingService } from "/app/src/services";
import { removeLicenseKey } from "/app/src/helpers/license";
import { useTranslation } from "react-i18next";

interface FormValues {
  license: string;
}

export default function DisabledCheck({
  name,
  message,
  connections = [],
  children = null,
}: {
  name: string;
  message: string;
  connections?: number[];
  children?: JSX.Element;
}) {
  const { t } = useTranslation();

  const updateLicenseForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={20}>
        <Col span={24}>
          <Form.Item name="license" label={t("translation:license")}>
            <Input.TextArea
              name="license"
              placeholder={`${t("translation:please_enter")} ${t(
                "translation:your_license",
              )}`}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubmitButton type="primary" size="large" disabled={!dirty}>
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  if (isConnected(connections)) {
    return children;
  } else {
    return (
      <div className="login">
        <Helmet>
          <title>
            {name} {t("translation:disabled")} - ItemPath
          </title>
        </Helmet>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {message}
              </h3>
              {name === "Access" && (
                <Formik
                  component={updateLicenseForm}
                  initialValues={{
                    license: "",
                  }}
                  onSubmit={(values, actions) => {
                    settingService
                      .updateSettings({ license: values.license })
                      .then((response) => {
                        if (response.licenseUpdated) {
                          removeLicenseKey();
                          window.location.href = "/dashboard";
                        } else {
                          actions.setFieldError("license", "Invalid License");
                          actions.setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        actions.setSubmitting(false);
                      });
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function isConnected(connections: number[]) {
  if (connections.length === 0) {
    return false;
  }
  let connected = true;
  connections.forEach((conn) => {
    if (conn === 0) {
      connected = false;
    }
  }, connected);
  return connected;
}
