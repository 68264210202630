import React, { useEffect, useState, useRef } from "react";
import { Row, Button, Progress } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { processService } from "/app/src/services";
import { FormikProps } from "formik";
import SingleOrder from "./singleOrder";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";

interface FormValues {
  issueTo: string;
  qualification: string;
  facility: string;
  notes: string;
}
export default function ManualProcessingOrders({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { integrationId } = useParams();
  const { processId } = useParams();
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [progress, setProgress] = useState(0);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const returnToOrderList = () => {
    navigate(`/apps/${app.id}/integrations/${integrationId}`);
  };
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };
  useEffect(() => {
    if (processId) {
      processService
        .getSingle(processId)
        .then((response) => {
          setOrders(response.process.orders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [app, processId]);

  useEffect(() => {
    let value = (currentOrder + 1) / orders.length;
    value = value * 100;
    setProgress(value);
  }, [currentOrder, orders]);

  return (
    <div>
      {orders.length > 0 && (
        <SingleOrder
          orderId={orders[currentOrder]}
          cancelOrder={returnToOrderList}
          formRef={formRef}
        />
      )}
      <Row justify="center">
        <Progress percent={progress} showInfo={false} />
        {t("translation:order")} {currentOrder + 1} / {orders.length}
        {currentOrder === orders.length - 1 ? (
          <Button
            className="next"
            type="primary"
            htmlType="submit"
            block
            onClick={() => returnToOrderList()}
          >
            {t("translation:finish_processing")}
          </Button>
        ) : (
          <Button
            form="dynamicFields"
            key="submit"
            className="next"
            htmlType="submit"
            type="primary"
            block
            onClick={() => {
              handleSubmit();
              setCurrentOrder(currentOrder + 1);
            }}
          >
            {t("translation:go_to_next_order")}
          </Button>
        )}
      </Row>
    </div>
  );
}
