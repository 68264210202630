import { makeAPIRequest } from "../helpers/apiCall";

function getAllHubs(params: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({
    url: "/settings/hue/bridges",
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function createUser(params: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({
    url: "/settings/hue/user",
    params,
    method: "post",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getURL(params: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({
    url: "/settings/hue/start",
    params,
    method: "post",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getAllZones(params: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({
    url: "/settings/hue/zones",
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const hueService = {
  getURL,
  getAllZones,
  getAllHubs,
  createUser,
};
