import React from "react";
import RefreshList from "./refreshSetting";

export default function Controls({
  name,
  activeNewIntegration,
  refresh,
  setActiveNewIntegration,
}: {
  name: string;
  activeNewIntegration: boolean;
  refresh: () => void;
  setActiveNewIntegration: (activeNewIntegration: boolean) => void;
}) {
  return (
    <div className="app" id={name}>
      <div className="controls">
        <div className="icon"> </div>
        <div className="title">
          <h1>{name}</h1>
        </div>
        <div className="controlButtons">
          {activeNewIntegration ? (
            <RefreshList
              refresh={refresh}
              newSetting={activeNewIntegration}
              setNewSetting={setActiveNewIntegration}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
