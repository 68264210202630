import { genericGetSingle, genericGetAll } from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("shelves", params);
}

function getSingle(id: string) {
  return genericGetSingle("shelves", id);
}

/**
 * Gets the count of shelves
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("shelves", params);
}

export const shelfService = {
  getAll,
  getSingle,
  getCount,
};
