import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import axios from "axios";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("users", params);
}

function getSingle(userId: number | string) {
  return genericGetSingle("users", userId);
}

function createSingle(user: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("users", user);
}

function updateSingle(
  id: number | string,
  user: { [key: string]: string | number | boolean | Date | undefined },
) {
  return genericUpdateSingle("users", id, user);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("users", id);
}

function refresh(refreshToken: string) {
  //all other api calls use the same authentication header
  return axios({
    url: `${process.env.REACT_APP_API}/api/users/refresh`,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function login(details: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({ url: "/users/login", body: details, method: "post" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * SSO Login API Call
 */
function ssoLogin() {
  return makeAPIRequest({ url: "/users/sso", method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function reset(search: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({ url: "/users/reset", body: search, method: "post" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of users
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("users", params);
}

export const userService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  refresh,
  reset,
  login,
  ssoLogin,
  getCount,
};
