import { Row, Col, Divider, Button, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { workflowService, reportService } from "/app/src/services";
import Controls from "./controls";
import Triggers from "/app/src/components/generic/components/trigger";
import Actions from "./actions";
import Test from "./testWorkflow";
import Delete from "./deleteWorkflow";
import Criterias from "./criteria";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Workflow as WorkflowType,
  Trigger as TriggerType,
} from "/app/src/models";
import { handlePromiseError } from "../../helpers/api";
export default function SingleWorkflowView({
  workflow,
}: {
  workflow: WorkflowType;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: reports } = useQuery({
    queryKey: ["reports"],
    queryFn: () => {
      return reportService.getAll();
    },
    initialData: { reports: [] },
    select: (data: { reports: [] }) => {
      return data.reports;
    },
  });

  const { mutateAsync: updateWorkflow } = useMutation({
    mutationFn: (updatedWorkflow: Omit<WorkflowType, "trigger">) => {
      return workflowService
        .updateSingle(workflow.id, updatedWorkflow)
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["workflow", workflow.id], data);
    },
  });

  const toggleWorkflowStatus = (value: boolean) => {
    updateWorkflow({ status: value ? 1 : 0 });
  };

  const handleTriggerUpdate = (trigger: TriggerType) => {
    //update nested state property
    const workflowCopy = { ...workflow };
    workflowCopy.trigger = trigger;
    queryClient.setQueryData(["workflow", workflow.id], {
      workflow: workflowCopy,
    });
  };
  return (
    <div className="workflow">
      <Controls workflow={workflow} />
      <Row gutter={20}>
        <Col span={17}>
          <div className="box">
            <Triggers object={workflow} updateTrigger={handleTriggerUpdate} />
            <Divider>{t("translation:if")}</Divider>
            <Criterias workflow={workflow} reports={reports} />
            <Divider className="then_divider">{t("translation:then")}</Divider>
            <Actions workflow={workflow} type={"0"} reports={reports} />
            <Divider className="then_divider">{t("translation:else")}</Divider>
            <Actions workflow={workflow} type={"1"} reports={reports} />
          </div>
        </Col>
        <Col span={7}>
          <Row className="box">
            <Col span={6}>
              <h4>{t("translation:active")}</h4>
              <Switch
                className="toggleSwitch"
                defaultChecked
                onChange={toggleWorkflowStatus}
                checked={workflow.status === 1}
              />
            </Col>
            <Col span={18}>
              <h4>{t("translation:next_trigger")}</h4>
              {workflow.status === 1 ? (
                workflow.trigger?.nextTrigger ? (
                  <DateTime date={workflow.trigger.nextTrigger} />
                ) : (
                  <p>{t("translation:select_trigger")}</p>
                )
              ) : (
                <p>{t("translation:na")}</p>
              )}
            </Col>
          </Row>
          <Test workflowId={workflow.id} />
          <Delete workflowId={workflow.id} />
          <Button
            block
            type="primary"
            onClick={() => {
              navigate(`/workflows/${workflow.id}/logs`);
            }}
          >
            {t("translation:view")} {t("translation:logs")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
