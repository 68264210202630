import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Peripheral } from "../models";
import { Error } from "../types";

/**
 * Get all peripherals
 * @param params URLSearchParams
 * @returns Promise<{ peripherals: Peripheral[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ peripherals: Peripheral[] }> {
  return genericGetAll("peripherals", params);
}

// For getting order details
/**
 * Get a single peripheral
 * @param id number | string
 * @returns Promise<{ peripheral: Peripheral }>
 */
function getSingle(id: number | string) {
  return genericGetSingle("peripherals", id);
}

/**
 * Get a single peripheral
 * @param item { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ peripheral: Peripheral } | Error>
 */
function createSingle(item: {
  [key: string]: string | number | boolean | Date | undefined | string[];
}): Promise<{ peripheral: Peripheral } | Error> {
  return genericCreateSingle("peripherals", item);
}

/**
 * Update a single peripheral
 * @param id number | string
 * @param item { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ peripheral: Peripheral } | Error>
 */
function updateSingle(
  id: number | string,
  item: {
    [key: string]: string | number | boolean | Date | undefined;
  },
) {
  return genericUpdateSingle("peripherals", id, item);
}

/**
 * Delete a single peripheral
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(id: number | string) {
  return genericDeleteSingle("peripherals", id);
}

/**
 * Gets the count of peripherals
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("peripherals", params);
}

export const peripheralService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
