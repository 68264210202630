import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLoading } from "/app/src/hooks";
import { settingService } from "/app/src/services";
import { convertStringToBoolean } from "/app/src/helpers/converters";

import { Setting as SettingType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

/**
 * Creates a component that displays a setting, pulling in a default value
 *  even if it doesn't exist in the database. Use the setting number and
 *  appId to determine and assume uniqueness.
 */
export default function Setting({
  settingNumber,
  settingType,
  defaultValue,
  appId,
}: {
  settingNumber: number;
  settingType: string;
  defaultValue: string;
  appId: number;
}) {
  const { t } = useTranslation();
  const [setting, setSetting] = useState<SettingType>({ value: defaultValue });
  const [loading, setLoading] = useLoading(true);

  // Check to see if the setting exists, if not create it via the API.
  const onChange = (e: CheckboxChangeEvent) => {
    if (setting.id) {
      settingService
        .updateSingle(setting.id, { value: e.target.checked })
        .then((response) => setSetting(response.setting));
    } else {
      settingService
        .createSingle({
          number: settingNumber,
          value: e.target.checked,
          appId: appId,
        })
        .then((response) => {
          setSetting(response.setting);
        });
    }
  };

  // Get the setting by setting Number. Assume its a unique setting.
  useEffect(() => {
    const getSetting = async () => {
      const response = await settingService.getAll(
        buildParams({
          number: settingNumber,
          appId: appId,
        })
      );
      if (response.settings[0]) {
        setSetting(response.settings[0]);
      }
    };
    setLoading(getSetting);
  }, [settingNumber, appId, setLoading]);

  return (
    <div className="setting">
      {
        /* TODO: Add alternate modes of settings besides checkboxes */
        settingType === "checkbox" && (
          <Checkbox
            onChange={onChange}
            defaultChecked={convertStringToBoolean(setting?.value)}
            disabled={loading}
          >
            {t(`setting_${settingNumber}` as any)}
          </Checkbox>
        )
      }
    </div>
  );
}
