import { genericGetSingle, genericGetAll } from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("work_order_lines", params);
}

// For getting orderline details
function getSingle(id: string) {
  return genericGetSingle("work_order_lines", id);
}

/**
 * Gets the count of work order lines
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("work_order_lines", params);
}

export const workOrderLineService = {
  getAll,
  getSingle,
  getCount,
};
