import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { Theme } from "/app/src/types";
import { useMutation } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  type?: string;
  name?: string;
  theme?: string;
}

function formatForm(values: FormValues, app: App): Integration {
  return {
    name: values.name,
    appId: app.id,
    number: values.type,
    baseTable: values.theme,
  };
}

export default function NewIntegration({
  app,
  themes,
  setToggled,
}: {
  app: App;
  themes: Theme[];
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: addIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .createSingle(integration)
        .then(handlePromiseError);
    },
  });

  const addIntegrationHandler = async (values: FormValues) => {
    const formattedForm = formatForm(values, app);
    await addIntegration(formattedForm).then((response) => {
      navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
      setToggled();
    });
  };

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
    isSubmitting,
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={4}>
          <Form.Item name="type" label={t("translation:type")}>
            <Select name="type" size="large">
              <Select.Option value="API">{t("translation:api")}</Select.Option>
              <Select.Option value="File">
                {t("translation:file")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="theme" label={t("translation:theme")}>
            <Select name="theme" size="large">
              {themes.map((theme) => (
                <Select.Option value={theme.value} key={theme.value}>
                  {theme.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="name"
            label={t("translation:integration_name")}
            hasFeedback={false}
          >
            <Input
              name="name"
              placeholder={t("translation:enter_name")}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
          >
            {t("translation:create")} {t("translation:integration")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initialValues: FormValues = {
    type: "API",
    name: "",
    theme: "Material",
  };
  return (
    <div className="box">
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={initialValues}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={addIntegrationHandler}
        />
      </div>
    </div>
  );
}
