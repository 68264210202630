import React from "react";
import { Route, Routes } from "react-router-dom";

import SingleApp from "./single/app";
import AppList from "./appList";

export default function Apps() {
  return (
    <Routes>
      <Route path=":appId/*" element={<SingleApp />} />
      <Route exact path="/" element={<AppList />} />
    </Routes>
  );
}
