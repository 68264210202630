import React from "react";

import NewSnapshot from "./newSnapshot";
import Snapshot from "./snapshot";
import {
  Report,
  ReportColumn,
  Snapshot as SnapshotType,
} from "/app/src/models";

export default function Snapshots({
  report,
  snapshots,
  columns,
}: {
  report: Report;
  snapshots: SnapshotType[];
  columns: ReportColumn[];
}) {
  return (
    <div className="snapshots">
      {snapshots.map((snapshot) => (
        <Snapshot
          snapshot={snapshot}
          key={snapshot.id}
          reportColumns={columns}
        />
      ))}
      <NewSnapshot report={report} reportColumns={columns} />
    </div>
  );
}
