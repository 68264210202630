import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { logService, taskService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";

import DateTime from "/app/src/components/generic/formatting/dateTime";
import Table from "/app/src/components/generic/tables/table";
import { Integration, Task as TaskType, Log as LogType } from "/app/src/models";
import { useSort } from "/app/src/hooks";
import { buildParams } from "/app/src/helpers/params";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function Logs({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const { taskId } = useNumberId();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:description"),
        accessor: "description",
      },
      {
        Header: t("translation:errors"),
        accessor: "error",
      },
      {
        Header: t("translation:date"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
    ];
  }, [t]);

  //Get integration
  const { data: task } = useQuery({
    queryKey: ["task", taskId],
    queryFn: () => {
      return taskService.getSingle(Number(taskId));
    },
    initialData: { task: {} },
    select: (data: { task: TaskType }) => ({
      task: data.task,
    }),
  });

  // Get number of logs
  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", taskId],
    queryFn: () => {
      return logService.getCount(buildParams({ taskId }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: logs, isFetching } = useQuery({
    queryKey: ["logs", taskId, page, pageSize, sort, task],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          limit: pageSize,
          page,
          taskId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { logs: [] },
    select: (data: { logs: LogType[] }) => {
      return data.logs;
    },
  });

  return (
    <div className="logs">
      <div className="box">
        <h1>
          <Link
            to={`/apps/${integration.appId}/integrations/${integration.id}/tasks`}
          >
            {t("translation:logs")}
          </Link>{" "}
          / {t("translation:log")}
        </h1>
      </div>
      <Table
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={t("translation:no_tasks_found")}
        rowClicked={() => {}}
      />
    </div>
  );
}
