import { Formik, FormikProps } from "formik";
import { Row, Col, Divider } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

import {
  settingService,
  statusService,
  warehouseService,
} from "/app/src/services";
import { settingsSchema, settingSchema } from "/app/src/schemas/settingsSchema";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Setting as SettingType, Warehouse } from "/app/src/models";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import { mapWarehouses } from "/app/src/components/generic/formatting/warehouse";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "../../helpers/api";

/**
 * Component to display the forms for updating the license, url, time zone, and default warehouse
 */
export default function General() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Count of Rows
  const { data: license } = useQuery({
    queryKey: ["license"],
    queryFn: () => {
      return statusService.getStatus();
    },
    initialData: { license: "" },
    select: (data: { license: string }) => {
      return data.license;
    },
  });

  //useQuery for getting the time zone
  const { data: timeZone } = useQuery({
    queryKey: ["timeZone"],
    queryFn: () => {
      return statusService.getStatus();
    },
    initialData: { timeZone: "" },
    select: (data: { timeZone: string }) => {
      return data.timeZone;
    },
  });

  //useQuery for getting the ItemPath URL
  const { data: urlSetting } = useQuery({
    queryKey: ["urlSetting"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 101 }))
        .then((response) => {
          return response.settings[0];
        });
    },
  });

  //useQuery for getting the default warehouse
  const { data: defaultWarehouse } = useQuery({
    queryKey: ["defaultWarehouse"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 106 }))
        .then((response) => {
          return response.settings[0];
        });
    },
  });

  //useQuery for getting the list of warehouses
  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => {
      return warehouseService.getAll();
    },
    initialData: { warehouses: [] },
    select: (data: { warehouses: Warehouse[] }) => {
      return data.warehouses;
    },
  });

  //useMutation for updating the ItemPath URL
  const { mutateAsync: updateURL } = useMutation({
    mutationFn: (updateURL: SettingType) => {
      return settingService
        .updateSingle(updateURL.id, { value: updateURL.value })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["urlSetting"], data.setting);
    },
  });

  //useMutation for creating the ItemPath URL
  const { mutateAsync: createURL } = useMutation({
    mutationFn: (createURL: SettingType) => {
      return settingService
        .createSingle({
          value: createURL.value,
          number: 101,
          name: "URL",
        })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["urlSetting"], data.setting);
    },
  });

  //onSubmit handler for creating/updating the ItemPath URL
  const onSubmitURL = async (
    {
      value,
    }: {
      value: string;
    },
    {
      setFieldError,
    }: { setFieldError: (field: string, value: string) => void },
  ): Promise<void> => {
    if (urlSetting?.id) {
      await updateURL({ id: urlSetting.id, value }).catch((error) => {
        return setFieldError("value", error.errors[0]);
      });
    } else {
      await createURL({ value }).catch((error) => {
        return setFieldError("value", error.errors[0]);
      });
    }
  };

  //useMutation for updating the license
  const { mutateAsync: updateLicense } = useMutation({
    mutationFn: (values: { license: string }) => {
      return settingService
        .updateSettings({ license: values.license })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.license = values.license;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["license"], { license: data.license });
    },
  });

  //onSubmit handler for updating the license
  const onSubmitLicense = async (
    {
      license,
    }: {
      license: string;
    },
    {
      setFieldError,
    }: { setFieldError: (field: string, value: string) => void },
  ): Promise<void> => {
    await updateLicense({ license }).catch((error) => {
      return setFieldError("license", error.errors[0]);
    });
  };

  //useMutation for updating the time zone
  const { mutateAsync: updateTimezone } = useMutation({
    mutationFn: (values: { timeZone: string }) => {
      return settingService
        .updateSettings({ timeZone: values.timeZone })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.timeZone = values.timeZone;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["timeZone"], { timeZone: data.timeZone });
    },
  });

  //onSubmit handler for updating the time zone
  const onSubmitTimezone = async (
    { value: timeZone }: { value: string },
    {
      setFieldError,
    }: { setFieldError: (field: string, value: string) => void },
  ): Promise<void> => {
    await updateTimezone({ timeZone }).catch((error) => {
      return setFieldError("timeZone", error.errors[0]);
    });
  };

  //useMutation for updating the default warehouse
  const { mutateAsync: updateDefaultWarehouse } = useMutation({
    mutationFn: (values: { defaultWarehouse: string }) => {
      return settingService
        .updateSettings({ defaultWarehouse: values.defaultWarehouse })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.defaultWarehouse = values.defaultWarehouse;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["defaultWarehouse"], {
        defaultWarehouse: data.defaultWarehouse,
      });
    },
  });

  //onSubmit handler for updating the default warehouse
  const onSubmitDefaultWarehouse = async (
    { value: defaultWarehouse }: { value: string },
    {
      setFieldError,
    }: { setFieldError: (field: string, value: string) => void },
  ): Promise<void> => {
    await updateDefaultWarehouse({ defaultWarehouse }).catch((error) => {
      return setFieldError("defaultWarehouse", error.errors[0]);
    });
  };

  /**
   * Update license form
   */
  const updateLicenseForm: (
    props: FormikProps<{ license: string }>,
  ) => JSX.Element = ({ dirty }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3>{t("translation:license")}</h3>
          <p>{t("translation:license_description")}</p>
        </Col>
        <Col span={16}>
          <Form.Item name="license">
            <Input.TextArea
              className="no-label" // skipcq: JS-0394
              name="license"
              rows={4}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={16} offset={8}>
          <SubmitButton type="primary" size="large" block disabled={!dirty}>
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  /**
   * Update URL form
   */
  const updateURLForm: (
    props: FormikProps<{ value: string | undefined }>,
  ) => JSX.Element = ({ dirty }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3>{`${t("translation:itempath")} ${t("translation:url")}`}</h3>
          <p>{t("translation:url_description")}</p>
        </Col>
        <Col span={13}>
          <Form.Item
            className="no-label" // skipcq: JS-0394
            name="value"
          >
            <Input suffix name="value" size="large" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton
            className="no-label" // skipcq: JS-0394
            type="primary"
            size="large"
            block
            disabled={!dirty}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  /**
   * Update time zone form
   */
  const updateTimeZoneForm: (
    props: FormikProps<{ value: string }>,
  ) => JSX.Element = ({ dirty }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3>{t("translation:time_zone")}</h3>
          <p>{t("translation:time_zone_description")}</p>
        </Col>
        <Col span={13}>
          <Form.Item name="value" label={t("translation:time_zone")}>
            <SearchFilter
              list={getTimeZones()}
              name="value"
              placeholder={t("translation:select_time_zone")}
              sort={true}
              mapOptionsFn={mapTimeZones}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton
            className="no-label" // skipcq: JS-0394
            type="primary"
            size="large"
            block
            disabled={!dirty}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  /**
   * Form to update the default warehouse setting
   */
  const updateDefaultWarehouseForm: (
    props: FormikProps<{ value: string }>,
  ) => JSX.Element = ({ dirty }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <h3>{t("translation:default_warehouse")}</h3>
          <p>{t("translation:default_warehouse_description")}</p>
        </Col>
        <Col span={13}>
          <Form.Item name="value" label={t("translation:default_warehouse")}>
            <SearchFilter
              list={warehouses}
              name="value"
              placeholder={t("translation:select_default_warehouse")}
              sort
              mapOptionsFn={mapWarehouses}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <SubmitButton
            className="no-label" // skipcq: JS-0394
            type="primary"
            size="large"
            block
            disabled={!dirty}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div className="box">
      <h1>{t("translation:general_settings")}</h1>
      <Formik
        component={updateURLForm}
        enableReinitialize
        initialValues={{
          value: urlSetting?.value,
        }}
        validationSchema={settingSchema}
        onSubmit={onSubmitURL}
      />
      <Divider />
      <Formik
        component={updateLicenseForm}
        initialValues={{
          license,
        }}
        validationSchema={settingsSchema}
        enableReinitialize
        onSubmit={onSubmitLicense}
      />
      <Divider />
      <Formik
        component={updateTimeZoneForm}
        initialValues={{
          value: timeZone,
        }}
        enableReinitialize
        onSubmit={onSubmitTimezone}
      />
      <Divider />
      <Formik
        component={updateDefaultWarehouseForm}
        initialValues={{
          value: defaultWarehouse,
        }}
        enableReinitialize
        onSubmit={onSubmitDefaultWarehouse}
      />
    </div>
  );
}
