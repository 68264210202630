import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { ParameterValue } from "../models";

/**
 * Get all parameter values
 * @param params The URLSearchParams object to be used in the request
 * @returns A promise that resolves to the response
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ parameter_values: ParameterValue[] }> {
  return genericGetAll("parameter_values", params);
}
/**
 * Get a single parameter value by id
 * @param id the id of the parameter value to get
 * @returns A promise that resolves to the response
 */
function getSingle(
  id: number | string,
): Promise<{ parameter_value: ParameterValue }> {
  return genericGetSingle("parameter_values", id);
}

/**
 * Gets the count of parameter values
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("parameter_values", params);
}

export const parameterValueService = {
  getAll,
  getSingle,
  getCount,
};
