import { string, object } from "yup";
import i18next from "/app/src/locales/config";

const statusRegex: RegExp = /^([^,]+)(,[^,]+)*$/;

export const statusSchema = object().shape({
  data: string()
    .matches(statusRegex, i18next.t("translation:values_separated"))
    .required(i18next.t("translation:required"))
    .trim(),
});
