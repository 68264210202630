import { Descriptions } from "antd";
import { dynamicFieldService } from "/app/src/services";
import { DynamicField as DynamicFieldType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
export default function DynamicFields({
  object,
  baseTable,
}: {
  object: any;
  baseTable: string;
}) {
  // Load Dynamic Field List for materials
  const { data: dynamicFields } = useQuery({
    queryKey: ["dynamicFields", baseTable],
    queryFn: () => {
      return dynamicFieldService.getAll(buildParams({ baseTable: baseTable }));
    },
    initialData: { dynamic_fields: [] },
    select: (data: { dynamic_fields: DynamicFieldType[] }) => {
      return data.dynamic_fields;
    },
  });

  return (
    <div className="dynamicFields">
      <Descriptions>
        {dynamicFields.map((field) => (
          <Descriptions.Item key={field.id} label={field.name}>
            {field?.field && object[field.field]}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  );
}
