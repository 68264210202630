import { Sort } from "../types";

export default function getOrderByQuery(sort: Sort) {
  let orderQuery = "";
  if (sort.length === 0) {
    return "";
  }
  if (sort[0].desc === false) {
    orderQuery = `${sort[0].id}:asc`;
  } else {
    orderQuery = `${sort[0].id}:desc`;
  }
  return orderQuery;
}
