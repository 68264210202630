import { Collapse } from "antd";

import { Access, Report, Role, Workflow } from "/app/src/models";
import {
  accessService,
  permissionService,
  reportService,
  workflowService,
} from "/app/src/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePermissionsHook } from "./usePermissionsHook";
import { useTranslation } from "react-i18next";
import PermissionLevel from "./permissionLevel";
import Accesses from "./accesses";
import { buildParams } from "/app/src/helpers";
import { useCallback } from "react";
import DashboardViewOnly from "./miscPermissions/dashboardViewOnly";

const { Panel } = Collapse;

/**
 * Displays the permissions for a given role
 * @param param0 role to display permissions for
 * @returns
 */
export default function Permissions({ role }: { role: Role }) {
  const {
    workflowText,
    reportText,
    workflowsActive,
    reportsActive,
    dashboardActive,
    handleWorkflowClick,
    handleReportClick,
    handleDashboardClick,
  } = usePermissionsHook();
  const queryClient = useQueryClient();
  const allPermissionsQuery = useQuery({
    queryKey: ["allPermissions"],
    queryFn: () => permissionService.getAll(),
    staleTime: Infinity,
    placeholderData: { permissions: [] },
  });
  const reportsQuery = useQuery({
    queryKey: ["resources", "reports"],
    queryFn: () => {
      return reportService
        .getAll(
          buildParams({
            orderBy: "name",
          }),
        )
        .then((res: { reports: Report[] }) => {
          return { resources: res.reports };
        });
    },
    placeholderData: { resources: [] },
    staleTime: 60 * 1000,
  });

  const workflowsQuery = useQuery({
    queryKey: ["resources", "workflows"],
    queryFn: () => {
      return workflowService
        .getAll(
          buildParams({
            orderBy: "name",
          }),
        )
        .then((res: { workflows: Workflow[] }) => {
          return { resources: res.workflows };
        });
    },
    placeholderData: { resources: [] },
    staleTime: 60 * 1000,
  });
  /**
   * Maps the resources to the accesses. If the resource doesn't have a matching access,
   * then create a fake access
   * @param resource
   * @param roleId
   * @param resourceType
   * @param createAccess
   * @param updateAccess
   * @param accesses
   */
  const mapResourceToAccess = useCallback(
    (
      resources: Report[] | Workflow[],
      roleId: number,
      resourceType: "Workflow" | "Report",
      accesses: Access[],
    ) => {
      const allAccesses: Access[] = [];
      //Get permissions for resource type and convert to actionList
      let code = "REPO";
      if (resourceType === "Workflow") {
        code = "WORK";
      }
      const permissionData: object = queryClient.getQueryData([
        "permissions",
        roleId,
        code,
      ]);
      //convert string code to number
      const actionList = [];
      const levels = { "001": "view", "003": "edit", "004": "delete" };
      if (permissionData && code in permissionData) {
        Object.keys(levels).forEach((level) => {
          if (permissionData[code].includes(level)) {
            actionList.push(levels[level]);
          }
        });
      }
      resources.forEach((resource: Report | Workflow) => {
        const access = accesses.find(
          (access) => access.resourceId === resource.id,
        );
        if (access) {
          allAccesses.push({ ...access, resourceName: resource.name });
        } else {
          allAccesses.push({
            id: null,
            roleId,
            resourceId: resource.id,
            resourceType,
            resourceName: resource.name,
            actionList,
          });
        }
      });
      return allAccesses;
    },
    [queryClient],
  );
  const modifiedReportsQuery = useQuery({
    queryKey: ["modifiedResources", role.id, "Report"],
    queryFn: () => {
      return accessService
        .getAll(buildParams({ roleId: role.id, resourceType: "Report" }))
        .then((response) => {
          return {
            accesses: mapResourceToAccess(
              reportsQuery.data.resources,
              role.id,
              "Report",
              response.accesses,
            ),
          };
        });
    },
    initialData: { accesses: [] },
    enabled: reportsQuery.data?.resources?.length > 0,
  });

  const modifiedWorkflowsQuery = useQuery({
    queryKey: ["modifiedResources", role.id, "Workflow"],
    queryFn: () => {
      return accessService
        .getAll(buildParams({ roleId: role.id, resourceType: "Workflow" }))
        .then((response) => {
          return {
            accesses: mapResourceToAccess(
              workflowsQuery.data.resources,
              role.id,
              "Workflow",
              response.accesses,
            ),
          };
        });
    },
    initialData: { accesses: [] },
    enabled: workflowsQuery.data?.resources?.length > 0,
  });

  const { t } = useTranslation();
  return (
    <div>
      <div className="permissions-grid">
        <div className="align-left">
          <h2>{t("translation:reports")}</h2>
          <Collapse onChange={handleReportClick} ghost>
            <Panel header={reportText} showArrow key={1} />
          </Collapse>
        </div>
        <PermissionLevel
          code="REPO"
          roleId={role.id}
          resource="Report"
          allPermissions={allPermissionsQuery.data}
        />
        <Accesses
          active={reportsActive}
          data={modifiedReportsQuery.data.accesses}
        />
        <div className="align-left">
          <h2>{t("translation:workflows")}</h2>
          <Collapse onChange={handleWorkflowClick} ghost>
            <Panel header={workflowText} key={1} />
          </Collapse>
        </div>
        <PermissionLevel
          code="WORK"
          roleId={role.id}
          resource="Workflow"
          allPermissions={allPermissionsQuery.data}
        />
        <Accesses
          active={workflowsActive}
          data={modifiedWorkflowsQuery.data?.accesses}
        />
        {/* <div className="align-left">
          <h2>{t("translation:users")}</h2>
        </div>
        <PermissionLevel
          code="USER"
          roleId={role.id}
          resource="User"
          allPermissions={allPermissionsQuery.data}
        />
        <div className="align-left">
          <h2>{t("translation:roles")}</h2>
        </div>
        <PermissionLevel
          code="ROLE"
          roleId={role.id}
          resource="Role"
          allPermissions={allPermissionsQuery.data}
        /> */}
        <div className="align-left">
          <h2>{t("translation:explorer")}</h2>
        </div>
        <PermissionLevel
          code="EXPL"
          roleId={role.id}
          resource="Explorer"
          allPermissions={allPermissionsQuery.data}
          edit={false}
          delete={false}
          create={false}
        />
        <div className="align-left">
          <h2>{t("translation:dashboard")}</h2>
          <Collapse onChange={handleDashboardClick} ghost>
            <Panel
              header={t("translation:misc_permissions")}
              showArrow
              key={1}
            />
          </Collapse>
        </div>
        <PermissionLevel
          code="DASH"
          roleId={role.id}
          resource="Dashboard"
          allPermissions={allPermissionsQuery.data}
          create={false}
          delete={false}
          view={false}
        />
        <DashboardViewOnly
          active={dashboardActive}
          allPermissions={allPermissionsQuery.data}
          roleId={role.id}
        />
      </div>
    </div>
  );
}
