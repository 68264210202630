import {
  genericGetSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("transactions", params);
}

// For getting order details
function getSingle(id: string) {
  return genericGetSingle("transactions", id);
}

function updateSingle(id: string, data = {}) {
  return genericUpdateSingle("transactions", id, data);
}

/**
 * Gets the count of orders
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("transactions", params);
}

export const transactionService = {
  getAll,
  getSingle,
  updateSingle,
  getCount,
};
