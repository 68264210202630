import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

function getAll(params: URLSearchParams = null) {
  return genericGetAll("triggers", params);
}

function getSingle(id: number | string) {
  return genericGetSingle("triggers", id);
}

function updateSingle(
  id: number | string,
  trigger: { [key: string]: string | number | boolean | Date | undefined }
) {
  return genericUpdateSingle("triggers", id, trigger);
}

function createSingle(trigger: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("triggers", trigger);
}

function deleteSingle(id: number | string) {
  return genericDeleteSingle("triggers", id);
}

/**
 * Gets the count of triggers
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("triggers", params);
}

export const triggerService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
