import { Button } from "antd";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { ButtonProps } from "antd/lib/button";

/**
 * Component that disables the button if the status found in formik context is locked
 */
export default function DisabledSubmitButton({
  children,
  ...restProps
}: ButtonProps) {
  const { status } = useFormikContext();
  //locked status means the user does not have permission
  if (status === "locked") {
    restProps.disabled = true;
  }
  return (
    <Field>
      {({
        form: { isSubmitting, isValid, dirty, submitCount },
      }: FieldProps) => (
        <Button
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
          disabled={!isValid && (dirty || submitCount > 0)}
          {...restProps}
        >
          {children}
        </Button>
      )}
    </Field>
  );
}
