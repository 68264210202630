import { Permission } from "/app/src/models";

/**`
 * Groups the permissions by their first 4 characters of the code.
 * @param permissions array of permissions to sort
 */
export function sortPermissions(permissions: Permission[], code: string) {
  const sortedPermissions: { [key: string]: string[] } = {};
  permissions.forEach((permission) => {
    if (permission.code.startsWith(code)) {
      const level = permission.code.substring(5, 8);

      if (code in sortedPermissions) {
        sortedPermissions[code].push(level);
      } else {
        //get last 3 characters of code
        sortedPermissions[code] = [level];
      }
    }
  });
  return sortedPermissions;
}
