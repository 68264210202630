import React from "react";
import { Button, Row, Modal } from "antd";
import { User } from "/app/src/models";
import Status from "../users/status";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;
export default function UserMenu({
  user,
  toggleUserStatus,
}: {
  user: User;
  toggleUserStatus: () => void;
}) {
  const { t } = useTranslation();

  function ShowToggleActivationWarning(
    deactivate: string,
    confirmText: string,
    okText: string,
  ) {
    let titleText = deactivate;
    if (user.status === 2) {
      titleText = confirmText;
    }
    confirm({
      okText: okText,
      title: titleText,
      onOk: toggleUserStatus,
    });
  }

  return (
    <div className="userMenu sideMenu">
      <Row>
        <h4>
          {t("translation:user")} {t("translation:status")}:
        </h4>
      </Row>
      <Row>
        <h2>
          <p>{Status(user.status)}</p>
        </h2>
      </Row>
      <Row>
        {user.status !== 2 ? (
          <Button
            block
            danger
            onClick={() =>
              ShowToggleActivationWarning(
                t("translation:confirm_deactivate_user"),
                t("translation:confirm_deactivate_user"),
                t("translation:ok"),
              )
            }
          >
            {t("translation:deactivate")} {t("translation:user")}
          </Button>
        ) : (
          <Button
            block
            danger
            onClick={() =>
              ShowToggleActivationWarning(
                t("translation:confirm_deactivate_user"),
                t("translation:confirm_deactivate_user"),
                t("translation:ok"),
              )
            }
          >
            {t("translation:activate")} {t("translation:user")}
          </Button>
        )}
      </Row>
    </div>
  );
}
