/**
 * Creates a promise that resolves after a given time.
 * @param time The time to wait before resolving the promise.
 * @returns A promise that resolves after the given time.
 */
export function delay(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}
