import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Role } from "../models";
import { Error } from "../types";

/**
 * Gets all roles
 * @param params URLSearchParams
 * @returns Promise<{ roles: Role[] }>
 */
function getAll(params: URLSearchParams = null) {
  return genericGetAll("roles", params);
}

/**
 * Gets a single role
 * @param roleId number | string
 * @returns Promise<{ role: Role }>
 */
function getSingle(roleId: number | string): Promise<{ role: Role }> {
  return genericGetSingle("roles", roleId);
}

/**
 * Updates a single role
 * @param roleId number
 * @param role { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ role: Role } | Error>
 */
function updateSingle(
  roleId: number,
  role: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ role: Role } | Error> {
  return genericUpdateSingle("roles", roleId, role);
}

/**
 * Creates a single role
 * @param role { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ role: Role } | Error>
 */
function createSingle(role: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return genericCreateSingle("roles", role);
}

/**
 * Deletes a single role
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("roles", id);
}

/**
 * Gets the count of roles
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("roles", params);
}

export const roleService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
