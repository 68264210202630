import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * Custom hook for permissions
 * @returns workflowText, reportText, workflowsActive, reportsActive, handleWorkflowClick, handleReportClick
 */
export function usePermissionsHook() {
  const { t } = useTranslation();

  const [workflowText, setWorkflowText] = useState(
    t("translation:show_workflows"),
  );
  const [reportText, setReportText] = useState(t("translation:show_reports"));
  const [workflowsActive, setWorkflowsActive] = useState(false);
  const [reportsActive, setReportsActive] = useState(false);
  const [dashboardActive, setDashboardActive] = useState(false);

  const handleDashboardClick = useCallback((activeKey: string | string[]) => {
    if (activeKey.length === 0) {
      setDashboardActive(false);
    } else {
      setDashboardActive(true);
    }
  }, []);
  const handleWorkflowClick = useCallback(
    (activeKey: string | string[]) => {
      if (activeKey.length === 0) {
        setWorkflowText(t("translation:show_workflows"));
        setWorkflowsActive(false);
      } else {
        setWorkflowText(t("translation:hide_workflows"));
        setWorkflowsActive(true);
      }
    },
    [t],
  );
  const handleReportClick = useCallback(
    (activeKey: string | string[]) => {
      if (activeKey.length === 0) {
        setReportText(t("translation:show_reports"));
        setReportsActive(false);
      } else {
        setReportText(t("translation:hide_reports"));
        setReportsActive(true);
      }
    },
    [t],
  );
  return {
    workflowText,
    reportText,
    workflowsActive,
    reportsActive,
    dashboardActive,
    handleWorkflowClick,
    handleReportClick,
    handleDashboardClick,
  };
}
