import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { ReportFilter } from "../models";
import { Error } from "../types";

/**
 * Gets all report_filters
 * @param params URLSearchParams
 * @returns Promise<{ report_filters: ReportFilter[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ report_filters: ReportFilter[] }> {
  return genericGetAll("report_filters", params);
}

/**
 * Gets all report_filters from a report
 * @param reportId number | string
 * @param params { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_filters: ReportFilter[] }>
 */
function getAllFromReport(
  reportId: number | string,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{ report_filters: ReportFilter[] }> {
  return makeAPIRequest({
    url: `/reports/${reportId}/report_filters`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets a single report_filter
 * @param id number | string
 * @returns Promise<{ report_filter: ReportFilter }>
 */
function getSingle(
  id: number | string,
): Promise<{ report_filter: ReportFilter }> {
  return genericGetSingle("report_filters", id);
}

/**
 * Creates a single report_filter
 * @param reportFilter { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_filter: ReportFilter } | Error>
 */
function createSingle(reportFilter: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ report_filter: ReportFilter } | Error> {
  return genericCreateSingle("report_filters", reportFilter);
}

/**
 * Updates a single report_filter
 * @param id number | string
 * @param reportFilter { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report_filter: ReportFilter } | Error>
 */
function updateSingle(
  id: number | string,
  reportFilter: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ report_filter: ReportFilter } | Error> {
  return genericUpdateSingle("report_filters", id, reportFilter);
}

/**
 * Deletes a single report_filter
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("report_filters", id);
}

/**
 * Gets the count of report_filters
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("report_filters", params);
}

export const reportFilterService = {
  getAll,
  getAllFromReport,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
