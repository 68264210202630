import { string, object, number, mixed } from "yup";
import i18next from "/app/src/locales/config";

export const reportExportSchema = object().shape({
  exportType: string().required(i18next.t("translation:required")),

  integrationId: string().when("exportType", {
    is: (exportType) => /CSV/.test(exportType), //use regex to test if exportType contains "CSV"
    then: (schema) =>
      schema.required(i18next.t("translation:required")).nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const reportFilterSchema = object().shape({
  verb: string().required(i18next.t("translation:required")),
  reportColumnId: number().required(i18next.t("translation:required")),
  value: mixed()
    .transform((value) => {
      if (Array.isArray(value) && value.length === 0) {
        return null;
      }
      return value;
    })
    .required(i18next.t("translation:required")),
  dateRange: string().when("verb", {
    is: (verb) => ["before", "after"].includes(verb),
    then: (schema) =>
      schema.nonNullable().required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const updateReportFilterSchema = object().shape({
  value: mixed()
    .transform((value) => {
      if (Array.isArray(value) && value.length === 0) {
        return null;
      }
      return value;
    })
    .required(i18next.t("translation:required")),
});
