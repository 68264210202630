import { useQuery } from "@tanstack/react-query";
import {
  warehouseService,
  callService,
  workflowService,
  snapshotService,
  reportService,
  fileService,
} from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { getPreviousDaysDate } from "/app/src/helpers";

/**
 * Custom hook for the Status component
 * @returns warehouseCount, apiCallsByMonth, workflowsCount, snapshotCount, reportCount, fileCount
 *
 */
export default function StatusHook() {
  const { data: warehouseCount } = useQuery({
    queryKey: ["warehouseCount"],
    queryFn: () => {
      return warehouseService.getCount();
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //No need to filter by creation date because API call already does that
  const { data: apiCallsByMonth } = useQuery({
    queryKey: ["apiCallsByMonth"],
    queryFn: () => {
      return callService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: workflowsCount } = useQuery({
    queryKey: ["workflowsCount"],
    queryFn: () => {
      return workflowService.getCount(
        buildParams({
          countOnly: true,
          status: 1,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: snapshotCount } = useQuery({
    queryKey: ["snapshotCount"],
    queryFn: () => {
      return snapshotService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: reportCount } = useQuery({
    queryKey: ["reportCount"],
    queryFn: () => {
      return reportService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: fileCount } = useQuery({
    queryKey: ["fileCount"],
    queryFn: () => {
      return fileService.getCount(
        buildParams({
          countOnly: true,
          creationDate: `[gt]${getPreviousDaysDate(30)}`,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return {
    warehouseCount,
    apiCallsByMonth,
    workflowsCount,
    snapshotCount,
    reportCount,
    fileCount,
  };
}
