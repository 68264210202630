import React, { useState, useEffect, useContext } from "react";
import { Spin, Col } from "antd";
import WidgetSnapshotDataContext from "/app/src/contexts/WidgetSnapshotDataContext";
import { CurrentData } from "/app/src/types";

export default function Number({
  datasetId,
  snapshotsAmount,
  colour = "black",
}: {
  datasetId?: number;
  snapshotsAmount?: number;
  colour?: string;
}) {
  const [numberData, setNumberData] = useState<{
    name?: string;
    value?: number | string;
  }>({});
  const [loading, setLoading] = useState(true);
  const { data } = useContext(WidgetSnapshotDataContext);

  useEffect(() => {
    const dataset = data.snapshotsData.find((x) => x.id === datasetId);
    if (dataset !== undefined) {
      const datasetInfo: { name?: string; value?: number | string } = {
        name: dataset.name,
        value: undefined,
      };
      const currentData: CurrentData[] = dataset.data as CurrentData[];

      if (currentData) {
        if (Object.keys(currentData).length > 0) {
          datasetInfo.value = currentData[0].value as number;
        }
      } else {
        console.error("Snapshot data is null");
        datasetInfo.value = "-";
      }
      setNumberData(datasetInfo);
      setLoading(false);
    }
  }, [datasetId, data.snapshotsData]);

  if (loading) {
    return <Spin />;
  } else {
    if (snapshotsAmount === 1) {
      return (
        <Col span={24}>
          <div className="single number">
            <h1 style={{ color: colour }}>{numberData.value}</h1>
            <span>{numberData.name}</span>
          </div>
        </Col>
      );
    } else if (snapshotsAmount === 2) {
      return (
        <Col span={24}>
          <div className="double number">
            <h1 style={{ color: colour }}>{numberData.value}</h1>
            <span>{numberData.name}</span>
          </div>
        </Col>
      );
    } else {
      return (
        <Col span={12}>
          <div className="quad number">
            <h1 style={{ color: colour }}>{numberData.value}</h1>
            <span>{numberData.name}</span>
          </div>
        </Col>
      );
    }
  }
}
