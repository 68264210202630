/**
 * Component for displaying api status
 * @returns JSX.Element
 *
 */
export default function ApiStatus() {
  return (
    <div>
      <div className="box">
        <p />
      </div>
    </div>
  );
}
