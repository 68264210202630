import { useContext } from "react";
import StatusContext from "../StatusContext";

/**
 * @description Returns the status context
 * @returns {StatusContextType} status
 */
export const useStatusContext = () => {
  const status = useContext(StatusContext);
  return status;
};
