import React from "react";

import Search from "./search";

export default function Filters({
  setSearchString,
}: {
  setSearchString: (searchString: string) => void;
}) {
  return (
    <div className="workflowFilters">
      <Search setSearchString={setSearchString} />
    </div>
  );
}
