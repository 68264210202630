import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useSort } from "/app/src/hooks";
import { integrationService } from "/app/src/services";
import { App, Integration as IntegrationType } from "/app/src/models";
import Controls from "./controls";
import Filters from "./filters";
import NewForm from "./newDisplayForm";
import Table from "/app/src/components/generic/tables/table";
import { useTranslation } from "react-i18next";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DeleteButtonPopup from "/app/src/components/generic/components/buttons/DeleteButtonPopup";
import { Cell } from "react-table";

/**
 * Returns a table with the the displays
 * @param param0 object containing app
 */
export default function DisplaysList({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");
  const queryClient = useQueryClient();
  const invalidateDisplays = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["displays", page, pageSize, searchString, sort, app.id],
    });
    queryClient.invalidateQueries({
      queryKey: ["displaysCount", app.id, searchString],
    });
  }, [app.id, page, pageSize, queryClient, searchString, sort]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
      {
        Header: t("translation:description"),
        accessor: "token",
      },
      {
        Header: "",
        id: "delete",
        accessor: "id",
        clickable: false,
        Cell: (cell: Cell) => (
          <div style={{ display: "flex" }}>
            <DeleteButtonPopup
              confirmDeleteText={t("translation:confirm_delete_report")}
              deleteObjectFn={() => integrationService.deleteSingle(cell.value)}
              refresh={invalidateDisplays}
            />
          </div>
        ),
      },
    ];
  }, [invalidateDisplays, t]);

  const goToDisplay = (integrationId: number) => {
    navigate(`/apps/${app.id}/displays/${integrationId}`);
  };

  const [activeNew, setActiveNew] = useState(false);
  const toggleNew = () => {
    setActiveNew(!activeNew);
  };

  const {
    data: displays,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["displays", page, pageSize, searchString, sort, app.id],
    queryFn: () => {
      return integrationService.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          appId: app.id,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { integrations: [] },
    select: (data: { integrations: IntegrationType[] }) => {
      return data.integrations;
    },
  });

  const { data: displaysCount } = useQuery({
    queryKey: ["displaysCount", app.id, searchString],
    queryFn: () => {
      return integrationService.getCount(
        buildParams({ appId: app.id, search: searchString }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="app" id="displays">
      <Helmet>
        <title>{t("translation:displays")} - ItemPath</title>
      </Helmet>
      <Controls app={app} toggleNew={toggleNew} activeNew={activeNew} />
      {activeNew && <NewForm app={app} />}
      <Row gutter={20}>
        <Col span={6}>
          <Filters setSearchString={setSearchString} />
        </Col>

        <Col span={18}>
          <Table
            loading={isFetching || isLoading}
            rows={displays}
            tableColumns={columns}
            length={displaysCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            rowClicked={goToDisplay}
            emptyText={t("translation:no_displays_found")}
          />
        </Col>
      </Row>
    </div>
  );
}
