import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { batchService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";

import Search from "./../search";
import { useSort } from "/app/src/hooks";
import Table from "/app/src/components/generic/tables/table";
import { useTranslation } from "react-i18next";
import { Batch as BatchType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { Column } from "react-table";
import { useQuery } from "@tanstack/react-query";
export default function Batches() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns: Column<BatchType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
      {
        Header: t("translation:priority"),
        accessor: "priority",
      },
      {
        Header: t("translation:creation_date"),
        accessor: "creationDate",
      },
    ];
  }, [t]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");

  const goToBatch = (batchId: number) => {
    navigate(`/explorer/batches/${batchId}`);
  };

  //Get the batches
  const { data: batches, isFetching } = useQuery({
    queryKey: ["batches", page, pageSize, sort, searchString],
    queryFn: () => {
      return batchService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { batches: [] },
    select: (data: { batches: BatchType[] }) => {
      return data.batches;
    },
  });

  //Get the number of batches
  const { data: batchesCount } = useQuery({
    queryKey: ["batchesCount", searchString],
    queryFn: () => {
      return batchService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:batches")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={batches}
        tableColumns={columns}
        length={batchesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        rowClicked={goToBatch}
        emptyText={t("translation:no_batches")}
      />
    </div>
  );
}
