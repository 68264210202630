import React from "react";

export default function Controls({ title }: { title: string }) {
  return (
    <div className="controls">
      <div className="d-flex bd-highlight">
        <h1>{title}</h1>
      </div>
    </div>
  );
}
