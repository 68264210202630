import { message } from "antd";
import { connectionService } from "/app/src/services";
import { Connection } from "/app/src/models";
import { ConnectionState } from "../contexts/types";
//convert connections type and status `to an object with the keys as type, value as status

export function parseConnections(data: Connection[]): ConnectionState {
  ///https://stackoverflow.com/a/67042616
  const sqlConnection = data.find(
    (connection) => connection.type === "PowerPick SQL",
  );
  const webServicesConnection = data.find(
    (connection) => connection.type === "PowerPick Web Services",
  );
  return {
    connectionState: {
      "PowerPick SQL": sqlConnection.status as 0 | 1,
      "PowerPick Web Services": webServicesConnection.status as 0 | 1,
    },
  };
}

export function testConnection(
  setIsTesting: (status: boolean) => void,
  connection: Connection,
  setConnection: (connection: Connection) => Promise<any>,
) {
  if (!connection?.id) return;
  setIsTesting(true);
  message.loading(`Testing ${connection.name} connection`, 25);
  connectionService
    .testSingle(connection.id)
    .then((response) => {
      message.destroy();
      if ("connected" in response) {
        message.success("Connection successful");
        if (connection.status === 0) {
          setConnection({ id: connection.id, status: 1 });
        }
      } else {
        if (connection.status === 1) {
          setConnection({ id: connection.id, status: 0 });
        }
        if ("errors" in response) {
          message.error(response.error);
        } else {
          // API tests return status code
          if ("statusCode" in response) {
            message.error(`Connection failed - ${response.statusCode}`);
          } else {
            message.error("Connection failed.");
          }
        }
      }
    })
    .catch(() => {
      message.error("Connection failed.");
      setConnection({ id: connection.id, status: 0 });
    })
    .finally(() => {
      setIsTesting(false);
    });
}

/**
 * Helper function to check if the connection status has changed
 * @param oldStatus old Connection Status
 * @param newStatus new Connection Status
 * @returns true if the connection status has changed
 */
export function checkStatusChange(
  oldStatus: ConnectionState,
  newStatus: ConnectionState,
) {
  if (
    oldStatus.connectionState["PowerPick SQL"] !==
    newStatus.connectionState["PowerPick SQL"]
  ) {
    return true;
  }
  if (
    oldStatus.connectionState["PowerPick Web Services"] !==
    newStatus.connectionState["PowerPick Web Services"]
  ) {
    return true;
  }
  return false;
}
