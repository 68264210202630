import React from "react";
import BarChart from "./barChart";
import PieChart from "./pieChart";
import LineChart from "./lineChart";
import NumberChart from "./numberChart";
import { Widget } from "/app/src/models";
import WidgetSnapshotErrorContext from "/app/src/contexts/WidgetSnapshotErrorContext";
import { useTranslation } from "react-i18next";

export default function DataChart({ widget }: { widget: Widget }) {
  const { errors } = React.useContext(WidgetSnapshotErrorContext);
  const { t } = useTranslation();

  if (errors.includes(widget.id)) {
    return <div>{t("translation:chart_data_error")}</div>;
  }
  switch (widget.chartType) {
    case "line":
      return <LineChart widget={widget} />;
    case "bar":
      return <BarChart widget={widget} />;
    case "pie":
      return <PieChart widget={widget} />;
    case "number":
      return <NumberChart widget={widget} />;
    default:
      return null;
  }
}
