import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:page_not_found")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 10, offset: 7 }}
        >
          <div className="box mt-4">
            <h3>{t("translation:page_not_found_tip")}</h3>
            <Button type="default" size="large" onClick={goToDashboard}>
              {t("translation:go_to_")}
              {t("translation:dashboard")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
