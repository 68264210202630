import React from "react";
import { Helmet } from "react-helmet";

import { warehouseService } from "/app/src/services";
import Warehouse from "./warehouse";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

/**
 * Component to display all warehouses. Renders a Warehouse component for each
 * warehouse.
 */
export default function Warehouses() {
  const { t } = useTranslation();

  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => warehouseService.getAll(),
    initialData: { warehouses: [] },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:explorer")} - ItemPath</title>
      </Helmet>

      {warehouses.warehouses.map((warehouse) => (
        <Warehouse warehouse={warehouse} key={warehouse.id} />
      ))}
    </div>
  );
}
