import React, { useCallback, useState } from "react";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col, Collapse, Button, Modal } from "antd";
import {
  WarningTwoTone,
  CaretRightOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { testConnection } from "/app/src/helpers/connectionsStatus";
import { isAPIType } from "/app/src/helpers/connectionAPI";
import { getConnectionSchema } from "/app/src/helpers/schemas";
import Headers from "./headers";
import TestEndpoint from "./testEndpoint";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection as ConnectionType } from "/app/src/models";
const { confirm } = Modal;

interface FormValues {
  name: string | undefined;
  username: string | undefined;
  url: string | undefined;
  password: string | undefined;
}

/**
 * Component to display a connection
 * @param connection the connection to be displayed
 * @param removable whether the connection can be deleted
 * @param updateConnection function to update the connection
 * @param deleteConnection function to delete the connection
 * @param editable whether the connection can be edited
 * @param testable whether the connection can be tested
 */
export default function Connection({
  connection,
  removable,
  updateConnection,
  deleteConnection,
  editable,
  testable,
}: {
  connection: ConnectionType;
  removable?: boolean;
  updateConnection: (
    connection: ConnectionType,
  ) => Promise<
    | { connection: ConnectionType }
    | { errors: { [key: string]: [key: string] } }
  >;
  deleteConnection: (
    connection: ConnectionType,
  ) => Promise<{ deleted: boolean }> | undefined;
  editable?: boolean;
  testable?: boolean;
}) {
  const [isTesting, setIsTesting] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { Panel } = Collapse;
  const { t } = useTranslation();

  /**
   * Handlers for collapsible panel
   */
  const collapseHandler = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  /**
   * Handler for testing connection
   */
  const handleTestClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      testConnection(setIsTesting, connection, updateConnection);
    },
    [connection, updateConnection],
  );

  /**
   * Handler for deleting connection
   */
  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      confirm({
        okText: t("translation:delete"),
        okButtonProps: { danger: true },
        cancelText: t("translation:cancel"),
        title: t("translation:confirm_delete_connection"),
        onOk() {
          deleteConnection(connection);
        },
      });
    },
    [t, connection, deleteConnection],
  );

  /**
   * Handler for updating connection
   */
  const handleUpdate = useCallback(
    (values: FormValues, actions) => {
      updateConnection({ ...values, id: connection.id })
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    [connection.id, updateConnection],
  );

  const panelHeader = (
    <Row
      justify="start"
      gutter={16}
      align="middle"
      onClick={(event) => {
        if (!editable) {
          event.stopPropagation();
        }
      }}
    >
      <Col span={1}>
        {editable && <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      </Col>
      <Col
        span={1}
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {testable &&
          (connection.status && connection.status !== 0 ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "32px" }}
            />
          ) : (
            <WarningTwoTone twoToneColor="red" style={{ fontSize: "32px" }} />
          ))}
      </Col>
      <Col span={16}>
        <h2>{connection.name}</h2>
      </Col>
      <Col span={6}>
        <div style={{ float: "right" }}>
          {testable && (
            <Button disabled={isTesting} onClick={handleTestClick}>
              {isTesting
                ? t("translation:testing___")
                : t("translation:test_") + t("translation:connection")}
            </Button>
          )}
          {removable && (
            <Button
              style={{ marginLeft: "10px" }}
              danger
              onClick={handleDeleteClick}
            >
              {t("translation:delete")}
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );

  /**
   * Formik form for connection settings - name, username, password, url
   */
  const settingsForm: (props: FormikProps<FormValues>) => JSX.Element = ({
    dirty,
    isValid,
  }) => (
    <Form layout="vertical">
      <h3>{t("translation:connection_settings")}</h3>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("translation:name")}>
            <Input suffix name="name" className="user" size="large" />
          </Form.Item>
        </Col>
        {connection.type !== "No Auth" && (
          <Col span={12}>
            <Form.Item name="username" label={t("translation:username")}>
              <Input name="username" className="user" size="large" />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item name="url" label={t("translation:address")}>
            <Input suffix name="url" size="large" />
          </Form.Item>
        </Col>
        {connection.type !== "No Auth" && (
          <Col span={12}>
            <Form.Item name="password" label={t("translation:password")}>
              <Input.Password
                className="password"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => {
                  if (connection.passwordExists) {
                    e.target.placeholder = "***********";
                  }
                }}
                placeholder={
                  connection.passwordExists ? "***********" : undefined
                }
                name="password"
                size="large"
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || !isValid}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
  const initialFormValues: FormValues = {
    name: connection?.name,
    username: connection?.username,
    password: "",
    url: connection?.url,
  };
  return (
    <div className="appSettings">
      <div className="box">
        <Row justify="start" gutter={16}>
          <Col span={24}>
            <Collapse ghost defaultActiveKey={["1"]} onChange={collapseHandler}>
              <Panel header={panelHeader} showArrow={false} key={1}>
                <Formik
                  component={settingsForm}
                  validationSchema={getConnectionSchema(connection)}
                  initialValues={initialFormValues}
                  enableReinitialize
                  onSubmit={handleUpdate}
                />
                {isAPIType(connection.type) && (
                  <>
                    <TestEndpoint connection={connection} />
                    <Headers connection={connection} />
                  </>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </div>
  );
}
