interface Errors {
  [key: string]:
    | [key: string]
    | { [key: string]: { message: string; code: number } };
}

/**
 * Function to digest the errors from the API and set the errors in the formik form
 * @param errors errors form API
 * @param setFieldError formik setFieldError
 */
export function handleSubmissionErrors(
  errors: Errors,
  setFieldError: (field: string, message: string | undefined) => void
) {
  for (const [key] of Object.entries(errors)) {
    for (const [k, v] of Object.entries(errors[key])) {
      if (v?.message !== undefined) {
        setFieldError(k, v?.message);
      } else {
        setFieldError(k, v[0]);
      }
    }
  }
}
