import React, { useEffect, useState } from "react";

import { appService } from "/app/src/services";

import GenericSettings from "./generic";
import Hue from "./hue";
import { App as AppType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function App() {
  const params = useNumberId();
  const [app, setApp] = useState<AppType>({});

  // Get app data
  useEffect(() => {
    if (!params?.appId) return;
    appService
      .getSingle(params.appId)
      .then((response) => setApp(response.app))
      .catch((error) => {
        console.log(error);
      });
  }, [params]);

  return (
    <div className="appSettings">
      <div className="box">
        <h2>{app.name}</h2>
        {app.name === "Philips Hue" ? (
          <Hue app={app} />
        ) : (
          <GenericSettings app={app} />
        )}
      </div>
    </div>
  );
}
