import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";

/**
 * Component that displays the edit and delete icons for a report. The icons will be green if the user has access
 * to the action and red if they do not
 * @param accessPermissions permissions for the current user
 */
export default function PermissionIcons() {
  const { canEdit, canDelete } = useAccessContext();
  const { t } = useTranslation();
  return (
    <>
      <Popover
        placement="bottomRight"
        content={
          canEdit
            ? t("translation:edit_access")
            : t("translation:no_edit_access")
        }
      >
        <EditOutlined style={canEdit ? { color: "green" } : { color: "red" }} />
      </Popover>
      <Popover
        placement="bottomRight"
        content={
          canDelete
            ? t("translation:delete_access")
            : t("translation:no_delete_access")
        }
      >
        <DeleteOutlined
          style={canDelete ? { color: "green" } : { color: "red" }}
        />
      </Popover>
    </>
  );
}
